// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getAuth, GoogleAuthProvider } from "firebase/auth";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyB6iONAq2d1pp9A1xFElCPbsI_R_oxcs0A",
  authDomain: "demo1-ef78a.firebaseapp.com",
  projectId: "demo1-ef78a",
  storageBucket: "demo1-ef78a.appspot.com",
  messagingSenderId: "860647325939",
  appId: "1:860647325939:web:257159e0f5fea42db1f786",
  measurementId: "G-XYJ5M55HR4",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
const googleProvider = new GoogleAuthProvider();
export { app, auth, googleProvider };

const analytics = getAnalytics(app);
