import React, { useState } from "react";
import "../Css/SideBar.css";
// import Murphyimg from "../Images/Murphyimg.png";
import editlogo from "../Images/editlogo.png";
import paylogo from "../Images/paylogo.png";
import booklogo from "../Images/booklogo.png";
import passlogo from "../Images/passlogo.png";
import ChangePassword from "../Component/ChangePassword";
import setlogo from "../Images/setlogo.png";
import Mypost from "../Images/MyPost.png";
// import { useNavigate } from "react-router-dom";
import messagelogo from "../Images/messagelogo.png";
import Navbar2 from "../Component/Navbar2";
import Footer2 from "../Component/Footer2";
import { GiHamburgerMenu } from "react-icons/gi";
import {
  CButton,
  COffcanvas,
  COffcanvasHeader,
  COffcanvasTitle,
  CCloseButton,
  COffcanvasBody,
} from "@coreui/react";
import { NavLink, Outlet, json } from "react-router-dom";
import { useEffect } from "react";
import { useUser } from "../UserContext.js";
const SideBar = () => {
  const { userData } = useUser();
  // const [save, setSave] = useState();

  // useEffect(() => {
  //   setSave(JSON.parse(localStorage.getItem("Nezziedata")));
  // }, []);
  // console.log(save);
  const editdata = [
    {
      img11: editlogo,
      path: "/MyProfile/EditProfile",
      pathname: "EditProfile",
    },
    {
      img11: paylogo,
      path: "/MyProfile/PaymentOptions",
      pathname: "PaymentOptions",
    },
    {
      img11: booklogo,
      path: "/MyProfile/MyBooking",
      pathname: "MyBookings",
    },
    {
      img11: Mypost,
      path: "/MyProfile/MyPost",
      pathname: "MyPost",
    },
    {
      img11: messagelogo,
      pathname: "Messages",
    },
    {
      img11: passlogo,
      path: "/MyProfile",
      pathname: "ChangePassword",
    },
    {
      img11: setlogo,
      path: "/MyProfile/Settings",
      pathname: "Settings",
    },

    {
      img11: setlogo,
      path: "/MyProfile/TermsCondition",
      pathname: "TermsCondition",
    },
    {
      img11: setlogo,
      path: "/MyProfile/Support",
      pathname: "Support",
    },
  ];

  const [visible, setVisible] = useState(false);
  const [show, setShow] = useState(false);
  const ok = (index) => {
    if (index === 5) {
      setShow(true);
    }
  };
  return (
    <>
      <Navbar2 />
      <div className="togal-btn-sid">
        <CButton onClick={() => setVisible(true)}>
          <GiHamburgerMenu color="#1e2d38" size={30} />
        </CButton>
        <COffcanvas
          placement="start"
          visible={visible}
          onHide={() => setVisible(false)}
        >
          <COffcanvasHeader>
            <COffcanvasTitle></COffcanvasTitle>
            <CCloseButton
              className="text-reset"
              onClick={() => setVisible(false)}
            />
          </COffcanvasHeader>
          <COffcanvasBody className="murphy-main-div-ha">
            <div className="murphy-div">
              <img src={userData?.image} alt="" className="murphy-img22" />
            </div>
            <div className="mb-5">
              <span className="Kathryn-Murphy-span">{userData?.fullName}</span>
            </div>
            <div className="ghjg">
              {editdata.map((item3, index) => {
                return (
                  <div className="svg-or-span-sp12" key={index}>
                    <div>
                      <img src={item3.img11} alt="" />
                    </div>
                    <div className="Profile-span-line">
                      <NavLink
                        to={item3.pathname}
                        className="same-sapn-profile"
                        onClick={() => setVisible(false)}
                      >
                        <span className="same-sapn-profile">
                          {item3.pathname}
                        </span>
                      </NavLink>
                      <div className="line-kaliya"> </div>
                    </div>
                  </div>
                );
              })}
            </div>
          </COffcanvasBody>
        </COffcanvas>
      </div>
      <div className="out-slide">
        <section className="sidebar-main-section">
          <div className="sidebar-parent-div32">
            <div className="sidebar-child-div33">
              <div className="murphy-div">
                <img src={userData?.image} alt="" className="murphy-img22" />
              </div>
              <div>
                <span className="Kathryn-Murphy-span">
                  {userData?.fullName}
                </span>
              </div>

              <div className="ghjg">
                {editdata.map((item3, index) => {
                  return (
                    <div className="svg-or-span-sp12" key={index}>
                      <div>
                        <img src={item3.img11} alt="" />
                      </div>
                      <div className="Profile-span-line">
                        <NavLink
                          to={item3.pathname}
                          className="same-sapn-profile"
                          onClick={() => ok(index)}
                        >
                          <span className="same-sapn-profile">
                            {item3.pathname}
                          </span>
                        </NavLink>
                        <div className="line-kaliya"> </div>
                      </div>
                    </div>
                  );
                })}
              </div>

              {/* <div className="svg-or-span-sp12-div">
                <div>
                  <img src={setlogo} alt="" />
                </div>
                <div className="Profile-span-line">
                  <div>
                    <span className="same-sapn-profile">Settings</span>
                  </div>
                </div>
              </div> */}
            </div>
          </div>
          <div>
            <div>{<Outlet />}</div>
          </div>
          {show && <ChangePassword show={show} setShow={setShow} />}
        </section>
      </div>
      <Footer2 />
    </>
  );
};

export default SideBar;
