import * as Yup from "yup";

export const LoginSchemas = Yup.object({
  email: Yup.string().email().required("Please Enter Your Email"),
  password: Yup.string().min(4).required("Please Enter Your Password "),
});

export const RegisterSchemas = Yup.object({
  FullName: Yup.string().min(2).max(25).required("Please Enter Your Full Name"),
  email: Yup.string().email().required("Please Enter Your Email"),
  password: Yup.string().min(4).required("Please Enter Your Password "),
  MobileNumber: Yup.number().required("Please Enter Your Mobile Number"),
  ConfirmPassword: Yup.string()
    .required()
    .oneOf([Yup.ref("password"), null], "Password Must Match"),
});

export const ForgotPasswordSchemas = Yup.object({
  email: Yup.string().email().required("Please Enter Your Email"),
});

export const EditProfileSchemas = Yup.object({
  FullName: Yup.string().min(2).max(25).required("Please Enter Your Full Name"),
  email: Yup.string().email().required("Please Enter Your Email"),
  MobileNumber: Yup.number().required("Please Enter Your Mobile Number"),
});
export const Post2Schemas = Yup.object({
  rate: Yup.string().required("Required field can't be empty"),
  Writehere: Yup.string().required("Required field can't be empty"),
});
export const NewSchemas = Yup.object({
  rate: Yup.string().required("Required field can't be empty"),
});
export const HousingSchemas = Yup.object({
  rate: Yup.string().required("Required field can't be empty"),
});
export const JobpostSchemas = Yup.object({
  Message: Yup.string().required("Required field can't be empty"),
  Price: Yup.string().required("Required field can't be empty"),
});
export const Housing2Schemas = Yup.object({
  rate: Yup.string().required("Required field can't be empty"),
});
export const ServiceComplainstSchemas = Yup.object({
  rate: Yup.string().required("Required field can't be empty"),
});
export const OnlineSchemas = Yup.object({
  rate: Yup.string().required("Required field can't be empty"),
});
export const LandlordtSchemas = Yup.object({
  rate: Yup.string().required("Required field can't be empty"),
});
export const ViolationsSchemas = Yup.object({
  rate: Yup.string().required("Required field can't be empty"),
});
export const TravelSchemas = Yup.object({
  rate: Yup.string().required("Required field can't be empty"),
});
export const SupportSchemas = Yup.object({
  email: Yup.string().required("Required field can't be empty"),
  Writehere: Yup.string().required("Required field can't be empty"),
  Subject: Yup.string().required("Required field can't be empty"),
});
export const ChangePasswordSchemas = Yup.object({
  CurrentPassword: Yup.string()
    .min(4)
    .required("Please Enter  Current Password"),
  NewPassword: Yup.string().min(4).required("Please Enter Your Password"),
  ConfirmNewPassword: Yup.string()
    .required()
    .oneOf([Yup.ref("NewPassword"), null], "Password Must Match"),
});

export const PostaJobSchemas = Yup.object({
  Description: Yup.string().required("Required field can't be empty"),
  State: Yup.string().required("Required field can't be empty"),
  City: Yup.string().required("Required field can't be empty"),
  ExperienceIn: Yup.string().required("Required field can't be empty"),
  HourlyRate: Yup.number().required("Required field can't be empty"),
  HourlyRateto: Yup.number().required("Required field can't be empty"),
  TimeDuration: Yup.number().required("Required field can't be empty"),
  TotalHours: Yup.number().required("Required field can't be empty"),
  Writehere: Yup.string().required("Required field can't be empty"),
  Country: Yup.string().required("Required field can't be empty"),
  Address: Yup.string().required("Required field can't be empty"),
});

export const AdRegisterSchemas = Yup.object({
  FullName: Yup.string().min(2).max(25).required("Please Enter Your Full Name"),
  email: Yup.string().email().required("Please Enter Your Email"),
  password: Yup.string().min(4).required("Please Enter Your Password "),
  MobileNumber: Yup.number().required("Please Enter Your Mobile Number"),

  ConfirmPassword: Yup.string()
    .required()
    .oneOf([Yup.ref("password"), null], "Password Must Match"),
});

export const RegisterTwoSchemas = Yup.object({
  RatePerhour: Yup.string().required("Required field can't be empty"),
  Writehere: Yup.string().required("Required field can't be empty"),
  // AboutYou: Yup.string().required("Required field can't be empty"),
  YourSpeciality: Yup.string().required("Required field can't be empty"),
  ResolutionExperience: Yup.string().required("Required field can't be empty"),
  // Skills: Yup.string().required("Required field can't be empty"),
});

export const EditProfile2Schemas = Yup.object({
  FullName: Yup.string().min(2).max(25).required("Please Enter Your Full Name"),
  email: Yup.string().email().required("Please Enter Your Email"),
  MobileNumber: Yup.number().required("Please Enter Your Mobile Number"),
  // RatePerhour: Yup.string().required("Required field can't be empty"),
  // Writehere: Yup.string().required("Required field can't be empty"),
  // YourSpeciality: Yup.string().required("Required field can't be empty"),
  // ResolutionExperience: Yup.string().required("Required field can't be empty"),
  // Skills: Yup.string().required("Required field can't be empty"),
});
