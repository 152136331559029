// import React, { useEffect } from "react";
import "../Css/ForgotPassword.css";
import forgotimg from "../Images/forgotimg.png";
import emaillogo from "../Logo/emaillogo.png";
import Group28 from "../Images/Group28.png";
import backarrow from "../Images/backarrow.png";
import sedo from "../Images/Loginsedo.png";
import { useNavigate } from "react-router-dom";
import { useFormik } from "formik";
import { ForgotPasswordSchemas } from "../schemas";
import { useState } from "react";
import axios from "../schemas/Api";
import { toast } from "react-toastify";
import CircularProgress from "@mui/material/CircularProgress";
import Backdrop from "@mui/material/Backdrop";

const initialValues = {
  email: "",
};
const ForgotPassword = () => {
  const [Loading, setLoading] = useState(false);
  const onSubmit = async () => {
    setLoading(true);
    try {
      const response = await axios.post(
        "userForgetPassword",
        {
          email: values.email,
        },
        {
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
          },
        }
      );
      if (response) {
        setLoading(false);
      }
      toast.success(response.data.message, {
        position: "top-right",
        theme: "colored",
      });
      if (
        response.data.message ===
        "Password Reset Link Sent To Your Email Account"
      ) {
        navigate("/Login");
      }
      console.log(response);
    } catch (error) {
      console.log("error", error);
      setLoading(false);
      // toast.error(error.response.data.message, {
      //   position: "top-right",
      //   theme: "colored",
      // });
    }
  };
  const { values, handleBlur, handleChange, touched, handleSubmit, errors } =
    useFormik({
      initialValues: initialValues,
      validationSchema: ForgotPasswordSchemas,

      onSubmit,
    });
  console.log(errors);
  const navigate = useNavigate();
  return (
    <section className="Forgot-Password-main-section">
      <form onSubmit={handleSubmit} className="Forgot-Password-child-section">
        <div className="forgot-image-single-div">
          {/* <img src={forgotimg} alt="" className="forgot-image12" /> */}
          <div className="dado-img-login-dono">
            <img src={forgotimg} alt="" className="login-img-img" />
            <img className="sado-hu-me" src={sedo} alt="" />
          </div>
          <div className="forgot-image-inner-div">
            <span className="dolar-span-text">
              Lorem ipsum dolor consectetur adipiscing.
            </span>
            <img src={Group28} alt="" className="group28-img-22" />
          </div>
        </div>
        <div className="width-percentage-div">
          <div className="back-arro-span45-parent-div">
            <div onClick={() => navigate("/")} className="back-arro-span45-div">
              <img src={backarrow} alt="" className="back-90" />
              <span className="back-span-90">Back</span>
            </div>
          </div>
          <div>
            <span className="Forgot-Password-span12">Forgot Password</span>
          </div>
          <div className="Forgot-Password-span1244-div">
            <span className="Forgot-Password-span1244">
              Please enter the email address you ‘d like to your password reset
              information sent to.
            </span>
          </div>
          <div>
            <div className="email-input-div">
              <div className="email-input-image-inner-div">
                <img src={emaillogo} alt="" className="emai-input-image" />
              </div>

              <input
                type="email"
                placeholder="Email"
                className="forgot-input-feild"
                name="email"
                value={values.email}
                onChange={handleChange}
                onBlur={handleBlur}
              />
            </div>
            {errors.email && touched.email ? (
              <p className="mastu-to-eroor mt-2">{errors.email} </p>
            ) : null}
          </div>
          <div>
            <button type="submit" className="forgot-button">
              {Loading ? "loading.." : "Submit"}
            </button>
          </div>
        </div>
        {!Loading && <div></div>}
        {Loading && ( // Display the loader when `loading` is true
          <div>
            <Backdrop
              sx={{
                color: "#fff",
                zIndex: (theme) => theme.zIndex.drawer + 1,
              }}
              open={true}
              // onClick={handleClose}
            >
              <CircularProgress color="inherit" />
            </Backdrop>
          </div>
        )}
      </form>
    </section>
  );
};

export default ForgotPassword;
