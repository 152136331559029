import React, { useState } from "react";
import { GrStar } from "react-icons/gr";
import "../Css/RatethisAdvocate.css";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import Rateuser from "../Images/Rateuser.png";
// import axios from "axios";
// import { useLocation } from "react-router-dom";
// import axios from "../schemas/Api";
// import { useLocation } from "react-router-dom";

const RatethisAdvocate = ({ advocateName, initialShow }) => {
  // const route = useLocation();
  // const maindata = route.state.items.advocate_id;
  // const [reveiwStars, setReveiwStars] = useState(5);
  // model-----------/////////////////////////////////////////////////
  // const data = JSON.parse(localStorage.getItem("Nezziedata"));

  const [show, setShow] = useState(true);
  const handleClose = () => {
    setShow(false);
    // Call the onHide prop function to handle closing the modal in the parent component
  };

  // const handleClose = () => setShow(false);
  // const handleShow = () => setShow(true);

  // StarRateing----------------///////////////////////////////////////

  const [selected, setSelected] = useState(0);
  const StarGenerator = (numofstar = 5) => {
    // console.log(selected, "seleted");
    return Array(numofstar)
      .fill()
      .map((item, i) => (
        <Star
          key={i}
          selected={selected > i}
          onSelected={() => setSelected(i + 1)}
        />
      ));
  };
  // const addReveiw = async () => {
  //   try {
  //     const resp = await axios.post(
  //       "",
  //       {
  //         headers: {
  //           "Content-Type": "application/json",
  //           Accept: "application/json",
  //           Authorization: data.token,
  //         },
  //       },
  //       {
  //         advocateId: maindata,
  //         stars: reveiwStars,
  //       }
  //     );
  //     console.log(resp);
  //   } catch (error) {
  //     console.log("Error", error);
  //   }
  // };
  const Star = ({ selected, onSelected }) => {
    return (
      <GrStar
        className="fastar-mnb"
        color={selected ? "goldenrod" : "gray"}
        borderColor={selected ? "red" : "goldenrod"}
        onClick={onSelected}
      />
    );
  };
  return (
    <>
      {/* <Button variant="primary" onClick={handleShow}>
        Launch demo modal
      </Button> */}
    </>
  );
};

export default RatethisAdvocate;
