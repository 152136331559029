import React from "react";
import "../Css/Modal.css";
import nlogo from "../Logo/nlogo.png";
import { HiOutlineLogout } from "react-icons/hi";

import { useNavigate } from "react-router-dom";

const Modal = ({ userRole }) => {
  const navigate = useNavigate();
  const Logouthandler = () => {
    localStorage.removeItem("Nezziedata");
    navigate("/");
  };
  const handleProfileEdit = () => {
    if (userRole === "user") {
      navigate("/MyProfile/EditProfile");
    } else if (userRole === "advocate") {
      navigate("/MyProfile2/EditProfile2");
    }
  };
  return (
    <>
      <div className="modal-main-section-div">
        <div className="btnone" onClick={handleProfileEdit}>
          <img src={nlogo} alt="" className="nlogo-popup" />
          <span>My Profile</span>
        </div>
        <hr className="hrline" />
        <div className="btntwo" onClick={Logouthandler}>
          <HiOutlineLogout className="react-icon-icon" />
          <span>Logout</span>
        </div>
      </div>
    </>
  );
};

export default Modal;
