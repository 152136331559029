import React, { useState, useEffect } from "react";
import "../Css/TermsCondition.css";
import axios from "../schemas/Api";
// import axios from "axios";
import CircularProgress from "@mui/material/CircularProgress";
const TermsCondition = () => {
  const [isloading, setIsLoading] = useState(true);

  const [termdata, setTermdata] = useState([]);
  const data = JSON.parse(localStorage.getItem("Nezziedata"));

  const privacyData = async () => {
    try {
      const resp = await axios.post(
        "https://admin.nezzie.app/api/advocate/privacyPolicy",
        {
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            Authorization: data.token,
          },
        }
      );
      if (resp.data.data) {
        setTermdata(resp.data.data);
        setIsLoading(false);
        console.log(resp);
      }
    } catch (error) {
      console.log("Error", error);
    }
  };
  useEffect(() => {
    privacyData();
  }, []);
  return (
    <section className="terms-main-section">
      <h3>Terms Condition</h3>
      {isloading ? ( // Display the loader when data is loading
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100vh",
          }}
        >
          <CircularProgress />
        </div>
      ) : (
        // Render the content when data is loaded
        <div>
          <p dangerouslySetInnerHTML={{ __html: termdata }}></p>
        </div>
      )}
    </section>
  );
};

export default TermsCondition;
