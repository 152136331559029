import { createContext, useState } from "react";

export const Globalcontext = createContext();

export const Globalcontextprovider = ({ children }) => {
  const [context, setContext] = useState(false);
  return (
    <Globalcontext.Provider value={{ context, setContext }}>
      {children}
    </Globalcontext.Provider>
  );
};
