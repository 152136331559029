import React from "react";
import Lottie from "lottie-react";
import "../Css/PaymentFailed.css";
import Navbar2 from "./Navbar2";
import Footer2 from "./Footer2";
import { useNavigate } from "react-router-dom";

const PaymentFailed = () => {
  const navigate = useNavigate();
  return (
    <>
      <Navbar2 />
      <section className="PaymentFailed-sec">
        <div className="PaymentFailed-div">
          <div className="faild-lottiy-div">
            <Lottie
              className="fail-loty"
              animationData={require("../Animation/Fali.json")}
            />
            <span className="Successfully-span mb-3">Payment Failed!</span>
            <span className="booking-span">
              Your payment has been failed please
            </span>
            <span className="booking-span">check payment method.</span>
            <button
              className="mt-3 ok-btn-cong"
              onClick={() => navigate("/AdviceAdvocate")}
            >
              ok
            </button>
          </div>
        </div>
      </section>
      <Footer2 />
    </>
  );
};

export default PaymentFailed;
