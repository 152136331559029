import React from "react";
import Navbar from "./Navbar";
import Footer from "./Footer";
import YourTrust from "./YourTrust";
import AboutUs from "./AboutUs";
import HowItsWork from "./HowItsWork";
import AAdvocates from "./AAdvocates";
import Unbelievable from "./Unbelievable";
import "../Css/Home.css";

const Home = () => {
  return (
    <>
      <Navbar />
      <div className="home-main-div10">
        <div className="hejfef">
          <Unbelievable />
        </div>
        <div className="jrjgfdrghj">
          <YourTrust />
        </div>
        <div className="hejfef">
          <AAdvocates />
        </div>
        <div className="hejfef">
          {" "}
          <AboutUs />
        </div>
        <div className="hejfef">
          <HowItsWork />
        </div>
      </div>
      <Footer />
    </>
  );
};

export default Home;
