// import React, { useState, useEffect } from "react";
// import "../Css/Mybooking2.css";
// // import image11 from "../Images/Hire1.png";
// // import image22 from "../Images/Advocates2.png";
// // import image33 from "../Images/Advocates4.png";
// // import image44 from "../Images/Advocates3.png";
// // import starimg from "../Images/starimg.png";
// import axios from "../schemas/Api";
// import ssstar from "../Images/ssstar.png";
// import ksstar from "../Images/ksstar.png";
// // import RatethisAdvocate from "./RatethisAdvocate";
// // import { useNavigate } from "react-router-dom";
// // import Button from "react-bootstrap/Button";
// import Modal from "react-bootstrap/Modal";
// // import Rateuser from "../Images/Rateuser.png";
// import { GrStar } from "react-icons/gr";
// import { toast } from "react-toastify";
// import "react-toastify/dist/ReactToastify.css";

// import "../Css/RatethisAdvocate.css";

// const Mybooking2 = () => {
//   const [reveiwStars, setReveiwStars] = useState(5);

//   const [show, setShow] = useState(false);
//   const [advocateid, setAdvocateid] = useState();
//   const handleRateButtonClick = (item2) => {
//     setAdvocateid(item2);
//     setShow(true);
//     console.log(item2);
//   };
//   const handleClose = () => {
//     setShow(false);
//   };
//   const [completed, setCompleted] = useState([]);
//   const data = JSON.parse(localStorage.getItem("Nezziedata"));
//   // console.log(completed);
//   // console.log(`${data.token}`);
//   useEffect(() => {
//     completedData();
//   }, []);

//   const completedData = async () => {
//     try {
//       const response = await axios.post(
//         "bookings/my_Bookingslist",
//         {
//           status: "Completed",
//         },
//         {
//           headers: {
//             "Content-Type": "application/json",
//             Accept: "application/json",
//             Authorization: data.token,
//           },
//         }
//       );
//       setCompleted(response.data.data);
//       console.log(response);
//     } catch (error) {
//       console.log("Error", error);
//     }
//   };
//   const addreview = async () => {
//     try {
//       const resp = await axios.post(
//         "review/add_review",
//         {
//           advocateId: advocateid.advocate_id,
//           stars: reveiwStars,
//         },
//         {
//           headers: {
//             "Content-type": "application/json",
//             Accept: "application/json",
//             Authorization: data.token,
//           },
//         }
//       );
//       if (resp.data.data === "Review Added Successfully") {
//         toast.success(resp.data.message, {
//           position: "top-right",
//           theme: "colored",
//         });
//       } else {
//         toast.success(resp.data.message, {
//           position: "top-right",
//           theme: "colored",
//         });
//       }

//       console.log(resp);
//     } catch (error) {
//       console.log("Error", error);
//     }
//   };

//   const Star = ({ selected, onSelected }) => {
//     return (
//       <GrStar
//         className="fastar-mnb"
//         color={selected ? "goldenrod" : "gray"}
//         borderColor={selected ? "red" : "goldenrod"}
//         onClick={onSelected}
//       />
//     );
//   };
//   const [selected, setSelected] = useState(0);
//   const StarGenerator = (numofstar = 5) => {
//     // console.log(selected, "seleted");
//     return Array(numofstar)
//       .fill()
//       .map((item, i) => (
//         <Star
//           key={i}
//           selected={selected > i}
//           onSelected={() => setSelected(i + 1)}
//         />
//       ));
//   };

//   // const bookingdata2 = [
//   //   {
//   //     Img2: image11,
//   //     Name2: "Jenny Wilson",
//   //     Price2: "$ 200",
//   //   },
//   //   {
//   //     Img2: image22,
//   //     Name2: "Jecob Jones",
//   //     Price2: "$ 200",
//   //   },
//   //   {
//   //     Img2: image33,
//   //     Name2: "Dianne",
//   //     Price2: "$ 200",
//   //   },
//   //   {
//   //     Img2: image44,
//   //     Name2: "Marvin",
//   //     Price2: "$ 200",
//   //   },
//   // ];
//   return (
//     <section className="mybooking2-main-section1">
//       <div className="mybooking2-child-section1">
//         <div className="main-div-of-all-card10">
//           {completed.length > 0 ? (
//             completed.map((item2, index) => {
//               let Ratingcount = item2.review_total_count;
//               let MaxRate = 5;
//               let yellowstar = Math.min(Ratingcount, MaxRate);
//               let RatingcountImage = Array.from(
//                 { length: yellowstar },
//                 (_, i) => <img key={i} src={ssstar} alt="" />
//               );
//               let GrayStarCount = MaxRate - yellowstar;
//               let GrayStarImage = Array.from(
//                 { length: GrayStarCount },
//                 (_, i) => <img key={i + yellowstar} src={ksstar} alt="" />
//               );
//               let starRatingimages = [...RatingcountImage, ...GrayStarImage];
//               return (
//                 <div key={index} className="single-card-height-width-div">
//                   <div className="inner22-div-card-content22">
//                     <div className="henry-class-div">
//                       <div>
//                         <img
//                           src={item2.image}
//                           alt=""
//                           className="item2-image45"
//                         />
//                       </div>
//                       <div className="name22-span-text-div">
//                         <div>
//                           <span className="item2-span-name">
//                             {item2.advocateName}
//                           </span>
//                         </div>
//                         <div className="star-iamge23-review-div">
//                           {starRatingimages.map((item2, name) => {
//                             return (
//                               <img
//                                 key={name}
//                                 src={item2.props.src}
//                                 className="star-img-90"
//                                 alt=""
//                               />
//                             );
//                           })}
//                         </div>
//                         <div>
//                           <span className="dollar-price-span35">
//                             $ {item2.rate}
//                           </span>
//                           <span className="perhour-55-span"> / per hour</span>
//                         </div>
//                       </div>
//                       <div className="chat-logo-btn-div">
//                         <svg
//                           className="msg-svg-1"
//                           width="24"
//                           height="24"
//                           viewBox="0 0 24 24"
//                           fill="none"
//                           xmlns="http://www.w3.org/2000/svg"
//                         >
//                           <g clip-path="url(#clip0_2_925)">
//                             <path
//                               d="M7.29101 20.824L2.00001 22L3.17601 16.709C2.40154 15.2604 1.99754 13.6426 2.00001 12C2.00001 6.477 6.47701 2 12 2C17.523 2 22 6.477 22 12C22 17.523 17.523 22 12 22C10.3574 22.0025 8.73963 21.5985 7.29101 20.824V20.824ZM7.58101 18.711L8.23401 19.061C9.39256 19.6801 10.6864 20.0027 12 20C13.5823 20 15.129 19.5308 16.4446 18.6518C17.7602 17.7727 18.7855 16.5233 19.391 15.0615C19.9965 13.5997 20.155 11.9911 19.8463 10.4393C19.5376 8.88743 18.7757 7.46197 17.6569 6.34315C16.538 5.22433 15.1126 4.4624 13.5607 4.15372C12.0089 3.84504 10.4004 4.00346 8.93854 4.60896C7.47674 5.21447 6.22731 6.23984 5.34825 7.55544C4.4692 8.87103 4.00001 10.4177 4.00001 12C4.00001 13.334 4.32501 14.618 4.94001 15.766L5.28901 16.419L4.63401 19.366L7.58101 18.711V18.711Z"
//                               fill="#006EBD"
//                             />
//                           </g>
//                           <defs>
//                             <clip-path id="clip0_2_925">
//                               <rect width="24" height="24" fill="white" />
//                             </clip-path>
//                           </defs>
//                         </svg>
//                         <button className="completed-123-btn">Completed</button>
//                       </div>
//                     </div>
//                     <div className="date-time-main13-div">
//                       <div className="time-child10-div2">
//                         <span className="date5-span5">Date</span>
//                         <span className="jun5-span5">{item2.bookedDate}</span>
//                       </div>
//                       <div className="time-child10-div2">
//                         <span className="date5-span5">Time</span>
//                         <span className="jun5-span5">{item2.bookedTime}</span>
//                       </div>
//                     </div>
//                     <div className="rating-button55-div">
//                       {" "}
//                       {item2.review_total_count > 0 ? (
//                         <div className="special-star-rated-div">
//                           <button className="Ratedd-button-extar">
//                             Rated{" "}
//                             <img src={ssstar} alt="" className="star-img-90" />{" "}
//                             {item2.review_total_count}.0
//                           </button>
//                         </div>
//                       ) : (
//                         <button
//                           className="rating-button55"
//                           onClick={() => handleRateButtonClick(item2)}
//                         >
//                           Rate this Advocate{" "}
//                         </button>
//                       )}
//                       <Modal show={show} onHide={handleClose}>
//                         <Modal.Header closeButton>
//                           <span className="">Rate this Advocate</span>
//                         </Modal.Header>

//                         <div className="model-contain">
//                           <div className="Rate-user-name">
//                             <img
//                               className="Rateuser-img"
//                               src={advocateid?.image}
//                               alt=""
//                             />
//                             <span>{advocateid?.advocateName}</span>
//                           </div>
//                           <div className="mb-3">{StarGenerator(5)}</div>
//                           <div className="Review-span-div">
//                             <span className="textatea-span">
//                               Write a Review
//                             </span>
//                             <textarea
//                               className="Write-a-text"
//                               placeholder="Write here"
//                               id="w3review"
//                               name="w3review"
//                               rows="5"
//                               cols="35"
//                             ></textarea>
//                           </div>

//                           <div className="">
//                             <button
//                               className="Submit-btn"
//                               onClick={() => addreview(true)}
//                             >
//                               Submit
//                             </button>
//                           </div>
//                         </div>
//                       </Modal>
//                     </div>
//                   </div>
//                 </div>
//               );
//             })
//           ) : (
//             <p>No completed bookings available.</p>
//           )}
//         </div>
//       </div>
//     </section>
//   );
// };

// export default Mybooking2;
import React, { useState } from "react";
import "../Css/Mybooking2.css";
import image11 from "../Images/Hire1.png";
import image22 from "../Images/Advocates2.png";
import image33 from "../Images/Advocates4.png";
import image44 from "../Images/Advocates3.png";
import starimg from "../Images/starimg.png";
import Modal from "react-bootstrap/Modal";
import { GrStar } from "react-icons/gr";
import RatethisAdvocate from "./RatethisAdvocate";
import Tip2 from "./Tip2";
import close from "../Images/close.png";

const Mybooking2 = () => {
  const [show, setShow] = useState(false);
  const [showto, setshowto] = useState("");
  const Imagelink = () => {
    setshowto(true);
  };
  const Star = ({ selected, onSelected }) => {
    return (
      <GrStar
        className="fastar-mnb"
        color={selected ? "goldenrod" : "gray"}
        borderColor={selected ? "red" : "goldenrod"}
        onClick={onSelected}
      />
    );
  };
  const [selected, setSelected] = useState(0);

  const StarGenerator = (numofstar = 5) => {
    // console.log(selected, "seleted");
    return Array(numofstar)
      .fill()
      .map((item, i) => (
        <Star
          key={i}
          selected={selected > i}
          onSelected={() => setSelected(i + 1)}
        />
      ));
  };
  const handleClose = () => {
    setShow(false);
  };

  const bookingdata2 = [
    {
      Img2: image11,
      Name2: "Jenny Wilson",
      Price2: "$ 200",
    },
    {
      Img2: image22,
      Name2: "Jecob Jones",
      Price2: "$ 200",
    },
    {
      Img2: image33,
      Name2: "Dianne",
      Price2: "$ 200",
    },
    {
      Img2: image44,
      Name2: "Marvin",
      Price2: "$ 200",
    },
  ];
  return (
    <>
      {showto && (
        <div className="model">
          <div className="closeicon">
            <img src={close} alt="" onClick={() => setshowto(false)} />
            <Tip2 />
          </div>
        </div>
      )}
      <section className="mybooking2-main-section1">
        <div className="mybooking2-child-section1">
          {/* <div className="mybooking2-single121-div">
          <span className="My-Bookings21">My Bookings</span>
        </div>
        <div>
          <button className="Upcoming-button22">Upcoming</button>
          <button className="completed-button22">Completed</button>
        </div> */}
          <div className="main-div-of-all-card10">
            {bookingdata2.map((item2, index) => {
              return (
                <div key={index} className="single-card-height-width-div">
                  <div className="inner22-div-card-content22">
                    <div className="henry-class-div">
                      <div>
                        <img
                          src={item2.Img2}
                          alt=""
                          className="item2-image45"
                        />
                      </div>
                      <div className="name22-span-text-div">
                        <div>
                          <span className="item2-span-name">{item2.Name2}</span>
                        </div>
                        <div className="star-iamge23-review-div">
                          <img src={starimg} alt="" className="star-img-98" />
                          <span className="image-reveiw-span66">
                            (233 Reviews)
                          </span>
                        </div>
                        <div>
                          <span className="dollar-price-span35">
                            {item2.Price2}
                          </span>
                          <span className="perhour-55-span"> / per hour</span>
                        </div>
                      </div>
                      <div className="chat-logo-btn-div">
                        <svg
                          className="msg-svg-1"
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <g clip-path="url(#clip0_2_925)">
                            <path
                              d="M7.29101 20.824L2.00001 22L3.17601 16.709C2.40154 15.2604 1.99754 13.6426 2.00001 12C2.00001 6.477 6.47701 2 12 2C17.523 2 22 6.477 22 12C22 17.523 17.523 22 12 22C10.3574 22.0025 8.73963 21.5985 7.29101 20.824V20.824ZM7.58101 18.711L8.23401 19.061C9.39256 19.6801 10.6864 20.0027 12 20C13.5823 20 15.129 19.5308 16.4446 18.6518C17.7602 17.7727 18.7855 16.5233 19.391 15.0615C19.9965 13.5997 20.155 11.9911 19.8463 10.4393C19.5376 8.88743 18.7757 7.46197 17.6569 6.34315C16.538 5.22433 15.1126 4.4624 13.5607 4.15372C12.0089 3.84504 10.4004 4.00346 8.93854 4.60896C7.47674 5.21447 6.22731 6.23984 5.34825 7.55544C4.4692 8.87103 4.00001 10.4177 4.00001 12C4.00001 13.334 4.32501 14.618 4.94001 15.766L5.28901 16.419L4.63401 19.366L7.58101 18.711V18.711Z"
                              fill="#006EBD"
                            />
                          </g>
                          <defs>
                            <clip-path id="clip0_2_925">
                              <rect width="24" height="24" fill="white" />
                            </clip-path>
                          </defs>
                        </svg>
                        <button className="completed-123-btn">Completed</button>
                      </div>
                    </div>
                    <div className="date-time-main13-div">
                      <div className="time-child10-div2">
                        <span className="date5-span5">Date</span>
                        <span className="jun5-span5">5 Jan 2023</span>
                      </div>
                      <div className="time-child10-div2">
                        <span className="date5-span5">Time</span>
                        <span className="jun5-span5">2:00 pm to 3:00 pm</span>
                      </div>
                    </div>
                    <div className="rating-button55-div">
                      <button
                        className="rating-button55"
                        onClick={() => setShow(true)}
                      >
                        Rate this Advocate{" "}
                      </button>
                      <Modal show={show} onHide={handleClose}>
                        <Modal.Header closeButton>
                          <span className="">Rate this Advocate</span>
                        </Modal.Header>

                        <div className="model-contain">
                          <div className="Rate-user-name">
                            <img
                              className="Rateuser-img"
                              src={image11}
                              alt=""
                            />
                            <span>Jenny Wilson</span>
                          </div>
                          <div className="mb-3">{StarGenerator(5)}</div>
                          <div className="Review-span-div">
                            <span className="textatea-span">
                              Write a Review
                            </span>
                            <textarea
                              className="Write-a-text"
                              placeholder="Write here"
                              id="w3review"
                              name="w3review"
                              rows="5"
                              cols="35"
                            ></textarea>
                          </div>
                          <div className="">
                            <button
                              className="Submit-btn"
                              //  onClick={() => addreview(true)}
                            >
                              Submit
                            </button>
                          </div>
                        </div>
                      </Modal>
                      <button className="rating-button55" onClick={Imagelink}>
                        Acknowledged Effort & Compensation{" "}
                      </button>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </section>
    </>
  );
};

export default Mybooking2;
