import React, { useEffect, useState } from "react";
import "../Css/Notification.css";
import Navbar2 from "../Component/Navbar2";
import Footer2 from "./Footer2";
import axios from "../schemas/Api";

const Notification = () => {
  const [notification, setNotification] = useState([]);
  console.log("notification", notification);
  const data = JSON.parse(localStorage.getItem("Nezziedata"));

  useEffect(() => {
    notificationdata();
  }, []);

  const notificationdata = async () => {
    try {
      const resp = await axios.post(
        "notification/myNotifications",
        {},
        {
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            Authorization: data.token,
          },
        }
      );
      setNotification(resp.data.data);
      console.log(resp);
    } catch (error) {
      console.log("Error", error);
    }
  };

  // notification api integrated at here

  return (
    <>
      <Navbar2 />
      <div className="Notification-main-sections">
        {" "}
        <div className="Notification-spn-divs-papa">
          <div className="Notification-spn-divs">
            <span className="Notification-spn">Notification</span>
          </div>
          {/* <div>maksndknd</div> */}
        </div>
      </div>

      <Footer2 />
    </>
  );
};

export default Notification;
