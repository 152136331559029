import React, { useState, useEffect } from "react";
import "../Css/AdvocateDetail.css";
// import hire1 from "../Images/Hire1.png";
import favestar from "../Images/favestar.png";
// import { useState } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import user1 from "../Images/user1.png";
import user2 from "../Images/user2.png";
import axios from "../schemas/Api";
import ssstar from "../Images/ssstar.png";
import ksstar from "../Images/ksstar.png";
import Navbar2 from "./Navbar2";
import Footer2 from "./Footer2";
// import BookNow from "./BookNow"
import DatePicker from "react-datepicker";
// import { CListGroup } from "@coreui/react";
import { useLocation } from "react-router-dom";
import CircularProgress from "@mui/material/CircularProgress";
import moment from "moment";
import { useNavigate } from "react-router-dom";

const AdvocateDetail = () => {
  const [todaySlots, setTodaySlots] = useState([]);
  const [tomorrowSlots, setTomorrowSlots] = useState([]);
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(null);
  const [showLoading, setShowLoading] = useState(false);
  const navigate = useNavigate();
  // const mainavailableSlots = [
  //   "8:00 to 8:30 AM",
  //   "8:30 to 9:00 AM",
  //   "9:00 to 9:30 AM",
  //   "9:30 to 10:00 AM",
  //   "10:00 to 10:30 AM",
  //   "10:30 to 11:00 AM",
  //   "11:00 to 11:30 AM",
  //   "11:30 to 12:00 PM",
  //   "12:00 to 12:30 PM",
  //   "12:30 to 10:00 PM",
  // ];
  const [selectedDate, setSelectedDate] = useState("today");
  const [selectedSlots, setSelectedSlots] = useState([]);
  const [isloading, setIsLoading] = useState(true);

  const route = useLocation();
  const maindata = route.state.item.advocateId;
  // console.log(maindata, "maindata");
  const [showModal, setShowModal] = useState(false);
  const handleDateSelection = async (date) => {
    setSelectedDate(date);
    setSelectedSlots([]); // Clear selected slots when changing the date
    await fetchAvailableSlots(date);
    if (date === "today") {
      setSelectedSlots(todaySlots);
    } else {
      setSelectedSlots(tomorrowSlots);
    }
  };
  console.log("Selected date:", selectedDate);
  useEffect(() => {
    const currentDate = moment().format("YYYY-MM-DD");
    const formattedStartDate = startDate
      ? moment(startDate).format("YYYY-MM-DD")
      : null;

    if (formattedStartDate === currentDate) {
      setSelectedDate("today");
    } else {
      setSelectedDate("tomorrow");
    }

    // console.log(selectedDate);
  }, [startDate]);

  const onChange = (dates) => {
    const [start, end] = dates;
    setStartDate(start);
    setEndDate(end);
  };

  // modal open function
  const handleShowModal = () => {
    setShowModal(true);
    // idData();
  };

  const handleCloseModal = () => {
    setShowModal(false);
  };
  const handleSlotSelection = (slot) => {
    if (selectedSlots.includes(slot)) {
      setSelectedSlots(selectedSlots.filter((s) => s !== slot));
    } else {
      if (selectedSlots.length === 0 || selectedSlots.length === 1) {
        setSelectedSlots([...selectedSlots, slot]);
      } else {
        console.log("You can only select up to 2 slots.");
        // console.log(selectedSlots);
      }
    }
  };

  // advocates details api usestate
  const [show, setShow] = useState(false);
  const [details, setDetails] = useState([]);
  const data = JSON.parse(localStorage.getItem("Nezziedata"));
  useEffect(() => {
    detailsData();
  }, []);
  // // advocates details api useeffefect

  // console.log(details);
  // advocates details api
  const detailsData = async () => {
    try {
      const response = await axios.post(
        "details/advocatedetails",
        {
          advocate_id: maindata,
        },
        {
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            Authorization: data.token,
          },
        }
      );

      setDetails([response.data.data]);
      setIsLoading(false);
      console.log(response);
    } catch (error) {
      console.log("Error", error);
    }
  };
  //get slot api integaretd at here
  useEffect(() => {
    // setIsLoading(true);
    fetchAvailableSlots(selectedDate);
  }, [selectedDate]);

  const fetchAvailableSlots = async (selectedDate) => {
    try {
      setShowLoading(true);
      const currentDate =
        selectedDate === "today" ? moment() : moment().add(1, "day");
      const formattedDate = currentDate.format("YYYY-MM-DD");
      const resp = await axios.post(
        "slots/get_slots",
        {
          advocateID: maindata,
          date: formattedDate,
        },
        {
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            Authorization: data.token,
          },
        }
      );
      const currentTime = moment();
      // console.log(currentTime);
      const slotsData = (resp.data.data || [])
        .map((slot) => slot.slotTime)
        // .filter((slotTime) =>
        //   moment(`${formattedDate} ${slotTime}`).isAfter(currentTime)
        // );
        .filter((slotTime) => {
          const formattedDateTime = `${formattedDate} ${slotTime}`;
          const slotDateTime = moment(formattedDateTime, "YYYY-MM-DD HH:mm A");
          return slotDateTime.isAfter(currentTime);
        });
      if (selectedDate === "today") {
        setTodaySlots(slotsData);
        console.log("Slots for today:", slotsData);
      } else {
        setTomorrowSlots(slotsData);
        console.log("Slots for tomorrow:", slotsData);
      }
      setShowLoading(false);
      console.log(resp.data.data);
    } catch (error) {
      console.log("Error fetching slots", error);
    }
  };
  // add booking api integrated at here

  const handleBookNow = async () => {
    try {
      const respo = await axios.post(
        "appointment/addbooking",
        {
          advocateID: maindata,
          bookedDate: moment(startDate).format("YYYY-MM-DD"),
          bookedTime: selectedSlots.join(", "),
          mettingType: "inPerson",
        },
        {
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            Authorization: data.token,
          },
        }
      );
      if (respo.data.message === "Appointment Booked Successfully") {
        navigate("/Congratulations");
      }

      console.log("booking response", respo.data);
    } catch (error) {
      console.log("Error", error);
    }
  };

  // const [reveiwdata, setReveiwdata] = useState([]);
  // review api usestate
  // useEffect(() => {
  //   reviewList();
  // }, []);
  // console.log(reveiwdata);
  // // review api useeffefect
  // reviewList api
  // const reviewList = async () => {
  //   try {
  //     const respns = await axios.post(
  //       "review/reviewList",
  //       { advocateId: maindata, pageNumber: 1, pageSize: 2 },
  //       {
  //         headers: {
  //           "Content-Type": "application/json",
  //           Accept: "application/json",
  //           Authorization: data.token,
  //         },
  //       }
  //     );
  //     setReveiwdata(respns.data.reviewList);
  //     console.log(respns);
  //   } catch (error) {
  //     console.log("Error", error);
  //     setReveiwdata([]);
  //   }
  // };

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  return (
    <>
      <Navbar2 />
      {details.map((item, index) => {
        /* console.log(item); */

        let Ratingcount = item?.review_total_count;
        let MaxRate = 5;
        let yellowstar = Math.min(Ratingcount, MaxRate);
        let RatingcountImage = Array.from({ length: yellowstar }, (_, i) => (
          <img key={i} src={ssstar} alt="" />
        ));
        let GrayStarCount = MaxRate - yellowstar;
        let GrayStarImage = Array.from({ length: GrayStarCount }, (_, i) => (
          <img key={i + yellowstar} src={ksstar} alt="" />
        ));
        let starRatingimages = [...RatingcountImage, ...GrayStarImage];
        return (
          <section className="AdvocateDetail-main-sec" key={item.id}>
            <div className="AdvocateDetail-main-div">
              {isloading ? (
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    height: "100vh",
                  }}
                >
                  <CircularProgress />
                </div>
              ) : (
                <div className="AdvocateDetail-second-div">
                  <div className="maincar-css-kaliya">
                    <div className="maoincard-one">
                      <img className="hire1-card-img" src={item.image} alt="" />
                      <span className="Jacob-Jones-span">{item.fullName}</span>
                      <div>
                        {starRatingimages.map((item) => {
                          return (
                            <img
                              src={item.props.src}
                              className="star-img-90"
                              alt=""
                            />
                          );
                        })}
                        <span className="reviews-879-span">(0 Reviews)</span>
                      </div>
                      <div className="price-and">
                        <span className="dollar-spn12">
                          ${item.ratePerHours}
                        </span>
                        <span className="per-hours-span98"> / per hour</span>
                      </div>
                      <button className="send-message-button">
                        Send Message
                      </button>
                    </div>
                  </div>
                  <div className="About-maindib-jocob">
                    <span className="About-span">About {item.fullName}</span>
                    <div className="redmore-spann">
                      <span className="Contrary-span">{item.aboutYou}</span>
                      <span className="red-read-more">...Read More</span>
                    </div>
                    <div className="Skills-div">
                      <span className="Skills-span">Skills</span>
                      <div className="all-btn-din">
                        {item.skills.map((skill) => (
                          <button className="Lorem-Ipsum-btn" key={skill._id}>
                            {skill.skill}
                          </button>
                        ))}
                      </div>
                    </div>
                    <div className="Resolution-div">
                      <span className="Resolution-span">
                        Resolution Experience
                      </span>
                      <div className="redmore-spann">
                        <span className="Contrary-span">
                          {item.resolutionExperiance}
                        </span>
                        <span className="red-read-more">...Read More</span>
                      </div>
                    </div>
                    <div className="Launch-model-divv">
                      <Button
                        className="Check-Availability-btn"
                        variant="primary"
                        onClick={handleShow}
                      >
                        Check Availability
                      </Button>
                      <Button
                        className="book-vedio-call-btn"
                        variant="primary"
                        onClick={handleShowModal}
                      >
                        Book Audio Call
                      </Button>

                      <Modal show={showModal} onHide={handleCloseModal}>
                        <Modal.Header closeButton>
                          <Modal.Title>Book Now</Modal.Title>
                        </Modal.Header>
                        <div className="Select-div">
                          <DatePicker
                            selected={startDate}
                            onChange={onChange}
                            startDate={startDate}
                            endDate={endDate}
                            selectsRange
                            inline
                            minDate={new Date()}
                          />
                          <span className="Select-time-span">Select Time</span>
                          <div className="mt-2 all-time-btn">
                            {showLoading ? (
                              <div
                                style={{
                                  display: "flex",
                                  justifyContent: "center",
                                  alignItems: "center",
                                  height: "200px",
                                }}
                              >
                                <CircularProgress />
                              </div>
                            ) : selectedDate === "today" ? (
                              todaySlots.map((slot, index) => (
                                <button
                                  className={`time-apoy-mant ${
                                    selectedSlots.includes(slot)
                                      ? "selected-slot"
                                      : ""
                                  }`}
                                  key={index}
                                  onClick={() => handleSlotSelection(slot)}
                                >
                                  {slot}
                                </button>
                              ))
                            ) : (
                              tomorrowSlots.map((slot, index) => (
                                <button
                                  className={`time-apoy-mant ${
                                    selectedSlots.includes(slot)
                                      ? "selected-slot"
                                      : ""
                                  }`}
                                  key={index}
                                  onClick={() => handleSlotSelection(slot)}
                                >
                                  {slot}
                                </button>
                              ))
                            )}
                          </div>

                          <button
                            className="Book-Now-text mt-4"
                            onClick={handleBookNow}
                          >
                            Book Now
                          </button>
                          <div className="mb-4"></div>
                        </div>
                      </Modal>

                      <Modal show={show} onHide={handleClose}>
                        <Modal.Header closeButton>
                          <Modal.Title>Check Availability</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                          <div className="model-img">
                            <img
                              className="hire1-model-img"
                              src={item.image}
                              alt=""
                            />
                            <div className="moidel-user">
                              <span className="jacob-span-90">
                                {item.fullName}
                              </span>

                              <div>
                                {starRatingimages.map((item) => {
                                  return (
                                    <img
                                      src={item.props.src}
                                      className="star-img-90"
                                      alt=""
                                    />
                                  );
                                })}
                              </div>
                              <div className="pay-user">
                                <span className="dolar-span19">
                                  {item.ratePerHours}
                                </span>
                                <span className="per-hour-tsapn">
                                  / per hour
                                </span>
                              </div>
                            </div>
                          </div>
                          <Modal.Body className="model-ko-use-kiya">
                            <span className="Availability-span-second">
                              Availability
                            </span>
                          </Modal.Body>
                          <div className="mt-4 Today-tommoro-span">
                            <span
                              className={`Today-span ${
                                selectedDate === "today" ? "selected" : ""
                              }`}
                              onClick={() => handleDateSelection("today")}
                            >
                              Today
                            </span>
                            <span
                              className={`Today-span ${
                                selectedDate === "tomorrow" ? "selected" : ""
                              }`}
                              onClick={() => handleDateSelection("tomorrow")}
                            >
                              Tomorrow
                            </span>
                          </div>
                          <div className="mt-2 all-time-btn">
                            {showLoading ? (
                              <div
                                style={{
                                  display: "flex",
                                  justifyContent: "center",
                                  alignItems: "center",
                                  height: "200px", // Adjust height as needed
                                }}
                              >
                                <CircularProgress />
                              </div>
                            ) : selectedDate === "today" ? (
                              todaySlots.map((slot, index) => (
                                <button
                                  className={`time-apoy-mant ${
                                    selectedSlots.includes(slot)
                                      ? "selected-slot"
                                      : ""
                                  }`}
                                  key={index}
                                  onClick={() => handleSlotSelection(slot)}
                                >
                                  {slot}
                                </button>
                              ))
                            ) : (
                              tomorrowSlots.map((slot, index) => (
                                <button
                                  className={`time-apoy-mant ${
                                    selectedSlots.includes(slot)
                                      ? "selected-slot"
                                      : ""
                                  }`}
                                  key={index}
                                  onClick={() => handleSlotSelection(slot)}
                                >
                                  {slot}
                                </button>
                              ))
                            )}
                          </div>
                        </Modal.Body>

                        <Modal.Footer>
                          <Button
                            type="submit"
                            className="Video-btn"
                            variant="primary"
                            // onClick={handleClose}
                          >
                            Book Video Call
                          </Button>
                        </Modal.Footer>
                      </Modal>
                    </div>
                    <div className="line-span"></div>
                    <div className="mt-3">
                      <span className="Reviews-span">Reviews</span>
                      <div className="Exceptional-rewive">
                        <span className="Exceptional-span">
                          Exceptional 4.0
                        </span>
                        <span className="do-rewive">233 Reviews</span>
                      </div>
                      <div>
                        <img className="second-do-bar" src={favestar} alt="" />
                      </div>
                    </div>
                    <div>
                      <div>
                        <span>5</span>
                        <svg
                          width="14"
                          height="14"
                          viewBox="0 0 14 14"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <g clip-path="url(#clip0_17_7791)">
                            <path
                              d="M7.00063 9.91665L3.57179 12.0108L4.50396 8.10248L1.45312 5.48915L5.45771 5.16831L7.00063 1.45831L8.54354 5.16831L12.5487 5.48915L9.49729 8.10248L10.4295 12.0108L7.00063 9.91665Z"
                              fill="#E8A700"
                            />
                          </g>
                          <defs>
                            <clipPath id="clip0_17_7791">
                              <rect width="14" height="14" fill="white" />
                            </clipPath>
                          </defs>
                        </svg>
                        <div className="mb-5 gryy-line-hs">
                          <div className="grya-ab-ha"></div>
                        </div>
                      </div>
                      <div className="reweive-main">
                        <div>
                          <img className="rewive-user" src={user1} alt="" />
                        </div>
                        <div className="Warren-span-rewvi">
                          <div className="Warren-main-div">
                            <div className="date-and-name">
                              <span className="Wade-span"> Wade Warren</span>
                              <span className="dec-date">12 Dec 2022</span>
                            </div>
                            <div>
                              <img
                                className="tara-imag"
                                src={favestar}
                                alt=""
                              />
                            </div>
                          </div>
                          <span className="venenatis-span">
                            Mauris venenatis, felis in dictum sagittis, elit
                            nunc dignissim massa, eget feugiat nibh est a nibh.
                            Aliquam eu nibh sit amet augue consectetur pretium
                            sed sit amet leo.
                          </span>
                        </div>
                      </div>
                      <div className="line-div mt-4 mb-4"></div>

                      <div className="reweive-main">
                        <div>
                          <img className="rewive-user" src={user2} alt="" />
                        </div>
                        <div className="Warren-span-rewvi">
                          <div className="Warren-main-div">
                            <div className="date-and-name">
                              <span className="Wade-span"> Wade Warren</span>
                              <span className="dec-date">12 Dec 2022</span>
                            </div>
                            <div>
                              <img
                                className="tara-imag"
                                src={favestar}
                                alt=""
                              />
                            </div>
                          </div>
                          <span className="venenatis-span">
                            Mauris venenatis, felis in dictum sagittis, elit
                            nunc dignissim massa, eget feugiat nibh est a nibh.
                            Aliquam eu nibh sit amet augue consectetur pretium
                            sed sit amet leo.
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </section>
        );
      })}
      <Footer2 />
    </>
  );
};

export default AdvocateDetail;
