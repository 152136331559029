import React from "react";
import mapStyel from "./mapStyel";
import { GoogleMap, Marker } from "@react-google-maps/api";
import Navbar2 from "./Navbar2";
import Footer2 from "./Footer2";
import "../Css/NearMe.css";
import { useState } from "react";
import axios from "../schemas/Api";
import { useEffect } from "react";
import marker from "../Images/marker.png";
const containerStyle = {
  width: "100%",
  height: "500px",
  marginTop: "2rem",
};

// const center = {
//   lat: 39.722504,
//   lng: -75.654544,
// };

const apiKey = "AIzaSyB86XJujeIEHyCA9YZE15d3rKYfKNVfP0A";

const NearMe = () => {
  const [advocate, setAdvocate] = useState([]);
  const [mapCenter, setMapCenter] = useState(null);
  const data = JSON.parse(localStorage.getItem("Nezziedata"));

  useEffect(() => {
    adData();
  }, []);
  // localStorage.getItem("lat");
  // localStorage.getItem("lon");
  const adData = async () => {
    try {
      const latitude = parseFloat(localStorage.getItem("lat"));
      const longitude = parseFloat(localStorage.getItem("lon"));

      if (isNaN(latitude) || isNaN(longitude)) {
        console.log("Invalid latitude or longitude");
        return;
      }
      // console.log("Latitude:", latitude);
      // console.log("Longitude:", longitude);
      const response = await axios.post(
        "advocate/near_by_advocate",
        {
          longitude: longitude,
          latitude: latitude,
        },
        {
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            Authorization: data.token,
          },
        }
      );
      const advocatesFromAPI = response.data.data.map((advocate) => ({
        ...advocate,
        latitude: parseFloat(advocate.latitude), // Convert to number
        longitude: parseFloat(advocate.longitude), // Convert to number
      }));
      setAdvocate(advocatesFromAPI);
      if (advocatesFromAPI.length > 0) {
        const firstAdvocate = advocatesFromAPI[0];
        setMapCenter({
          lat: firstAdvocate.latitude,
          lng: firstAdvocate.longitude,
        });
      }
      console.log(response);
    } catch (error) {
      console.log("Error", error);
    }
  };

  return (
    <>
      <Navbar2 />
      <section className="near-me-section-main-div">
        <div
          style={{
            display: "flex",
            justifyContent: "flex-start",
            alignItems: "flex-start",
            width: "90%",
            marginTop: "1rem",
          }}
        >
          <span
            className="near-me-span111"
            style={{
              fontSize: "27px",
              fontWeight: "600",
              color: " #1e2d38",
            }}
          >
            Near Me
          </span>
        </div>
        <div>
          <GoogleMap
            mapContainerStyle={containerStyle}
            center={mapCenter}
            zoom={11}
            options={{
              styles: mapStyel,
            }}
            googleMapsApiKey={apiKey}
          >
            {advocate.map((advocate) => {
              if (
                typeof advocate.latitude === "number" &&
                typeof advocate.longitude === "number"
              ) {
                {
                  /* console.log("Marker:", advocate); */
                }
                const markerStyle = {
                  display: "inline-block",
                  borderRadius: "50%", // Adjust the border radius as needed
                  overflow: "hidden",
                };

                return (
                  <Marker
                    key={advocate.id}
                    position={{
                      lat: advocate.latitude,
                      lng: advocate.longitude,
                    }}
                    icon={{
                      url: marker, // URL of the advocate's image
                      scaledSize: new window.google.maps.Size(50, 50), // Adjust the size as needed
                    }}
                  >
                    <div style={markerStyle}>
                      {/* <img src={marker} alt="" /> */}
                      <img
                        src={marker}
                        alt={`Advocate ${advocate.fullName}`}
                        className="bjjhwefv"
                        style={{
                          width: "100px !important",
                          height: "100px !important",
                          borderRadius: "50% !important",
                          objectFit: "cover",
                        }}
                      />
                    </div>
                  </Marker>
                );
              } else {
                console.log(
                  "Invalid latitude or longitude in advocate:",
                  advocate
                );
                return null; // Skip this marker
              }
            })}
          </GoogleMap>
        </div>
      </section>
      <Footer2 />
    </>
  );
};

export default NearMe;
