import React from "react";
import "../Css/Unbelievable.css";
import line from "../Images/Vector.png";
import Advocates from "../Images/Rectangle.png";
import Grop from "../Images/Group.png";
const Unbelievable = () => {
  return (
    <>
      <section className="Unbelievable-sectino">
        <div className="Unbelievable-div">
          <div className="Unbelievable-itm-div">
            <div className="line-ka-liya">
              <span className="Unbelievable-span">
                {/* Nezzie™ a global online marketplace where freelancers advocate
                for resolving customer disputes and provide expert
                representation.{" "} */}
                Nezzie™ Where Guidance Meets Resolution
              </span>
              <img className="line-img" src={line} alt="" />
            </div>
            <div className="ebebbvbev">
              <span className="printing-span">
                {/* Lorem Ipsum is simply dummy text of the printing and typesetting
                industry. Lorem Ipsum has been the industry's standard dummy
                text ever since the 1500s. */}
                Empowering Collaborative Solutions for Business Disputes
              </span>
              <button className="Advocate-btn">Explore Nezzie™</button>
            </div>
          </div>{" "}
          <div className="jbrjber">
            <div className="do-img">
              <img className="Advocates-img" src={Advocates} alt="" />
              <img className="grop-img" src={Grop} alt="" />
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Unbelievable;
