import React, { useState, useEffect } from "react";
import { FiArrowUpRight } from "react-icons/fi";
import { NewSchemas } from "../schemas";
import { useFormik } from "formik";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import axios from "../schemas/Api";
import ServiceComplaints from "./ServiceComplaints";
import OnlineTransaction from "./OnlineTransaction";
import { useContext } from "react";
import { Globalcontext } from "../Context/GlobalContext";

const initialValues = {
  rate: "",
};

const New = () => {
  const [bdata, setbData] = useState(0);
  const { context, setContext } = useContext(Globalcontext);

  const btns = [
    {
      id: "1",
      Name: "Sexual Harassment",
    },
    {
      id: "2",
      Name: "Hostile Work Environment",
    },
    {
      id: "3",
      Name: "Bullying and Intimidation",
    },
    {
      id: "4",
      Name: "Psychological Harassment",
    },
  ];

  const {
    values,
    handleBlur,
    handleChange,
    touched,
    // handleSubmit,
    // setFieldValue,
    errors,
    // setTouched,
  } = useFormik({
    initialValues: initialValues,
    validationSchema: NewSchemas,
    // Address: address,
    // onSubmit,
  });
  const handlemodal = (i) => {
    setbData(i);
    setContext(true);
  };
  return (
    <div className="second-post-main-section">
      <div className="both-categories-90-div-9k">
        {btns.map((item, i) => {
          return (
            <>
              <button
                // className="Product-Issues-button-unactive"
                className={
                  bdata === i
                    ? "Product-Issues-button22"
                    : "Product-Issues-button-unactive22"
                }
                key={i}
                onClick={() => handlemodal(i)}
              >
                {item.Name}
              </button>
            </>
          );
        })}
      </div>
      {bdata === 0 ? (
        <></>
      ) : bdata === 1 ? (
        <></>
      ) : bdata === 2 ? (
        <></>
      ) : bdata === 3 ? (
        <></>
      ) : null}
    </div>
  );
};

export default New;
