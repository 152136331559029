import React from "react";
import "../Css/Tip.css";

const Tip = () => {
  return (
    <section className="tip-main-section">
      <div className="mini-tip-div78">
        <div className="tip-span-div">
          <span className="tip-span">$12.93</span>
          <span className="tip-span-more">Add a tip</span>
        </div>
        <div className="nunu-3div-main">
          <div className="flex-div-bhai">
            <div className="inner-div-tip">
              <span className="percenetga-span98">15%</span>
              <span className="new-dollar-spn">$2.45</span>
            </div>
            <div className="inner-div-tip">
              {" "}
              <span className="percenetga-span98">20%</span>
              <span className="new-dollar-spn">$3.26</span>
            </div>
            <div className="inner-div-tip">
              {" "}
              <span className="percenetga-span98">25%</span>
              <span className="new-dollar-spn">$4.08</span>
            </div>
          </div>
          <div className="custome-button-div45">
            <div>
              <button className="Custome-button-niv">Custome Tip Amount</button>
            </div>
            <div>
              <button className="Custome-button-niv">No Tip</button>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Tip;
