import React, { useEffect, useState } from "react";
import "../Css/MyPost.css";
// import payimg from "../Images/payimg.png";
// import editimg from "../Images/editimg.png";
// import deleteimg from "../Images/deleteimg.png";
// import payimg from "../Images/pay";
import EditImg from "../Images/EditImg.png";
import DeleteImg from "../Images/DeletImg.png";
import axios from "../schemas/Api";
import CircularProgress from "@mui/material/CircularProgress";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useNavigate } from "react-router-dom";

const MyPost = () => {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(true); // Initialize as true because data is initially loading

  const [mypostdatag, setMypostdatag] = useState([]);
  const data = JSON.parse(localStorage.getItem("Nezziedata"));

  // my post api integrated at here

  useEffect(() => {
    newmypostData();
  }, []);
  const newmypostData = async () => {
    try {
      setIsLoading(true);
      const response = await axios.post(
        "post/myPost",
        {},
        {
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            Authorization: data.token,
          },
        }
      );
      setMypostdatag(
        response.data.data.myPost.map((post) => ({
          ...post,
          formattedCreatedAt: formatCreatedAt(post.createdAt),
        }))
      );
      // setMypostdatag(response.data.data.myPost);
      console.log(response);
      // console.log("mypostdatag:", mypostdatag);
      setIsLoading(false);
    } catch (error) {
      console.log("Error", error);
    }
  };
  // delete post api integrated at here

  const handleDeletepost = async (_id) => {
    try {
      const resp = await axios.post(
        "post/deletePost",
        { post_id: _id },
        {
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            Authorization: data.token,
          },
        }
      );
      console.log(resp);
      if (resp.data.message === "Post Deleted Successfully") {
        toast.success(resp.data.message, {
          position: "top-right",
          theme: "colored",
        });
        newmypostData();
      }
    } catch (error) {
      console.log("Error", error);
      toast.error(error.resp.data.message, {
        position: "top-right",
        theme: "colored",
      });
    }
  };

  // const mypostdata1 = [
  //   {
  //     text: "I need someone to view my Customer work like Product Issues and disputes.",
  //     Dollr: "Hourly $ 40 -50",
  //     post: "posted 4 hour ago",
  //     Hour: "Less than 30 hrs/week",
  //     Dura: "Duration",
  //     Need: "Hours needed",
  //     Week: "2 Weeks",
  //     Exin: "Experience In",
  //     Location: "Location",
  //     dallas: "Dallas, TX",
  //     Contract: "Contract disputes",
  //     img11: EditImg,
  //     img12: DeleteImg,
  //     payment: "payment Verified",
  //   },
  // ];
  const formatCreatedAt = (createdAt) => {
    const currentTime = new Date();
    const postTime = new Date(createdAt);
    const timeDifference = currentTime - postTime;
    const seconds = Math.floor(timeDifference / 1000);
    const minutes = Math.floor(seconds / 60);
    const hours = Math.floor(minutes / 60);
    const days = Math.floor(hours / 24);

    if (days > 0) {
      return `${days} day${days > 1 ? "s" : ""} ago`;
    } else if (hours > 0) {
      return `${hours} hour${hours > 1 ? "s" : ""} ago`;
    } else if (minutes > 0) {
      return `${minutes} minute${minutes > 1 ? "s" : ""} ago`;
    } else {
      return `${seconds} second${seconds > 1 ? "s" : ""} ago`;
    }
  };
  return (
    <section className="MyPost-main-section">
      <div className="MyPost-main-divgap">
        <div className="my-post-single-div">
          {" "}
          <span className="my-post-single-span">My Post</span>
        </div>
        <div className="map-my-post-div">
          {isLoading ? (
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                width: "850px",
                height: "300px",
              }}
            >
              <CircularProgress />
            </div>
          ) : Array.isArray(mypostdatag) && mypostdatag.length > 0 ? (
            mypostdatag.map((item10, index) => (
              <div className="mypost-height-width-div" key={index}>
                <span className="text101-span">
                  I need someone to view my{" "}
                  <span className="specialist-name-span">
                    {item10.speciallityName}
                  </span>{" "}
                  work like{" "}
                  <span className="specialist-name-span">
                    {item10.category1Id}
                  </span>{" "}
                  and other disputes.
                </span>

                {/* <div className="item10-Dollr-span-div">
                  <span className="item10-Dollr-span">
                    {" "}
                    Hourly ${item10.rate}
                  </span>
                  <span className="item10-Dollr-span">
                    posted {item10.formattedCreatedAt}
                  </span>
                </div> */}
                <div className="Experience-In-opn-div">
                  <div className="dfghjkjhghgu">
                    <span className="Experience-In-opn">Experience In</span>
                    <span className="item10-Dollr-span">
                      {" "}
                      {item10.speciallityName}
                    </span>
                  </div>
                  <div className="dfghjkjhghgu">
                    <span className="Experience-In-opn">Expected Rate</span>
                    <span className="item10-Dollr-span">$ {item10.rate}</span>
                  </div>
                </div>

                <div className="View-Offers-div123">
                  <div>
                    <button
                      className="View-Offers-btn-000"
                      onClick={() => handleDeletepost(item10._id)}
                    >
                      Delete
                    </button>
                  </div>
                  <div>
                    <button
                      className="View-Offers-btn"
                      onClick={() => navigate("/Viewoffer")}
                    >
                      View Offers
                    </button>
                  </div>
                </div>

                {/* <div className="paymnet-verified-div12">
                  <div className="circle11-div">
                    <img src={EditImg} alt="" className="edit-delete-img" />
                  </div>
                  <div
                    className="circle11-div"
                    onClick={() => handleDeletepost(item10._id)}
                  >
                    <img src={DeleteImg} alt="" className="edit-delete-img" />
                  </div>
                </div> */}
              </div>
            ))
          ) : (
            <p>No data available</p>
          )}
        </div>
      </div>
    </section>
  );
};

export default MyPost;
