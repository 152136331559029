import React, { useEffect, useState } from "react";
import "../Css/Support.css";
import { SupportSchemas } from "../schemas";
import { useFormik } from "formik";
import axios from "../schemas/Api";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const initialValues = {
  email: "",
  Subject: "",
  Writehere: "",
};

const Support = () => {
  // const [isFormSubmitted, setIsFormSubmitted] = useState(false);
  //  Support message api integrated at here
  const data = JSON.parse(localStorage.getItem("Nezziedata"));
  // console.log(data);
  const onSubmit = async () => {
    try {
      const response = await axios.post(
        "supports/addSupportsMessage",
        { supportsMessage: "help" },
        {
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            Authorization: data.token,
          },
        }
      );
      if (response.data.message === "Message Successfully Sent") {
        toast.success(response.data.message, {
          position: "top-right",
          theme: "colored",
        });
        // values.email = "";
        values.Writehere = "";
        values.Subject = "";
      }
      console.log(response);
    } catch (error) {
      toast.error(error.res.data.message, {
        position: "top-right",
        theme: "colored",
      });

      console.log("Error", error);
    }
  };

  const {
    values,
    handleBlur,
    handleChange,
    touched,
    handleSubmit,
    setFieldValue,
    errors,
  } = useFormik({
    initialValues: initialValues,
    validationSchema: SupportSchemas,

    onSubmit,
  });

  useEffect(() => {
    setFieldValue("email", data.email);
  }, []);
  return (
    <form onSubmit={handleSubmit} className="support-main-section">
      <div className="child-child-div5">
        {" "}
        <span className="support-span-oyy">Support</span>
      </div>
      <div className="sendu-main-div">
        <div className="child-support-divs">
          <div className="input-tyype-email-div">
            <span className="email-span76">Email</span>
            <input
              type="email"
              className="email-input-changes123"
              // placeholder="email"
              name="email"
              style={{ cursor: "not-allowed" }}
              disabled={true}
              value={values.email}
              onChange={handleChange}
              onBlur={handleBlur}
            />
            {errors.email && touched.email ? (
              <p className="mastu-to-eroor mt-2">{errors.email} </p>
            ) : null}
          </div>
          <div className="input-tyype-email-div">
            <span className="email-span76">Subject</span>
            <input
              type="text"
              className="email-input-changes"
              placeholder="Subject"
              name="Subject"
              value={values.Subject}
              onChange={handleChange}
              onBlur={handleBlur}
            />
            {errors.Subject && touched.Subject ? (
              <p className="mastu-to-eroor mt-2">{errors.Subject} </p>
            ) : null}
          </div>
        </div>
        <div className="input-tyype-email-div">
          <span className="email-span76">Message</span>

          <textarea
            placeholder="Write here"
            name="Writehere"
            id=""
            cols="30"
            rows="10"
            className="email-input-changes-masseg"
            value={values.Writehere}
            onChange={handleChange}
            onBlur={handleBlur}
          ></textarea>
          {errors.Writehere && touched.Writehere ? (
            <p className="mastu-to-eroor mt-2">{errors.Writehere} </p>
          ) : null}
        </div>
        <div>
          <button className="support-submit-button" type="submit">
            Submit
          </button>
        </div>
      </div>
    </form>
  );
};

export default Support;
