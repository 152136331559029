import React, { useState } from "react";
import "../Css/JobPosts.css";
import post from "../Images/ad6.png";
import post1 from "../Images/Post1.png";
import post2 from "../Images/Post2.png";
import post3 from "../Images/Post3.png";
import post4 from "../Images/Post4.png";
// import Mm from "../Component/Mm";

import { useNavigate } from "react-router-dom";
import Navbar1 from "./Navbar1";
import Footer2 from "./Footer2";
import axios from "../schemas/APi1";
import { useEffect } from "react";
// import Open from "./Open";
import Modal from "react-bootstrap/Modal";
import { JobpostSchemas } from "../schemas";
import { useFormik } from "formik";
import "../Css/Open.css";
import CircularProgress from "@mui/material/CircularProgress";

const initialValues = {
  Message: "",
  Price: "",
};
const JobPosts = () => {
  const [getpost, setGetpost] = useState([]);
  // console.log(getpost);
  // const [isLoading, setIsLoading] = useState(true);

  // const [open, setOpen] = useState(false);
  const dataspecuails = localStorage.getItem("specialitydata");
  // console.log(dataspecuails);
  const data = JSON.parse(localStorage.getItem("Nezziedata"));

  // get post api integrated at  here

  // const ok = async () => {
  //   try {
  //     const resp = await axios.post(
  //       "meta_data/speciallity_data",
  //       {
  //         speciallityId: dataspecuails,
  //       },
  //       {
  //         headers: {
  //           "Content-Type": "application/json",
  //           Accept: "application/json",
  //           Authorization: data.token,
  //         },
  //       }
  //     );

  //     console.log(resp.data.data[0], " tera mu me");
  //   } catch (error) {
  //     console.log("Error", error);
  //   }
  // };
  // useEffect(() => {
  //   ok();
  // }, []);
  const getpostdatas = async () => {
    try {
      const resp = await axios.post(
        "post/getPost",
        {
          speciallity: dataspecuails,
          categoryId1: "64ed931d5feec882801375ee",
          categoryId2: "64ed93875feec88280137603",
          pageNumber: "1",
        },
        {
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            Authorization: data.token,
          },
        }
      );
      setGetpost(resp.data.data);
      // setIsLoading(false);
      console.log(resp);
    } catch (error) {
      console.log("Error", error);
      // setIsLoading(false);
    }
  };
  useEffect(() => {
    getpostdatas();
  }, []);
  const pos = [
    {
      img: post1,
      Name: "Jacob Jones",
    },
    {
      img: post2,
      Name: "Wade Warren",
    },
    {
      img: post3,
      Name: "Eleanor Pena",
    },

    {
      img: post4,
      Name: "Cody Fisher",
    },
  ];

  const {
    values,
    handleBlur,
    handleChange,
    touched,
    // handleSubmit,
    // setFieldValue,
    errors,
    // setTouched,
  } = useFormik({
    initialValues: initialValues,
    validationSchema: JobpostSchemas,
    // Address: address,
    // onSubmit,
  });

  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [smShow, setSmShow] = useState(false);
  const [lgShow, setLgShow] = useState(false);
  const navigate = useNavigate();
  return (
    <>
      <Navbar1 />
      <section className="section-JobPosts-main">
        <div className="div-JobPosts-main">
          <div className="svg-span-or">
            <span className="job-post-span-999">Job Posts</span>

            <div onClick={() => navigate("/Filter")}>
              <div onClick={() => setSmShow(true)} className="filtar-selacct">
                <svg
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <g clip-path="url(#clip0_38_25041)">
                    <path
                      d="M10 18H14V16H10V18ZM3 6V8H21V6H3ZM6 13H18V11H6V13Z"
                      fill="#1E2D38"
                    />
                  </g>
                  <defs>
                    <clipPath id="clip0_38_25041">
                      <rect width="24" height="24" fill="white" />
                    </clipPath>
                  </defs>
                </svg>
                <span>Filter</span>
              </div>
            </div>
          </div>
          <div className="meri-duniya-grid">
            {pos.map((curelem, index) => {
              return (
                <div key={index} className="Contract-disputes-like-div">
                  <span className="Misleading-spn">
                    I need someone to view my{" "}
                    <span className="special-ligal-span">legal</span> work like
                    <span className="special-ligal-span">
                      Misleading advertising
                    </span>{" "}
                    and Contract disputes.
                  </span>

                  <div className="needed-iska-papa-div mt-2">
                    <div className="needed-main-div">
                      <span className="week-less-span">Experience In</span>
                      <span className="needed-hours-span">
                        Contract disputes
                      </span>
                    </div>
                    <div className="needed-main-div">
                      <span className="week-less-span">Expected Rate</span>
                      <span className="needed-hours-span">$ 21</span>
                    </div>
                  </div>
                  <div className="mt-3 Posted-by-img-by-name">
                    <span className="by-by-span">Posted by</span>
                    <img
                      className="post-img-pymaint"
                      src={curelem.img}
                      alt=""
                    />
                    <span className="map-span-name">{curelem.Name}</span>
                  </div>

                  <div className="mt-3 Proposal-message-dono">
                    <button className="message-btn-ho">Message</button>
                    <button
                      className="Proposal-Accept-btn"
                      onClick={handleShow}
                    >
                      Send Proposal
                    </button>
                  </div>
                  <Modal show={show} onHide={handleClose}>
                    <Modal.Header closeButton>
                      <div>
                        <span className="send-propsa-span">Send Proposal</span>
                      </div>
                    </Modal.Header>
                    <div className="devon-opne">
                      <div className="ret-propsal">
                        <span>Message</span>
                        <div>
                          <textarea
                            name="Message"
                            id=""
                            className="ertyuytyy"
                            resizer="none"
                            value={values.Message}
                            onChange={handleChange}
                            onBlur={handleBlur}
                          ></textarea>
                          {errors.Message && touched.Message ? (
                            <p className="mastu-to-eroor mt-2">
                              {errors.Message}{" "}
                            </p>
                          ) : null}
                        </div>
                      </div>
                      <div className="ret-propsal">
                        <span>Price</span>

                        <div>
                          <input
                            type="text"
                            className="fghjgfhjgy"
                            name="Price"
                            value={values.Price}
                            onChange={handleChange}
                            onBlur={handleBlur}
                          />
                          {errors.Price && touched.Price ? (
                            <p className="mastu-to-eroor mt-2">
                              {errors.Price}{" "}
                            </p>
                          ) : null}
                        </div>
                      </div>
                      <div>
                        <button className="button-propsal90">
                          Send Propsal
                        </button>
                      </div>
                    </div>
                  </Modal>
                </div>
              );
            })}
          </div>
          {/* <div className="meri-duniya-grid">
            {isLoading ? (
              <div className="loader-container">
                <CircularProgress />
              </div>
            ) : (
              getpost.map((post, index) => (
                <div key={index} className="Contract-disputes-like-div">
                  <span className="Misleading-spn">
                    I need someone to view my{" "}
                    <span className="special-ligal-span">
                      {post.speciallityName}
                    </span>{" "}
                    work like
                    <span className="special-ligal-span">
                      {post.category1Id}
                    </span>{" "}
                    and other disputes.
                  </span>
                  <div className="needed-iska-papa-div mt-2">
                    <div className="needed-main-div">
                      <span className="week-less-span">Experience In</span>
                      <span className="needed-hours-span">
                        {post.speciallityName}
                      </span>
                    </div>
                    <div className="needed-main-div">
                      <span className="week-less-span">Expected Rate</span>
                      <span className="needed-hours-span">${post.rate}</span>
                    </div>
                  </div>
                  <div className="mt-3 Posted-by-img-by-name">
                    <span className="by-by-span">Posted by</span>
                    <img
                      className="post-img-pymaint"
                      src={post.userImage}
                      alt=""
                    />
                    <span className="map-span-name">{post.fullName}</span>
                  </div>
                  <div className="mt-3 Proposal-message-dono">
                    <button className="message-btn-ho">Message</button>
                    <button
                      className="Proposal-Accept-btn"
                      onClick={handleShow}
                    >
                      Send Proposal
                    </button>
                  </div>
                  <Modal show={show} onHide={handleClose}>
                    <Modal.Header closeButton>
                      <div>
                        <span className="send-propsa-span">Send Proposal</span>
                      </div>
                    </Modal.Header>
                    <div className="devon-opne">
                      <div className="ret-propsal">
                        <span>Message</span>
                        <div>
                          <textarea
                            name="Message"
                            id=""
                            className="ertyuytyy"
                            resizer="none"
                            value={values.Message}
                            onChange={handleChange}
                            onBlur={handleBlur}
                          ></textarea>
                          {errors.Message && touched.Message ? (
                            <p className="mastu-to-eroor mt-2">
                              {errors.Message}
                            </p>
                          ) : null}
                        </div>
                      </div>
                      <div className="ret-propsal">
                        <span>Price</span>
                        <div>
                          <input
                            type="text"
                            className="fghjgfhjgy"
                            name="Price"
                            value={values.Price}
                            onChange={handleChange}
                            onBlur={handleBlur}
                          />
                          {errors.Price && touched.Price ? (
                            <p className="mastu-to-eroor mt-2">
                              {errors.Price}
                            </p>
                          ) : null}
                        </div>
                      </div>
                      <div>
                        <button className="button-propsal90">
                          Send Proposal
                        </button>
                      </div>
                    </div>
                  </Modal>
                </div>
              ))
            )}
          </div> */}
        </div>
      </section>
      <Footer2 />
    </>
  );
};

export default JobPosts;
