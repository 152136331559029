import React, { useState } from "react";
import "../Css/Register.css";
import fadvo from "../Images/fadvo.png";
import nlogo from "../Logo/nlogo.png";
import mlogo from "../Logo/mlogo.png";
import emaillogo from "../Logo/emaillogo.png";
import eyeclose from "../Logo/eyeclose.png";
import eyelogo from "../Logo/eyelogo.png";
import Group28 from "../Images/Group28.png";
import Passwordlogo from "../Logo/Passwordlogo.png";
import backarrow from "../Images/backarrow.png";
import sado from "../Images/Loginsedo.png";
import { useNavigate } from "react-router-dom";
import { useFormik } from "formik";
import { RegisterSchemas } from "../schemas";
import PhoneInput from "react-phone-number-input";
import "react-phone-number-input/style.css";
import { parsePhoneNumber } from "react-phone-number-input";
import axios from "../schemas/Api";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import CircularProgress from "@mui/material/CircularProgress";
import Backdrop from "@mui/material/Backdrop";

const initialValues = {
  FullName: "",
  MobileNumber: "",
  email: "",
  password: "",
  ConfirmPassword: "",
  country: "",
};
function Register() {
  const [Loading, setLoading] = useState(false);
  const [isEyeOpen1, setEyeOpen1] = useState(true);
  const [isEyeOpen2, setEyeOpen2] = useState(true);
  const onSubmit = async () => {
    setLoading(true);
    try {
      const response = await axios.post(
        "userRegister",
        {
          fullName: values.FullName,
          mobileNumber: values.MobileNumber,
          email: values.email,
          password: values.password,
          confirmPassword: values.ConfirmPassword,
          countryCode: values.country,
          select_languages: "English",
        },
        {
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
          },
        }
      );
      if (response) {
        setLoading(false);
      }
      toast.success(response.data.message, {
        position: "top-right",
        theme: "colored",
      });
      if (response.data.message === "User Register Successfully") {
        navigate("/Login");
      }
      console.log(response);
    } catch (error) {
      console.log("error", error);
      setLoading(false);
      toast.error(error.response.data.message, {
        position: "top-right",
        theme: "colored",
      });
    }
  };

  const handleEyeToggle1 = () => {
    setEyeOpen1(!isEyeOpen1);
  };
  const handleEyeToggle2 = () => {
    setEyeOpen2(!isEyeOpen2);
  };
  const {
    values,
    handleBlur,
    handleChange,
    touched,
    handleSubmit,
    setFieldValue,
    errors,
  } = useFormik({
    initialValues: initialValues,
    validationSchema: RegisterSchemas,
    onSubmit,
  });

  const navigate = useNavigate();

  const [phone, setPhone] = useState("");

  const handlePhoneChange = (value) => {
    setPhone(value);
    setFieldValue("MobileNumber", value);
    try {
      const parsedPhone =
        value.length >= 6
          ? parsePhoneNumber(value, { defaultCountry: "US" })
          : null;

      if (parsedPhone) {
        setFieldValue("country", `+${parsedPhone.countryCallingCode}`);
        setFieldValue("MobileNumber", parsedPhone.nationalNumber);
      } else {
        setFieldValue("country", "");
        setFieldValue("MobileNumber", "");
      }
    } catch (error) {
      console.log(error);
    }
  };

  // console.log(phone);
  // console.log(values.MobileNumber);
  // console.log(values.country);

  return (
    <section className="register-main-section">
      <div className="both-main-div78">
        <div className="image-section-parent-div">
          <div className="fadvo-or-text-div">
            <div className="dado-img-login-dono">
              <img src={fadvo} alt="" className="login-img-img" />
              <img className="sado-hu-me" src={sado} alt="" />
            </div>
            <div className="register-text-div12">
              <span className="span-span12-span">
                We earn your <span className="color-span12-span12">trust</span>{" "}
                and are deligent your case.
              </span>
              <img src={Group28} alt="" className="group28-img-11" />
            </div>
          </div>
        </div>
        <form onSubmit={handleSubmit} className="input-field13-parent-div">
          <div className="align-self-dib">
            <div onClick={() => navigate("/")} className="both-img-span-div">
              <img className="Backe-arww" src={backarrow} alt="" />
              <span className="back-both-span">Back</span>
            </div>
          </div>
          <div>
            <span className="Create-an-Account">Create an Account</span>
          </div>
          <div>
            <div className="nlogo-container-div">
              <div className="single-contendiv">
                <img src={nlogo} alt="" className="nlogo-image" />
              </div>
              <input
                type="text"
                placeholder="Full Name"
                className="fifth-input-feild"
                name="FullName"
                value={values.FullName}
                onChange={handleChange}
                onBlur={handleBlur}
              />
            </div>
            {errors.FullName && touched.FullName ? (
              <p className="mastu-to-eroor mt-2">{errors.FullName} </p>
            ) : null}
          </div>
          <div>
            <div className="nlogo-container-div">
              <div className="single-contendiv">
                <img src={mlogo} alt="" className="nlogo-image" />
              </div>
              <PhoneInput
                className="fifth-input-feild2"
                name="MobileNumber"
                placeholder="Mobile Number"
                onChange={handlePhoneChange}
                value={phone}
              />
            </div>
            {errors.MobileNumber && touched.MobileNumber ? (
              <p className="mastu-to-eroor mt-2">{errors.MobileNumber} </p>
            ) : null}
          </div>
          <div>
            <div className="nlogo-container-div">
              <div className="single-contendiv">
                <img src={emaillogo} alt="" className="nlogo-image" />
              </div>
              <input
                type="email"
                placeholder="Email"
                className="fifth-input-feild"
                name="email"
                value={values.email}
                onChange={handleChange}
                onBlur={handleBlur}
              />
            </div>
            {errors.email && touched.email ? (
              <p className="mastu-to-eroor mt-2">{errors.email} </p>
            ) : null}
          </div>
          <div>
            <div className="nlogo-container-div">
              <div className="single-contendiv">
                <img src={Passwordlogo} alt="" className="nlogo-image" />
              </div>
              <input
                type={isEyeOpen1 ? "password" : "text"}
                placeholder="Password"
                className="fifth-input-feild"
                name="password"
                value={values.password}
                onChange={handleChange}
                onBlur={handleBlur}
              />
              <div className="single-contendiv-div" onClick={handleEyeToggle1}>
                <img
                  src={isEyeOpen1 ? eyelogo : eyeclose}
                  alt=""
                  className="nlogo-image"
                />
              </div>
            </div>
            {errors.password && touched.password ? (
              <p className="mastu-to-eroor mt-2">{errors.password} </p>
            ) : null}
          </div>
          <div>
            <div className="nlogo-container-div">
              <div className="single-contendiv">
                <img src={Passwordlogo} alt="" className="nlogo-image" />
              </div>
              <input
                type={isEyeOpen2 ? "password" : "text"}
                placeholder="Confirm Password"
                className="fifth-input-feild"
                name="ConfirmPassword"
                value={values.ConfirmPassword}
                onChange={handleChange}
                onBlur={handleBlur}
              />
              <div className="single-contendiv-div" onClick={handleEyeToggle2}>
                <img
                  src={isEyeOpen2 ? eyelogo : eyeclose}
                  alt=""
                  className="nlogo-image"
                />
              </div>
            </div>
            {errors.ConfirmPassword && touched.ConfirmPassword ? (
              <p className="mastu-to-eroor mt-2">{errors.ConfirmPassword} </p>
            ) : null}
          </div>
          <div>
            <button type="submit" className="register-btn-single">
              {Loading ? "loading.." : "Regitser"}
            </button>
          </div>
          <div className="blue-span-div">
            <span className="Already-have-an-account">
              Already have an account?
            </span>
            <span
              onClick={() => navigate("/Login")}
              className="login-attach-span"
            >
              Login
            </span>
          </div>
          {!Loading && <div></div>}
          {Loading && ( // Display the loader when `loading` is true
            <div>
              <Backdrop
                sx={{
                  color: "#fff",
                  zIndex: (theme) => theme.zIndex.drawer + 1,
                }}
                open={true}
                // onClick={handleClose}
              >
                <CircularProgress color="inherit" />
              </Backdrop>
            </div>
          )}
        </form>
      </div>
    </section>
  );
}

export default Register;
