import React from "react";
import "../Css/AdvoUser.css";
import { useNavigate } from "react-router-dom";

const AdvoUser = () => {
  const navigarte = useNavigate();
  return (
    <section className="advo-user-main-section">
      <div className="advo-user-main-div21">
        <button onClick={() => navigarte("/Login")} className="User-btn12">
          User
        </button>
        <button onClick={() => navigarte("/Adlogin")} className="User-btn12">
          Advocate
        </button>
      </div>
    </section>
  );
};

export default AdvoUser;
