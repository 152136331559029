import React, { useState, useEffect } from "react";
import "../Css/Appoinment1.css";
// import Robert from "../Images/Robert.png";
// import Kristin from "../Images/Kristin.png";
// import Mckinny from "../Images/Mckinny 34.png";
// import Easther from "../Images/ad4.png";
// import Cody from "../Images/ad5.png";
// import theresha from "../Images/ad6.png";
// import darrel from "../Images/ad7.png";
// import ronald from "../Images/ad8.png";
// import leslie from "../Images/ad9.png";
// import Nodata from "../Logo/Nodata.png";
import starimg from "../Images/starimg.png";
import msg from "../Images/msg.png";
// import { useNavigate } from "react-router-dom";
// import round2 from "../Images/R2.png";
import axios from "../schemas/APi1";
import Appoinment2 from "./Appoinment2";
import Appoinment3 from "./Appoinment3";
// import { CListGroup } from "@coreui/react";
import Navbar1 from "./Navbar1";
import Footer2 from "./Footer2";
// import ssstar from "../Images/ssstar.png";
// import ksstar from "../Images/ksstar.png";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const Appoinment1 = () => {
  const [appoinment, setAppoinment] = useState([]);
  const data = JSON.parse(localStorage.getItem("Nezziedata"));

  useEffect(() => {
    pendingData();
  }, []);

  const pendingData = async () => {
    try {
      const res = await axios.post(
        "appointment/getappointment",
        {
          status: "Pending",
        },
        {
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            Authorization: data.token,
          },
        }
      );
      setAppoinment(res.data.data);
      console.log(res);
    } catch (error) {
      console.log("Error", error);
    }
  };
  const handleAcceptReject = async (_id, num) => {
    // console.log(num);
    try {
      const response = await axios.post(
        "appointment/accept_reject",
        {
          _id: _id,
          is_accept_reject: num,
        },
        {
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            Authorization: data.token,
          },
        }
      );
      if (num === 1) {
        toast.success("Apoointment Aceepted Successfully");
      } else {
        toast.error("Apoointment Cancelled Successfully");
      }
      console.log(response);
    } catch (error) {
      console.log("Error", error);
    }
  };
  // const navigate = useNavigate();
  // const appoinmentdata = [
  //   {
  //     img4: Robert,
  //     Name4: "Robert Fox",
  //   },
  //   {
  //     img4: Kristin,
  //     Name4: "Kristin Watson",
  //   },
  //   {
  //     img4: Mckinny,
  //     Name4: "Marvin McKinny",
  //   },
  //   {
  //     img4: Easther,
  //     Name4: "Esther Howard",
  //   },
  //   {
  //     img4: Cody,
  //     Name4: "Cody Fisher",
  //   },
  //   {
  //     img4: theresha,
  //     Name4: "Theresa Webb",
  //   },
  //   {
  //     img4: darrel,
  //     Name4: "Darrell Steward",
  //   },
  //   {
  //     img4: ronald,
  //     Name4: "Ronald Richards",
  //   },
  //   {
  //     img4: leslie,
  //     Name4: "Leslie Alexander",
  //   },
  // ];
  const [active, setActive] = useState(0);
  const btns = [
    {
      id: "1",
      name: "New",
    },
    {
      id: "2",
      name: "Upcoming",
    },
    {
      id: "3",
      name: "Completed",
    },
  ];
  return (
    <>
      <Navbar1 />
      {/* /{" "} */}
      {/* <div> */}
      {/* // <div className="hghghg-one"></div> */}
      {/* <div className="img-span-client-diav"> */}
      {/* // <img className="Nodata-img-one" src={Nodata} alt="" /> */}
      {/* // <span className="You-dont-span mt-3">You don’t have </span> */}
      {/* // <span className="You-dont-span">any client request yet</span> */}
      {/* //{" "} */}
      {/* </div> */}
      {/* /* <div className="hghghg-one"></div> */
      /*{" "} */}
      {/* <button onClick={() => navigate("/Appoinment1")}> */}
      {/* My Appointments */}
      {/* </button>{" "} */}
      {/* */
      /*{" "} */}
      {/* </div>{" "} */}
      {/* */}
      <section className="Appoinment1-main22-section">
        <div className="Appoinment1-main22-div98">
          <div className="two-span-div-bon">
            <span className="Appoinment1-span57">Dashboard</span>
            <span className="Appoinment1-span50">My Appointments</span>
          </div>
          <div className="three-button-div00">
            {btns.map((item, i) => {
              return (
                <button
                  className={`new-button92 ${active === i ? "active" : ""}`}
                  key={i}
                  onClick={() => setActive(i)}
                >
                  {item.name}
                </button>
              );
            })}
          </div>
          {active === 0 ? (
            <div className="map-parent-card-div">
              {appoinment.map((item4, index) => {
                console.log(item4);
                /* let Ratingcount = item4.review_total_count;
            let MaxRate = 5;
            let yellowstar = Math.min(Ratingcount, MaxRate);
            let RatingcountImage = Array.from(
              { length: yellowstar },
              (_, i) => <img key={i} src={ssstar} alt="" />
            );
            let GrayStarCount = MaxRate - yellowstar;
            let GrayStarImage = Array.from(
              { length: GrayStarCount },
              (_, i) => <img key={i + yellowstar} src={ksstar} alt="" />
            );
            let starRatingimages = [...RatingcountImage, ...GrayStarImage]; */

                return (
                  <div className="inner-single-card-789" key={index}>
                    <div className="inner-all-parent0div54">
                      <div className="img-star-name-div">
                        <div>
                          <img
                            src={item4.image}
                            alt=""
                            className="item4-img-img50"
                          />
                        </div>
                        <div className="item4-span-star-div77">
                          {" "}
                          <span className="item4-name4-span-89">
                            {item4.fullName}
                          </span>
                          <div className="review-rating-09">
                            {/* {starRatingimages.map((item4, index) => {
                          return (
                            <img
                              src={item4.props.src}
                              alt=""
                              className="star-img40"
                            />
                          );
                        })} */}
                            <img src={starimg} alt="" className="star-img40" />
                            <span className="span-12-400">
                              (<span className="span233-span-sapn">0</span>{" "}
                              Reviews)
                            </span>
                          </div>
                        </div>
                        <div className="message-logo-div">
                          <img
                            src={msg}
                            alt=""
                            className="message-logo-img99"
                          />
                        </div>
                      </div>
                      <div className="display-flex-div-div">
                        <div className="display-flex-div">
                          <span className="display-flex-span">Date</span>
                          <span className="jun-span-span1">
                            {item4.bookedDate}
                          </span>
                        </div>
                        <div className="display-flex-div">
                          <span className="display-flex-span">Time</span>
                          <span className="jun-span-span1">
                            {item4.bookedTime}
                          </span>
                        </div>
                      </div>
                      <div className="reject-accept-button-div">
                        <button
                          className="accept-button-97"
                          onClick={() => handleAcceptReject(item4._id, 2)}
                        >
                          Reject
                        </button>
                        <button
                          className="reject-button-97"
                          onClick={() => handleAcceptReject(item4._id, 1)}
                        >
                          Accept
                        </button>
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
          ) : active === 1 ? (
            <Appoinment2 />
          ) : active === 2 ? (
            <Appoinment3 />
          ) : null}
        </div>
      </section>
      <Footer2 />
    </>
  );
};

export default Appoinment1;
