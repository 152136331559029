import React, { useState } from "react";
import "../Css/Login.css";
import Loginimg from "../Images/Loginimg.png";
import flogo from "../Images/flogo.png";
import alogo from "../Images/alogo.png";
import tlogo from "../Images/tlogo.png";
import ilogo from "../Images/ilogo.png";
import glogo from "../Images/glogo.png";
import eyelogo from "../Logo/eyelogo.png";
import eyeclose from "../Logo/eyeclose.png";
import Group28 from "../Images/Group28.png";
import emaillogo from "../Logo/emaillogo.png";
import backarrow from "../Images/backarrow.png";
import Passwordlogo from "../Logo/Passwordlogo.png";
import sedo from "../Images/Loginsedo.png";
import { useNavigate } from "react-router-dom";
import { useFormik } from "formik";
import { LoginSchemas } from "../schemas";
import axios from "../schemas/Api";
import { toast } from "react-toastify";
import CircularProgress from "@mui/material/CircularProgress";
import Backdrop from "@mui/material/Backdrop";
import { auth, googleProvider } from "../firebase";
import { signInWithPopup } from "firebase/auth";

const initialValues = {
  email: "",
  password: "",
};

const Login = () => {
  const socialLogin = (type) => {
    signInWithPopup(auth, type === "google" ? googleProvider : null)
      .then((res) => {
        console.log(res);
        if (res.user) {
          navigate("/Nearme");
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };
  const [loading, setLoading] = useState(false);
  const onSubmit = async () => {
    setLoading(true);
    try {
      const response = await axios.post(
        "userLogin",
        {
          email: values.email,
          password: values.password,
        },
        {
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
          },
        }
      );
      if (response) {
        setLoading(false);
        localStorage.setItem("Nezziedata", JSON.stringify(response.data.Data));
      }
      toast.success(response.data.message, {
        position: "top-right",
        theme: "colored",
      });
      if (response.data.message === "User login Sucessfully") {
        navigate("/Categories");
      }
      // if (response.data.message === "User login Sucessfully") {
      //   setLoading(false);
      //   localStorage.setItem("Nezziedata", JSON.stringify(response.data.Data));
      //   toast.success(`${response.data.message}`, {
      //     position: "top-right",
      //     theme: "colored",
      //   });
      //   navigate("/c");
      // }
      console.log(response);
    } catch (error) {
      console.log("error", error);
      setLoading(false);
      toast.error(error.response.data.message, {
        position: "top-right",
        theme: "colored",
      });
    }
  };

  const [isEyeOpen, setEyeOpen] = useState(true);
  const handleEyeToggle = () => {
    setEyeOpen(!isEyeOpen);
  };

  const { values, handleBlur, handleChange, touched, handleSubmit, errors } =
    useFormik({
      initialValues: initialValues,
      validationSchema: LoginSchemas,

      onSubmit,
    });
  console.log(errors);

  const navigate = useNavigate();

  return (
    <section className="Login-section-main-div">
      <form onSubmit={handleSubmit} className="Login-section-parent-div">
        <div className="login-img-img-div">
          <div className="dado-img-login-dono">
            <img src={Loginimg} alt="" className="login-img-img" />
            <img className="sado-hu-me" src={sedo} alt="" />
          </div>
          <div className="image-text-75">
            <span className="image-text-text23">
              We earn your <span className="color-span-span12">trust</span> and
              are deligent your case.
            </span>
            <img src={Group28} alt="" className="group28-img" />
          </div>
        </div>
        <div className="login-section-all-input-div">
          <div className="align-self-div">
            {" "}
            <div onClick={() => navigate("/")} className="back-span-div">
              <img src={backarrow} alt="" className="backarrow-img" />
              <span className="back-span-span">Back</span>
            </div>
          </div>
          <div className="submit-div-he">
            <div>
              <span className="login-span-first">Login</span>
            </div>
            <div>
              <div className="adjusted-div">
                <div className="email-logo-container">
                  <img src={emaillogo} alt="" className="email-logo" />
                </div>
                <input
                  type="text"
                  placeholder="Email"
                  className="same-class-input-field"
                  name="email"
                  value={values.email}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
              </div>
              {errors.email && touched.email ? (
                <p className="mastu-to-eroor mt-2">{errors.email} </p>
              ) : null}
            </div>
            <div>
              <div className="adjusted-div">
                <div className="email-logo-container">
                  <img src={Passwordlogo} alt="" className="email-logo" />
                </div>
                <input
                  type={isEyeOpen ? "password" : "text"}
                  placeholder="Password"
                  className="same-class-input-field"
                  name="password"
                  value={values.password}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />

                <div
                  className="email-logo-container-div"
                  onClick={handleEyeToggle}
                >
                  <img
                    src={isEyeOpen ? eyelogo : eyeclose}
                    alt=""
                    className="email-logo"
                  />
                </div>
              </div>
              {errors.password && touched.password ? (
                <p className="mastu-to-eroor mt-2">{errors.password} </p>
              ) : null}
            </div>
          </div>
          <div>
            <span
              onClick={() => navigate("/ForgotPassword")}
              className="Forgot-Password-span"
            >
              Forgot Password?
            </span>
          </div>
          <div>
            <button type="submit" className="login-btn-single">
              {loading ? "loading.." : "Login"}
            </button>
          </div>
          <div className="margin-111-div">
            <div>
              <span className="OR-Login-With-span">OR Login With</span>
            </div>
            <div className="main-div-off-social-media-div22">
              <div className="social-width-media-div">
                <img src={flogo} alt="" className="smae-logo-for" />
              </div>
              <div
                className="social-width-media-div"
                onClick={() => socialLogin("google")}
              >
                <img src={glogo} alt="" className="smae-logo-for" />
              </div>
              {/* <div className="social-width-media-div">
                <img src={alogo} alt="" className="smae-logo-for" />
              </div> */}
              <div className="social-width-media-div">
                <img src={tlogo} alt="" className="smae-logo-for" />
              </div>
              <div className="social-width-media-div">
                <img src={ilogo} alt="" className="smae-logo-for" />
              </div>
            </div>
            <div className="dont-have-register-div">
              <span className="dont-have-register-span">
                Don’t have an account?{" "}
                <span
                  onClick={() => navigate("/Register")}
                  className="only-register-span"
                >
                  Register
                </span>
                .
              </span>
            </div>
          </div>
        </div>
        {!loading && <div></div>}
        {loading && ( // Display the loader when `loading` is true
          <div>
            <Backdrop
              sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
              open={true}
              // onClick={handleClose}
            >
              <CircularProgress color="inherit" />
            </Backdrop>
          </div>
        )}
      </form>
    </section>
  );
};

export default Login;
