import React from "react";
import "../Css/Viewoffer.css";
import Footer2 from "./Footer2";
import Navbar2 from "./Navbar2";
const Viewoffer = () => {
  return (
    <>
      <Navbar2 />
      <div className="Viewoffer-main-section">
        {" "}
        <div className="Viewoffer-span-ops-div">
          {" "}
          <span className="Viewoffer-span-ops">Viewoffer</span>
        </div>
      </div>
      <Footer2 />
    </>
  );
};

export default Viewoffer;
