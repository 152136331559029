import { useState } from "react";
import "../Css/AdRegister.css";
import cem from "../Images/camimg1.png";
import cem2 from "../Images/camimg2.png";
import { useNavigate } from "react-router-dom";
import { useFormik } from "formik";
import { AdRegisterSchemas } from "../schemas";
import PhoneInput from "react-phone-number-input";
import { parsePhoneNumber } from "react-phone-number-input";
import RegisterTwo from "./RegisterTwo";
// import "react-toastify/dist/ReactToastify.css";
// import { toast } from "react-toastify";

const initialValues = {
  FullName: "",
  MobileNumber: "",
  email: "",
  password: "",
  ConfirmPassword: "",
  country: "",
  image: "",
};

const AdRegister = () => {
  const [show, setShow] = useState(false);
  const [phone, setPhone] = useState("");
  const [close2, setclose2] = useState(true);
  const [close, setclose] = useState(true);
  const [selectedImage, setSelectedImage] = useState(null);
  // const [Loading, setLoading] = useState(false);
  const handleImageChange = (event) => {
    const file = event.target.files[0];
    setSelectedImage(file);
    console.log("Selected Image:", file);
  };

  // console.log(selectedImage);
  const handlePhoneChange = (value) => {
    setPhone(value);
    setFieldValue("MobileNumber", value);
    try {
      const parsedPhone =
        value.length >= 6
          ? parsePhoneNumber(value, { defaultCountry: "US" })
          : null;

      if (parsedPhone) {
        setFieldValue("country", `+${parsedPhone.countryCallingCode}`);
        setFieldValue("MobileNumber", parsedPhone.nationalNumber);
      } else {
        setFieldValue("country", "");
        setFieldValue("MobileNumber", "");
      }
    } catch (error) {
      console.log(error);
    }
  };
  const onSubmit = () => {
    console.log("hjbbhewhbv", values);
    setShow(true);
  };

  const eyeopen = (
    <svg
      className="clip-mar"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_26_1790)">
        <path
          d="M11.9987 3C17.3907 3 21.8767 6.88 22.8177 12C21.8777 17.12 17.3907 21 11.9987 21C6.60669 21 2.12069 17.12 1.17969 12C2.11969 6.88 6.60669 3 11.9987 3ZM11.9987 19C14.0382 18.9996 16.0171 18.3068 17.6115 17.0352C19.206 15.7635 20.3216 13.9883 20.7757 12C20.3199 10.0133 19.2036 8.24 17.6093 6.97003C16.015 5.70005 14.037 5.00853 11.9987 5.00853C9.96038 5.00853 7.98238 5.70005 6.38806 6.97003C4.79375 8.24 3.67745 10.0133 3.22169 12C3.67578 13.9883 4.79136 15.7635 6.38583 17.0352C7.9803 18.3068 9.95922 18.9996 11.9987 19ZM11.9987 16.5C10.8052 16.5 9.66062 16.0259 8.81671 15.182C7.97279 14.3381 7.49869 13.1935 7.49869 12C7.49869 10.8065 7.97279 9.66193 8.81671 8.81802C9.66062 7.97411 10.8052 7.5 11.9987 7.5C13.1922 7.5 14.3368 7.97411 15.1807 8.81802C16.0246 9.66193 16.4987 10.8065 16.4987 12C16.4987 13.1935 16.0246 14.3381 15.1807 15.182C14.3368 16.0259 13.1922 16.5 11.9987 16.5ZM11.9987 14.5C12.6617 14.5 13.2976 14.2366 13.7665 13.7678C14.2353 13.2989 14.4987 12.663 14.4987 12C14.4987 11.337 14.2353 10.7011 13.7665 10.2322C13.2976 9.76339 12.6617 9.5 11.9987 9.5C11.3356 9.5 10.6998 9.76339 10.2309 10.2322C9.76208 10.7011 9.49869 11.337 9.49869 12C9.49869 12.663 9.76208 13.2989 10.2309 13.7678C10.6998 14.2366 11.3356 14.5 11.9987 14.5Z"
          fill="#211B24"
        />
      </g>
      <defs>
        <clipPath id="clip0_26_1790">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );

  const eyeclose = (
    <svg
      className="clip-mar"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_26_1797)">
        <path
          d="M9.34178 18.782L7.41078 18.264L8.19778 15.325C7.01975 14.8905 5.9249 14.2574 4.96078 13.453L2.80778 15.607L1.39278 14.192L3.54678 12.039C2.33086 10.5828 1.51387 8.83576 1.17578 6.969L3.14378 6.61C3.90278 10.812 7.57878 14 11.9998 14C16.4198 14 20.0968 10.812 20.8558 6.61L22.8238 6.968C22.4861 8.83502 21.6695 10.5824 20.4538 12.039L22.6068 14.192L21.1918 15.607L19.0388 13.453C18.0747 14.2574 16.9798 14.8905 15.8018 15.325L16.5888 18.265L14.6578 18.782L13.8698 15.842C12.6321 16.0541 11.3674 16.0541 10.1298 15.842L9.34178 18.782Z"
          fill="#211B24"
        />
      </g>
      <defs>
        <clipPath id="clip0_26_1797">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );

  const {
    values,
    handleBlur,
    handleChange,
    touched,
    handleSubmit,
    setFieldValue,
    errors,
  } = useFormik({
    initialValues: initialValues,
    validationSchema: AdRegisterSchemas,
    onSubmit,
  });
  // console.log(errors);
  const num = {
    fullname: values.FullName,
    mobileNumber: values.MobileNumber,
    email: values.email,
    password: values.password,
    confirmPassword: values.ConfirmPassword,
    countryCode: values.country,
    image: selectedImage,
    select_languages: "English",
  };
  const navigate = useNavigate();
  return (
    <>
      <section className="AdRegister-main-sec">
        <form onSubmit={handleSubmit} className="AdRegister-main-div">
          {show !== true && (
            <>
              <div className="w-100">
                <span className="personaly-add">Become a Service Provider</span>
              </div>
              <div className="haghit-ka-liya"></div>
              <div className="ragiesrer-one">
                <div className="div-he-div-he">
                  <div className="blue-undur-line"></div>
                  <span className="one-dada-span">1</span>
                  <div className="blue-undur-line"></div>
                  <div className="transprant-undur-line"></div>
                  <span
                    onClick={() => navigate("/RegisterTwo")}
                    className="two-dada-span"
                  >
                    2
                  </span>
                  <div className="transprant-undur-line"></div>
                </div>
              </div>
            </>
          )}
          {show ? (
            <RegisterTwo
              num={num}
              selectedImage={selectedImage}
              setShow={setShow}
            />
          ) : (
            <>
              <div className="mt-4 profile-info-register">
                <span className="mb-4 personaly-add">Personal Info</span>
                <div
                  className="cemara-dono-add"
                  onClick={() => document.getElementById("imageInput").click()}
                >
                  {selectedImage ? (
                    <img
                      className="cam-two-ha"
                      src={URL.createObjectURL(selectedImage)}
                      alt=""
                    />
                  ) : (
                    <>
                      <img className="cam-two-ha" src={cem2} alt="" />
                      <img className="cam-one-add" src={cem} alt="" />

                      {/* File input element */}
                      <input
                        type="file"
                        onChange={handleImageChange}
                        accept="image/*"
                        style={{ display: "none" }}
                        id="imageInput"
                      />
                    </>
                  )}
                </div>
              </div>
              <div className="input-from-r mt-3">
                <span className="all-input-span-font">Full Name</span>
                <input
                  className="mt-2 Full-Name-input-hova"
                  placeholder="Full Name"
                  type="text"
                  name="FullName"
                  value={values.FullName}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
                {errors.FullName && touched.FullName ? (
                  <p className="mastu-to-eroor mt-2">{errors.FullName} </p>
                ) : null}
                <span className="mt-3 all-input-span-font">Mobile Number</span>
                <PhoneInput
                  defaultCountry="US"
                  className="mt-2 Full-Name-input-hova-added"
                  name="MobileNumber"
                  placeholder="Mobile Number"
                  onChange={handlePhoneChange}
                  onBlur={handleBlur}
                  value={phone}
                />
                {errors.MobileNumber && touched.MobileNumber ? (
                  <p className="mastu-to-eroor mt-2">{errors.MobileNumber} </p>
                ) : null}
                <span className="mt-3 all-input-span-font">Email</span>
                <input
                  className="mt-2 Full-Name-input-hova"
                  placeholder="Email"
                  type="email"
                  name="email"
                  value={values.email}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
                {errors.email && touched.email ? (
                  <p className="mastu-to-eroor mt-2">{errors.email} </p>
                ) : null}
                <span className="mt-3 all-input-span-font">Password</span>
                <div className="adregister-div mt-2">
                  <input
                    className="Password-or-text-eksatha"
                    type={close2 ? "Password" : "text"}
                    placeholder="New Password"
                    name="password"
                    value={values.password}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />

                  <span onClick={() => setclose2(!close2)}>
                    {close2 ? eyeopen : eyeclose}
                  </span>
                </div>
                {errors.password && touched.password ? (
                  <p className="mastu-to-eroor mt-2">{errors.password} </p>
                ) : null}
                <span className="mt-3 all-input-span-font">
                  Confirm Password
                </span>
                <div className="adregister-div mt-2">
                  <input
                    className="Password-or-text-eksatha"
                    type={close ? "Password" : "text"}
                    placeholder="Confirm Password"
                    name="ConfirmPassword"
                    value={values.ConfirmPassword}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                  <span onClick={() => setclose(!close)}>
                    {close ? eyeclose : eyeopen}
                  </span>
                </div>
                {errors.ConfirmPassword && touched.ConfirmPassword ? (
                  <p className="mastu-to-eroor mt-2">
                    {errors.ConfirmPassword}{" "}
                  </p>
                ) : null}
                <button
                  type="submit"
                  className="Continue-btn-adr mt-4"
                  // onClick={() => navigate("/RegisterTwo")}
                >
                  Continue
                </button>
              </div>
            </>
          )}
          <div className="haghit-ka-liya"></div>
        </form>
      </section>
    </>
  );
};

export default AdRegister;
