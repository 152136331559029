import React from "react";
import "../Css/YourTrust.css";
import icon1 from "../Images/icon1.png";
import icon2 from "../Images/icon2.png";
import icon3 from "../Images/icon3.png";
import icon4 from "../Images/icon4.png";
import icon5 from "../Images/icon5.png";
import icon6 from "../Images/icon6.png";
import icon7 from "../Images/icon7.png";
import icon8 from "../Images/icon8.png";
import icon9 from "../Images/icon9.png";
import icon10 from "../Images/icon10.png";
import icon11 from "../Images/icon11.png";
import icon12 from "../Images/icon12.png";

const YourTrust = () => {
  const data = [
    {
      img: icon1,
      Name: "Legal",
    },
    {
      img: icon2,
      Name: "Auto",
    },
    {
      img: icon3,
      Name: "Customer",
    },
    {
      img: icon4,
      Name: "Housing",
    },
    {
      img: icon5,
      Name: "Employment",
    },
    {
      img: icon6,
      Name: "Medical",
    },
    {
      img: icon7,
      Name: "Travel and Tourism",
    },
    {
      img: icon8,
      Name: "Environmental",
    },
    {
      img: icon9,
      Name: "Technology",
    },
    {
      img: icon10,
      Name: "Education",
    },
    {
      img: icon11,
      Name: "Financial",
    },
    {
      img: icon12,
      Name: "PhysicalRepresentation",
    },
  ];
  return (
    <>
      <section className="YourTrust-section">
        <div className="YourTrust-div">
          <div className="span-do-div-hoka-nhi">
            <span className="deligent-span-tu-do-bar-he">
              {/* We earn your trust and are deligent your case. */}
              Navigating Challenging Business Scenarios?
            </span>
            <span className="dummy-span">
              {/* Lorem Ipsum is simply dummy text of the printing and typesetting
              industry. Lorem Ipsum has been the industry's standard dummy text
              ever since the 1500s. */}
              Ever found yourself struggling to communicate effectively in a
              complex business situation? Nezzie™ recognizes the value of clear
              and informed dialogues, ensuring all parties are heard and
              understood. We understand that challenges arise, and it's
              essential to address them collectively.
            </span>
          </div>
          <div className="icono-ka-div">
            {data.map((itm, index) => {
              return (
                <div key={index} className="icon-ka-phla-div">
                  <img className="icon-one-div" src={itm.img} alt="" />
                  <span className="legal-span890">{itm.Name}</span>
                </div>
              );
            })}
          </div>
        </div>
      </section>
    </>
  );
};

export default YourTrust;
