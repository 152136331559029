import React from "react";
import "../Css/Footer.css";
import Moimage from "../Images/moboo.png";
import Applestore from "../Images/Applestore.png";
import Square from "../Images/Square.png";
import LogoBlue from "../Images/LogoBlue.png";
import facebook from "../Images/facebook.png";
import instagram from "../Images/instagram.png";
import twitter from "../Images/twitter.png";
import linkedin from "../Images/linkedin.png";

const Footer = () => {
  return (
    <>
      <section className="footer-main-section">
        <div className="footer-child-div">
          <div>
            <span className="download-111-span">Download App</span>
          </div>
          <div className="Seize-Control-div">
            <span className="Seize-Control">
              Seize Control of Productive Dialogue
            </span>
          </div>
          <div className="Transform-Conversations-div">
            <span className="Transform-Conversations">
              Embrace Nezzie™ – Transform Conversations Today
            </span>
          </div>
          <div className="individual-div">
            <span className="text-222-sapn">
              {/* Lorem Ipsum is simply dummy text of the printing and typesetting
              industry. Lorem Ipsum has been the industry's standard dummy text
              ever since the 1500s. */}
              Don't delay embracing effective communication and collaboration.
              Join Nezzie™ to foster productive dialogue and reshape business
              interactions. Download the app to take the first step toward
              transforming your conversations.
            </span>
          </div>
          <div className="two-image-parent-div">
            <div className="same-image11-div">
              <img src={Applestore} alt="" className="same-image11" />
            </div>
            <div className="same-image11-div">
              {" "}
              <img src={Square} alt="" className="same-image11" />
            </div>
          </div>
          <div className="moimage-div">
            <img src={Moimage} alt="" className="moimage-11" />
          </div>
        </div>
        <div className="seprate-bg-upper-parent-div">
          <div className="seprate-bg-parent-div">
            <div className="quick-links-child11-div">
              <div className="LogoBlue-div">
                <img src={LogoBlue} alt="" className="LogoBlue-image" />
              </div>
              <div className="width-div-div">
                <span className="Contrary-span">
                  Contrary to popular belief, Lorem Ipsum is not simply random
                  text. It has roots in a piece of classical Latin literature.
                </span>
              </div>
              <div>
                <button className="read-more12-span">
                  Become a Service Provider
                </button>
              </div>
            </div>
            <div className="quick-links-main-div">
              <div className="quick-links-child-div">
                <div>
                  <span className="Quick-Links-span-span">Quick Links</span>
                </div>
                <div>
                  <span className="Quick-Links-span">About Us</span>
                </div>
                <div>
                  <span className="Quick-Links-span">
                    Become a Service Provider
                  </span>
                </div>
                <div>
                  <span className="Quick-Links-span">Lorem Ipsum</span>
                </div>
                <div>
                  <span className="Quick-Links-span">New Features</span>
                </div>
              </div>
              <div className="quick-links-child-div">
                <div>
                  <span className="Quick-Links-span-span">Support</span>
                </div>
                <div>
                  <span className="Quick-Links-span">Contact Us</span>
                </div>
                <div>
                  <span className="Quick-Links-span">Support Lorem Ipsum</span>
                </div>
                <div>
                  <span className="Quick-Links-span">Lorem Ipsum</span>
                </div>
                <div>
                  <span className="Quick-Links-span">Careers</span>
                </div>
              </div>
              <div className="social-parent-div">
                <div>
                  <span className="Quick-Links-span-span">Social</span>
                </div>
                <div className="social-child-div">
                  <div className="face-book-div">
                    <img src={facebook} alt="" className="social-image" />
                  </div>
                  <div className="face-book-div">
                    <img src={linkedin} alt="" className="social-image" />
                  </div>
                  <div className="face-book-div">
                    <img src={twitter} alt="" className="social-image" />
                  </div>
                  <div className="face-book-div">
                    <img src={instagram} alt="" className="social-image" />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="border-bottom-div"></div>
          <div className="copyright-single-div">
            <span className="copyright-span">
              © Copyright 2023. All Rights Reserved.
            </span>
          </div>
        </div>
      </section>
    </>
  );
};

export default Footer;
