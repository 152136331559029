import React, { useState, useEffect } from "react";
import "../Css/YourTrust.css";
import icon1 from "../Images/icon1.png";
import icon2 from "../Images/icon2.png";
import icon3 from "../Images/icon3.png";
import icon4 from "../Images/icon4.png";
import icon5 from "../Images/icon5.png";
import icon6 from "../Images/icon6.png";
import icon7 from "../Images/icon7.png";
import icon8 from "../Images/icon8.png";
import icon9 from "../Images/icon9.png";
import icon10 from "../Images/icon10.png";
import icon11 from "../Images/icon11.png";
import icon12 from "../Images/icon12.png";
import Footer2 from "./Footer2";
import Navbar2 from "./Navbar2";
import "../Css/Categories.css";
import axios from "../schemas/APi1";
import close from "../Images/close.png";
import Post2 from "../Component/Post2";
import CircularProgress from "@mui/material/CircularProgress";

const YourTrust = () => {
  const [specialist, setSpecialist] = useState([]);
  const [show, setshow] = useState("");
  const [loading, setLoading] = useState(true);

  const Imagelink = () => {
    setshow(true);
  };
  // specialitylist api integraeted at here
  useEffect(() => {
    specialitydata();
  }, []);
  const specialitydata = async () => {
    try {
      const resp = await axios.post(
        "meta_data/speciallityList",
        {},
        {
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
          },
        }
      );
      setSpecialist(resp.data.data);
      setLoading(false);
      console.log(resp);
    } catch (error) {
      console.log("Error", error);
      setLoading(false);
    }
  };

  //   const data = [
  //     {
  //       img: icon1,
  //       Name: "Legal",
  //     },
  //     {
  //       img: icon2,
  //       Name: "Auto",
  //     },
  //     {
  //       img: icon3,
  //       Name: "Customer",
  //     },
  //     {
  //       img: icon4,
  //       Name: "Housing",
  //     },
  //     {
  //       img: icon5,
  //       Name: "Employment",
  //     },
  //     {
  //       img: icon6,
  //       Name: "Medical",
  //     },
  //     {
  //       img: icon7,
  //       Name: "Travel and Tourism",
  //     },
  //     {
  //       img: icon8,
  //       Name: "Environmental",
  //     },
  //     {
  //       img: icon9,
  //       Name: "Technology",
  //     },
  //     {
  //       img: icon10,
  //       Name: "Education",
  //     },
  //     {
  //       img: icon11,
  //       Name: "Financial",
  //     },
  //     {
  //       img: icon12,
  //       Name: "PhysicalRepresentation",
  //     },
  //   ];
  return (
    <>
      {loading ? (
        <div className="loader-oops">
          <CircularProgress />
        </div>
      ) : (
        show && (
          <div className="model">
            <div className="closeicon">
              <img src={close} alt="" onClick={() => setshow(false)} />
              <Post2 specialist={specialist} />
            </div>
          </div>
        )
      )}
      <Navbar2 />
      <section className="YourTrust-section">
        <div className="YourTrust-div-909">
          <div className="Categories-span987-div">
            <span className="Categories-span987">Categories</span>
          </div>

          <div className="icono-ka-div-alagse">
            {specialist.map((itm, index) => {
              return (
                <div
                  key={index}
                  className="icon-ka-phla-div"
                  onClick={() => {
                    Imagelink();
                    localStorage.setItem("specialitydata", itm._id);
                    localStorage.setItem("specialityname", itm.speciallityName);
                  }}
                >
                  <img
                    className="icon-one-div"
                    src={itm.speciallityImage}
                    alt=""
                  />
                  <span className="legal-span890">{itm.speciallityName}</span>
                </div>
              );
            })}
          </div>
        </div>
      </section>
      <Footer2 />
    </>
  );
};

export default YourTrust;
