import React, { useState, useEffect } from "react";
import "../Css/Availablity.css";
import axios from "../schemas/APi1";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const Availablity = () => {
  const [isMonthUnavailable, setIsMonthUnavailable] = useState(false);

  const [selectedDays, setSelectedDays] = useState([]);
  const [selectedTimes, setSelectedTimes] = useState([]);
  const [showVirtualTimes, setShowVirtualTimes] = useState(false);
  const [showInPersonTimes, setShowInPersonTimes] = useState(false);
  // usestate for virtual or inperson
  const [virtualTimes, setVirtualTimes] = useState([]);
  const [inPersonTimes, setInPersonTimes] = useState([]);

  const data = JSON.parse(localStorage.getItem("Nezziedata"));

  const addavailiblity = async () => {
    if (selectedDays.length === 0 || selectedTimes.length === 0) {
      toast.error("Please select both day and time", {
        position: "top-right",
        theme: "colored",
      });
      return;
    }
    try {
      const res = await axios.post(
        "avaibillity/addavaibillity",
        {
          availableOn: selectedDays, // Use selectedDays state
          session: [
            ...virtualTimes.map((time) => ({
              mettingType: "virtual",
              slotTime: time,
            })),
            ...inPersonTimes.map((time) => ({
              mettingType: "inPerson",
              slotTime: time,
            })),
          ],
          ratePerHours: "10",
        },
        {
          headers: {
            "Content-type": "application/json",
            Accept: "application/json",
            Authorization: data.token,
          },
        }
      );
      if (res.data.data === "Avaibility Set Successfully") {
        toast.success(res.data.message, {
          position: "top-right",
          theme: "colored",
        });
      } else {
        toast.success(res.data.message, {
          position: "top-right",
          theme: "colored",
        });
      }
      setSelectedDays([]);
      setVirtualTimes([]);
      setInPersonTimes([]);
      setSelectedTimes([]);
      console.log(res);
    } catch (error) {
      console.log("Error", error);
    }
  };

  const days = ["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"];

  const times = [
    "9:00 AM",
    "9:30 AM",
    "10:00 AM",
    "10:30 AM",
    "11:00 AM",
    "11:30 AM",
    "12:00 PM",
    "12:30 PM",
    "1:00 PM",
    "1:30 PM",
    "2:00 PM",
    "2:30 PM",
    "3:00 PM",
    "3:30 PM",
    "4:00 PM",
  ];
  const handleToggleMonthUnavailable = () => {
    setIsMonthUnavailable((prevValue) => !prevValue);
  };
  useEffect(() => {
    // Set virtual time slots active when component
    setShowVirtualTimes(true);
  }, []);
  useEffect(() => {
    // Set in-person time slots active when component
    setShowInPersonTimes(true);
  }, []);

  const handleDayClick = (day) => {
    setSelectedDays((prevDays) =>
      prevDays.includes(day)
        ? prevDays.filter((d) => d !== day)
        : [...prevDays, day]
    );
    // console.log("slected day", day);
  };

  const handleTimeClick = (time) => {
    // Check if virtual times are shown, then update virtualTimes state
    if (showVirtualTimes) {
      setVirtualTimes((prevTimes) =>
        prevTimes.includes(time)
          ? prevTimes.filter((t) => t !== time)
          : [...prevTimes, time]
      );
    }

    // Check if in-person times are shown, then update inPersonTimes state
    if (showInPersonTimes) {
      setInPersonTimes((prevTimes) =>
        prevTimes.includes(time)
          ? prevTimes.filter((t) => t !== time)
          : [...prevTimes, time]
      );
    }

    setSelectedTimes((prevTimes) =>
      prevTimes.includes(time)
        ? prevTimes.filter((t) => t !== time)
        : [...prevTimes, time]
    );
  };

  const handleVirtualClick = () => {
    setShowVirtualTimes(true); // show virtual times when clicking on "Virtual"
    setShowInPersonTimes(false); // Close the in-person times when virtual times are shown
  };

  const handleInPersonClick = () => {
    setShowInPersonTimes(true); // Always show in-person times when clicking on "In Person"
    setShowVirtualTimes(false); // Close the virtual times when in-person times are shown
  };

  return (
    <section className="Availablity-main-section">
      <div className="height-Availablity-main-div">
        <div className="available-sapan-90-div">
          <span className="available-sapan-90">Available Days</span>
        </div>
        <div className="days-button-div">
          {days.map((day) => (
            <button
              key={day}
              className={`days-button-on ${
                selectedDays.includes(day) ? "active" : ""
              }`}
              onClick={() => handleDayClick(day)}
            >
              {day}
            </button>
          ))}
        </div>
        <div className="available-sapan-90-div-90">
          <span className="available-sapan-90">Some timing for weekday</span>
          <label className="switch">
            <input
              type="checkbox"
              checked={isMonthUnavailable}
              onChange={handleToggleMonthUnavailable}
            />
            <span className="slider round"></span>
          </label>
        </div>
        <div>
          <button
            className={`button-virtual-button ${
              showVirtualTimes ? "active" : ""
            }`}
            onClick={handleVirtualClick}
          >
            Virtual
          </button>
          <button
            className={`button-virtual-button-op ${
              showInPersonTimes ? "active" : ""
            }`}
            onClick={handleInPersonClick}
          >
            In Person
          </button>
        </div>
        {showVirtualTimes || showInPersonTimes ? (
          <div className="time-slots">
            {times.map((time) => (
              <button
                key={time}
                className={`days-button ${
                  selectedTimes.includes(time) ? "active" : ""
                }`}
                onClick={() => handleTimeClick(time)}
              >
                {time}
              </button>
            ))}
          </div>
        ) : null}
        <div>
          <button className="save-button-090" onClick={addavailiblity}>
            Save
          </button>{" "}
        </div>
      </div>
    </section>
  );
};

export default Availablity;
