import React from "react";
import "../Css/Footer2.css";
import facebook from "../Images/facebook.png";
import twitter from "../Images/twitter.png";
import instagram from "../Images/instagram.png";
import linkedin from "../Images/linkedin.png";
import LogoBlue from "../Images/LogoBlue.png";

const Footer2 = () => {
  return (
    <section className="footer2-main-section">
      <div className="footer2-child-div19">
        <div className="boreder-div-div"></div>
      </div>
      <div className="seprate-bg-upper-parent-div">
        <div className="seprate-bg-parent-div">
          <div className="quick-links-child11-div">
            <div className="LogoBlue-div">
              <img src={LogoBlue} alt="" className="LogoBlue-image" />
            </div>
            <div className="width-div-div">
              <span className="Contrary-span">
                Contrary to popular belief, Lorem Ipsum is not simply random
                text. It has roots in a piece of classical Latin literature.
              </span>
            </div>
            <div>
              <button className="read-more12-span">
                Become a Service Provider
              </button>
            </div>
          </div>
          <div className="quick-links-main-div">
            <div className="quick-links-child-div">
              <div>
                <span className="Quick-Links-span-span">Quick Links</span>
              </div>
              <div>
                <span className="Quick-Links-span">About Us</span>
              </div>
              <div>
                <span className="Quick-Links-span">
                  Become a Service Provider
                </span>
              </div>
              <div>
                <span className="Quick-Links-span">Lorem Ipsum</span>
              </div>
              <div>
                <span className="Quick-Links-span">New Features</span>
              </div>
            </div>
            <div className="quick-links-child-div">
              <div>
                <span className="Quick-Links-span-span">Support</span>
              </div>
              <div>
                <span className="Quick-Links-span">Contact Us</span>
              </div>
              <div>
                <span className="Quick-Links-span">Support Lorem Ipsum</span>
              </div>
              <div>
                <span className="Quick-Links-span">Lorem Ipsum</span>
              </div>
              <div>
                <span className="Quick-Links-span">Careers</span>
              </div>
            </div>
            <div className="social-parent-div">
              <div>
                <span className="Quick-Links-span-span">Social</span>
              </div>
              <div className="social-child-div">
                <div className="face-book-div">
                  <img src={facebook} alt="" className="social-image" />
                </div>
                <div className="face-book-div">
                  <img src={linkedin} alt="" className="social-image" />
                </div>
                <div className="face-book-div">
                  <img src={twitter} alt="" className="social-image" />
                </div>
                <div className="face-book-div">
                  <img src={instagram} alt="" className="social-image" />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="border-bottom-div"></div>
        <div className="copyright-single-div">
          <span className="copyright-span">
            © Copyright 2023. All Rights Reserved.
          </span>
        </div>
      </div>
    </section>
  );
};

export default Footer2;
