import React, { useRef, useEffect } from "react";
import { useState } from "react";
import Logo from "../Images/Nezzie Logo.png";
import ser from "../Images/search.png";
// import logimg from "../Images/logimg.png";
import arrowrght from "../Images/arrowrght.png";
import Hamburger from "hamburger-react";
import { NavLink, useNavigate } from "react-router-dom";
import "../Css/Navbar.css";
import "../Css/Navbar2.css";
// import Mm from "./Mm";
import Modal from "./Modal";
import DropDown from "./DropDown";
import notification from "../Images/notification.png";
import { useUser } from "../UserContext.js";

// import arrowrght from "../Images/arrowrght.png";

const Navbar = () => {
  // const [save, setSave] = useState();
  const { userData } = useUser();

  // useEffect(() => {
  //   setSave(JSON.parse(localStorage.getItem("Nezziedata")));
  // }, []);
  // useEffect(() => {
  //   const data = JSON.parse(localStorage.getItem("Nezziedata"));
  //   if (data) {
  //     setSave(data);
  //   }
  // }, []);

  // console.log(save);
  const [open1, setOpen1] = useState(false);

  const [open, setOpen] = useState(false);
  const navigate = useNavigate();

  const [isScrolled, setIsScrolled] = useState(false);

  const [isOpen, setIsOpen] = useState(false);

  const handleScroll = () => {
    if (window.scrollY > 20) {
      setIsScrolled(true);
    } else {
      setIsScrolled(false);
    }
  };
  let menuref = useRef();
  useEffect(() => {
    let handler = (event) => {
      if (menuref.current) {
        if (!menuref.current.contains(event.target)) {
          setIsOpen(false);
        }
      }
    };
    document.addEventListener("scroll", handler);

    return () => {
      document.removeEventListener("mousedown", handler);
      document.removeEventListener("scroll", handler);
    };
  });
  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);
  const handlehide = () => {
    setIsOpen(!isOpen);
  };

  return (
    <>
      <section className="Navbar-section">
        <div className={!isScrolled ? "navbar-div" : "navbar-div-not"}>
          <div className="Navbar-itm-nav2">
            <NavLink to="/" className="logo-img-link-nav2">
              <img className="logo-img" src={Logo} alt="" />
            </NavLink>

            <div className={isOpen ? "navbar-itam1-paps" : "navbar-itam"}>
              <div className="gap-div99" onClick={() => setOpen1(!open1)}>
                <span className="Categories-span12">Categories</span>
                <img src={arrowrght} alt="" className="class-arrow-bottom" />
              </div>
              {open1 ? <DropDown /> : null}
              <div className="Nav-bar-itm">
                <li>
                  {" "}
                  <NavLink to="/" className="home-li">
                    Home{" "}
                  </NavLink>
                </li>
                <li>
                  {" "}
                  <NavLink to="/NearMe" className="home-li">
                    Near Me
                  </NavLink>
                </li>
                <li>
                  <NavLink to="/AvailableAdvocate" className="home-li">
                    Available Advocates
                  </NavLink>
                </li>
              </div>
              <div className="img-btn-select-div-nav3">
                {/* <button
                  onClick={() => navigate("/PostJob")}
                  className="post-job-btn00"
                >
                  Post a Job
                </button> */}
                {/* <input
                  type="search"
                  placeholder=""
                  className="search-input-field"
                /> */}
                <div
                  className="height-width-img23-ops0"
                  onClick={() => navigate("/Notification")}
                >
                  <div className="height-width-img23">2</div>
                  <img
                    src={notification}
                    alt=""
                    className="notification-img11"
                  />
                </div>
                <img
                  className="serach-img"
                  src={ser}
                  alt=""
                  onClick={() => navigate("/Search")}
                />
                <span className="Categories-span12">EN</span>
                {/* <select className="drop-one-en" name="cars" id="cars">
                  <option value="volvo">EN</option>
                  <option value="saab">Saab</option>
                  <option value="mercedes">Mercedes</option>
                  <option value="audi">Audi</option>
                </select> */}
                {/* <button
                  className="Login-btn"
                >
                  Login
                </button> */}
                <div className="log-img-div-div" onClick={() => setOpen(!open)}>
                  {" "}
                  <img src={userData?.image} alt="" className="log-img-log" />
                  <span className="Guy-Hawkins-span">{userData?.fullName}</span>
                  <img src={arrowrght} alt="" className="class-arrow-bottom" />
                </div>
                {open ? <Modal userRole="user" /> : null}
              </div>
            </div>
            <div className="ham-burgur-nav">
              <Hamburger
                onToggle={handlehide}
                toggled={isOpen}
                color="#ffffff"
              />
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Navbar;
