import React, { useEffect, useState } from "react";
import "../Css/Settings.css";
import axios from "../schemas/Api";
import CircularProgress from "@mui/material/CircularProgress";
import { MdKeyboardArrowRight } from "react-icons/md";

const Settings = () => {
  const [isEmailChecked, setIsEmailChecked] = useState(true);
  const [isSmsChecked, setIsSmsChecked] = useState(true);

  const [settingsFetched, setSettingsFetched] = useState(false);

  const handleEmailToggle = () => {
    setIsEmailChecked(!isEmailChecked);
    // localStorage.setItem(
    //   "userSettings",
    //   JSON.stringify({ isEmailChecked, isSmsChecked })
    // );
    const updatedValue = !isEmailChecked;
    document.cookie = `isEmailChecked=${updatedValue}; expires=Sun, 31 Dec 2030 23:59:59 GMT`;

    console.log(isEmailChecked);
  };

  const handleSmsToggle = () => {
    setIsSmsChecked(!isSmsChecked);
    // localStorage.setItem(
    //   "userSettings",
    //   JSON.stringify({ isEmailChecked, isSmsChecked })
    // );
    console.log(isSmsChecked);
    const updatedValue = !isSmsChecked;
    document.cookie = `isSmsChecked=${updatedValue}; expires=Sun, 31 Dec 2030 23:59:59 GMT`;
  };
  // user settingscreen api integaretd at here
  const data = JSON.parse(localStorage.getItem("Nezziedata"));
  useEffect(() => {
    fatchUserSettings();
  }, []);

  const fatchUserSettings = async () => {
    try {
      setSettingsFetched(true);
      const response = await axios.post(
        "setting/userSettingScreen",
        {},
        {
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            Authorization: data.token,
          },
        }
      );
      const { sendNotificationButton } = response.data.data;
      // console.log("sendNotificationButton:", sendNotificationButton);
      setIsEmailChecked(sendNotificationButton);
      setIsSmsChecked(sendNotificationButton);
      // setSettingsFetched(true);
      // localStorage.setItem(
      //   "userSettings",
      //   JSON.stringify({ isEmailChecked, isSmsChecked })
      // );
      setSettingsFetched(false);
      console.log(response.data);
    } catch (error) {
      console.log("Error", error);
      setSettingsFetched(false);
    }
  };
  // console.log("isemail:", isEmailChecked);
  // console.log("issms:", isSmsChecked);
  // user updatecreen api integaretd at here
  useEffect(() => {
    updateScreenSettings();
  }, []);
  const updateScreenSettings = async () => {
    try {
      const resp = await axios.post(
        "setting/updateSettingScreen",
        { select_languages: "English", sendNotificationButton: true },
        {
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            Authorization: data.token,
          },
        }
      );
      const updatedSendNotificationButton = resp.data.sendNotificationButton;

      if (updatedSendNotificationButton !== undefined) {
        setIsEmailChecked(updatedSendNotificationButton);
        setIsSmsChecked(updatedSendNotificationButton);
      } else {
        // console.log("API response contains an undefined value.");
      }

      console.log(resp.data);
    } catch (error) {
      console.log("Error", error);
    }
  };

  useEffect(() => {
    // Load user settings from cookies when component mounts
    const cookies = document.cookie.split("; ");
    const cookieMap = {};
    cookies.forEach((cookie) => {
      const [name, value] = cookie.split("=");
      cookieMap[name] = value;
    });
    if (cookieMap.isEmailChecked !== undefined) {
      setIsEmailChecked(cookieMap.isEmailChecked === "true");
    }
    if (cookieMap.isSmsChecked !== undefined) {
      setIsSmsChecked(cookieMap.isSmsChecked === "true");
    }
    setSettingsFetched(true);
  }, []);

  return (
    <section className="setting12-main-section">
      <div className="setting-main-div">
        <div className="setting-sigle-div">
          <span className="setting-span25">Settings</span>
        </div>
        <div className="both-notification-main-div">
          {settingsFetched ? (
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                width: "850px",
                height: "300px",
              }}
            >
              <CircularProgress />
            </div>
          ) : (
            <>
              <div className="both-notification-main-div123">
                <div className="setting-email-niti-div45">
                  <div className="Email-Notification-span-div">
                    <span className="Email-Notification-span">
                      Email Notification
                    </span>
                    <div>
                      <label className="switch">
                        <input
                          type="checkbox"
                          checked={isEmailChecked}
                          onChange={handleEmailToggle}
                        />
                        <span className="slider round"></span>
                      </label>
                    </div>
                  </div>
                  <div className="border-div-78"></div>
                </div>

                <div className="setting-email-niti-div45">
                  <div className="Email-Notification-span-div">
                    <span className="Email-Notification-span">
                      SMS Notification
                    </span>
                    <div>
                      <label className="switch-12">
                        <input
                          type="checkbox"
                          checked={isSmsChecked}
                          onChange={handleSmsToggle}
                          className="input-123"
                        />
                        <span className="slider round"></span>
                      </label>
                    </div>
                  </div>
                  <div className="border-div-78"></div>
                </div>
              </div>

              {/* shfhewhjgf */}
              <div className="setting-email-niti-div4512">
                <div className="Email-Notification-span-div12">
                  <span className="Email-Notification-span">Language</span>
                  <div className="revive90-div">
                    <span className="english-span98">English</span>
                    <MdKeyboardArrowRight className="MdKeyboardArrowRight" />
                  </div>
                </div>
                <div className="border-div-789"></div>
              </div>
            </>
          )}
        </div>
      </div>
    </section>
  );
};

export default Settings;
