import React, { useState } from "react";
import "../Css/NewClient.css";
import Nodata from "../Logo/Nodata.png";
import round2 from "../Images/R2.png";
import { useNavigate } from "react-router-dom";
import Appoinment1 from "../Component/Appoinment1";
import Appoinment2 from "./Appoinment2";
import Appoinment3 from "./Appoinment3";

const NewClient = () => {
  const [DataShow, setDataShow] = useState(0);
  const navigate = useNavigate();
  return (
    <>
      <section className="NewClient-main-sec">
        <div className="NewClient-main-div">
          <div className="two-span-div-bon">
            <span className="Appoinment1-span57">Dashboard</span>
            <span className="Appoinment1-span50">My Appointments</span>
          </div>
          <div className="three-button-div00 mt-4">
            <button onClick={() => setDataShow(0)} className="new-button92">
              New <img src={round2} alt="" className="round2-img2" />
            </button>
            <button
              onClick={() => setDataShow(1)}
              className="upcoming-button-690"
            >
              Upcoming
            </button>
            <button
              onClick={() => setDataShow(2)}
              className="upcoming-button-590"
            >
              Completed
            </button>
          </div>
        </div>
      </section>
      {DataShow === 0 ? (
        <Appoinment1 />
      ) : DataShow === 1 ? (
        <Appoinment2 />
      ) : DataShow === 2 ? (
        <Appoinment3 />
      ) : null}
    </>
  );
};

export default NewClient;
