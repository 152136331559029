import React from "react";
import ReactSlider from "react-slider";
import "../Css/FreeCalculator.css";
const FreeCalculator = () => {
  return (
    <>
      <section className="FreeCalculator-sec">
        <div className="FreeCalculator-div-main">
          <div className="FreeCalculator-div">
            <span className="Calculator-span mb-4">Free Calculator</span>
            <span className="rupy-span">$ 48.00</span>
            <span className="Total-span">Total Amount</span>
            <div className="Enter-fee-div">
              <span className="afreee-span mb-2">Enter Fee Amount</span>
              <input placeholder="$40" className="Amount-input" type="text" />
            </div>
            <span>Increase Time</span>
            <ReactSlider
              className="horizontal-slider"
              marks={[0, 30, 60, 90, 120, 150]}
              markClassName="example-mark"
              min={0}
              max={150}
              thumbClassName="example-thumb"
              trackClassName="example-track"
              renderThumb={(props, state) => (
                <div {...props}>{state.valueNow}min</div>
              )}
            />
            <div className="mt-5 flet-span-div">
              <div className="flet-span">
                <span className="Extra-span-ha">Flat Fee</span>
                <span className="Extra-span-ha">$ 40</span>
              </div>
              <div className="flet-line"></div>
              <div className="flet-span">
                <span className="Extra-span-ha">Service Fee (15 %)</span>
                <span className="Extra-span-ha">$ 6</span>
              </div>
              <div className="flet-line"></div>
              <div className="flet-span">
                <span className="Extra-span-ha">Processing Fee</span>
                <span className="Extra-span-ha">$ 2</span>
              </div>
              <div className="flet-line"></div>
              <div className="flet-span">
                <span className="Extra-span-ha">Extra (5% above $40)</span>
                <span className="Extra-span-ha">$ 0.00</span>
              </div>
              <div className="flet-line"></div>
              <div className="flet-span">
                <span className="Total-span">Total</span>
                <span className="Total-span">$ 48.00</span>
              </div>
            </div>
            <div>
              <button className="mt-5 mb-5 Calculate-batn">Calculate</button>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default FreeCalculator;
