import React from "react";
import "../Css/Congratulations.css";
import { useNavigate } from "react-router-dom";

import Lottie from "lottie-react";
import Navbar2 from "./Navbar2";
import Footer2 from "./Footer2";
const Congratulations = () => {
  const navigate = useNavigate();

  return (
    <>
      <Navbar2 />
      <section className="Congratulations-sec">
        <div className="Congratulations-div">
          <div className="lottiy-all-contain">
            <Lottie
              className="cong-animation"
              animationData={require("../Animation/Cong.json")}
            />
            <span className="Successfully-span mb-3">Successfully Done!</span>
            <span className="booking-span">
              Your booking has been submitted
            </span>
            <span className="booking-span">successfully.</span>
            <button
              className="mt-3 ok-btn-cong"
              onClick={() => navigate("/PaymentFailed")}
            >
              ok
            </button>
          </div>
        </div>
      </section>
      <Footer2 />
    </>
  );
};

export default Congratulations;
