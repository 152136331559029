import React, { useState, useRef, useEffect } from "react";
import "../Css/EditProfile2.css";
import camimg2 from "../Images/camimg2.png";
import camimg1 from "../Images/camimg1.png";
import addlogo from "../Logo/addlogo.png";
// import inputf from "../Images/inuuu.png";
import { useFormik } from "formik";
import { EditProfile2Schemas } from "../schemas";
import { parsePhoneNumber } from "react-phone-number-input";
import PhoneInput from "react-phone-number-input";
import "react-phone-number-input/style.css";
import axios from "../schemas/APi1";
// import add from "../Logo/Add.png";
// import TapTap from "../Logo/Uplod.png";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import { useUser } from "../UserContext.js";

const initialValues = {
  FullName: "",
  MobileNumber: "",
  email: "",
  // RatePerhour: "",
  // Writehere: "",
  // YourSpeciality: "",
  // ResolutionExperience: "",
};
const EditProfile2 = () => {
  const { updateUserData } = useUser();

  // edit profile advocate usestate
  const [Editdata, setEditdata] = useState([]);
  const [loading, setloading] = useState(false);

  const [specialist, setSpecialist] = useState([]);
  const [selectedImages, setSelectedImages] = useState({
    img: null,
  });
  const data = JSON.parse(localStorage.getItem("Nezziedata"));
  // console.log(data.token);

  const [images, setImages] = useState();
  const [skills, setSkills] = useState([]);
  const [newSkill, setNewSkill] = useState("");
  const [fieldCleared, setFieldCleared] = useState(false);
  const camimg1Ref = useRef(null);
  // const camimg2Ref = useRef(null);
  const handleImageChanges = (e, imageType) => {
    const file = e.target.files[0];
    setImages(file);
    setSelectedImages({ img: URL.createObjectURL(file) });
    console.log(file);
  };
  // for form submit
  const handleFormSubmit = (event) => {
    event.preventDefault(); // Prevent the default form submission behavior
  };

  // edit profile api integrated at here

  const onSubmit = async () => {
    setloading(true);
    try {
      const resp = await axios.post(
        "advocateUpdate",
        {
          fullName: values.FullName,
          mobileNumber: values.MobileNumber,
          email: values.email,
          image: image,
        },
        {
          headers: {
            "Content-Type": "multipart/form-data ",
            Authorization: data.token,
          },
        }
      );
      if (resp) {
        setloading(false);
      }
      toast.success(resp.data.message, {
        position: "top-right",
        theme: "colored",
      });
      setEditdata(resp.data.Data);
      if (resp.data.message === "User Updated Successfully") {
      }
      // localStorage.setItem("Nezziedata", JSON.stringify(resp.data.Data));
      updateUserData(resp.data.Data);

      console.log(resp);
    } catch (error) {
      console.log("Error", error);
      setloading(false);
      toast.error(error.resp.data.message, {
        position: "top-right",
        theme: "colored",
      });
    }
  };
  useEffect(() => {
    setFieldValue("FullName", data.fullName);
    setFieldValue("MobileNumber", data.mobileNumber);
    setPhone(data.countryCode + data.mobileNumber);
    setFieldValue("email", data.email);
  }, []);
  // validation useformik
  const {
    values,
    handleBlur,
    handleChange,
    touched,
    handleSubmit,
    errors,
    setFieldValue,
  } = useFormik({
    initialValues: initialValues,
    validationSchema: EditProfile2Schemas,
    onSubmit,
  });
  // console.log(errors);

  // phone input
  const [phone, setPhone] = useState("");

  const handlePhoneChange = (value) => {
    setPhone(value);
    setFieldValue("MobileNumber", value);
    try {
      const parsedPhone =
        value.length >= 6
          ? parsePhoneNumber(value, { defaultCountry: "US" })
          : null;

      if (parsedPhone) {
        setFieldValue("country", `+${parsedPhone.countryCallingCode}`);
        setFieldValue("MobileNumber", parsedPhone.nationalNumber);
      } else {
        setFieldValue("country", "");
        setFieldValue("MobileNumber", "");
      }
    } catch (error) {
      console.log(error);
    }
  };
  // for skill
  const handleSkillChange = (event) => {
    setNewSkill(event.target.value);
    setFieldCleared(false);
  };

  const handleAddSkill = () => {
    if (newSkill.trim() !== "") {
      if (!skills.includes(newSkill.trim())) {
        setSkills([...skills, newSkill.trim()]);
        setNewSkill("");
        setFieldCleared(true);
      } else {
        console.log("Skill already exists.");
      }
    } else {
      console.log("Skill value is required.");
    }
  };

  const handleCancelSkill = (index) => {
    const updatedSkills = [...skills];
    updatedSkills.splice(index, 1);
    setSkills(updatedSkills);
  };

  const removeSkillIconSvg = `
  <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="#1E2D38">
    <g clip-path="url(#clip0_27_6463)">
      <path d="M10.0001 8.82129L14.1251 4.69629L15.3034 5.87462L11.1784 9.99962L15.3034 14.1246L14.1251 15.303L10.0001 11.178L5.87511 15.303L4.69678 14.1246L8.82178 9.99962L4.69678 5.87462L5.87511 4.69629L10.0001 8.82129Z" fill="#1E2D38" />
    </g>
    <defs>
      <clipPath id="clip0_27_6463">
        <rect width="20" height="20" fill="white" />
      </clipPath>
    </defs>
  </svg>
`;
  const addIconSvg = `
    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="#1E2D38">
      <path d="M10 2V10H18V12H10V20H8V12H0V10H8V2H10Z"/>
    </svg>
  `;
  const fileInputRef = useRef(null);
  const [image, setImage] = useState(null);
  const handleImageChange = (event) => {
    // const file = event.target.files[0];
    // console.log(file);
    setImage(event.target.files[0]);
  };
  const handleImageClick = () => {
    fileInputRef.current.click();
  };
  const specialityData = async () => {
    try {
      const res = await axios.post(
        "meta_data/speciallityList",

        {
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
          },
        }
      );
      console.log(res);
      setSpecialist(res.data.data);
    } catch (error) {
      console.log("Error", error);
    }
  };
  useEffect(() => {
    specialityData();
  }, []);
  return (
    <>
      <form onSubmit={handleSubmit} className="editprofile2-main3-section4">
        <div className="editprofile2-main3-div">
          <div className="editprofile-span90-seted">
            <span className="editprofile-span90">Edit Profile</span>
          </div>
          <div className="edit-profile-img-position-div">
            <div className="camimg1-camimg2-pdiv">
              <img
                className="camimg2-img"
                src={selectedImages.img || camimg2}
                alt=""
              />
              <div className="camimg1-div13">
                <img
                  src={camimg1}
                  alt=""
                  className="camimg1-img"
                  onClick={() => camimg1Ref.current.click()}
                />
                <input
                  type="file"
                  accept="image/*"
                  ref={camimg1Ref}
                  style={{ display: "none" }}
                  onChange={(e) => handleImageChanges(e, "camimg1")}
                />
              </div>
            </div>
            <div className="input-text-father-div">
              <div className="input-text-child2-div">
                <div className="label-or-feild-div44">
                  <label htmlFor="fullname" className="html12-label-span10">
                    Full Name
                  </label>
                  <input
                    type="text"
                    className="kathryn-input222"
                    // placeholder="Kathryn Murphy"
                    name="FullName"
                    value={values.FullName}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                  {errors.FullName && touched.FullName ? (
                    <p className="mastu-to-eroor">{errors.FullName} </p>
                  ) : null}
                </div>

                <div className="label-or-feild-div44">
                  <label htmlFor="fullname" className="html12-label-span10">
                    Mobile Number
                  </label>
                  <PhoneInput
                    className="kathryn-input222"
                    name="MobileNumber"
                    // placeholder="+1 989809 98980"
                    style={{ cursor: "not-allowed" }}
                    disabled={true}
                    onChange={handlePhoneChange}
                    value={phone}
                    onBlur={handleBlur}
                  />
                  {errors.MobileNumber && touched.MobileNumber ? (
                    <p className="mastu-to-eroor">{errors.MobileNumber} </p>
                  ) : null}
                </div>
              </div>
              <div className="label-or-feild-div30">
                <label htmlFor="fullname" className="html12-label-span10">
                  Email
                </label>

                <input
                  type="email"
                  className="kathryn-second-input-20"
                  // placeholder="kathryn_murphy07@gmail.com"
                  value={values.email}
                  name="email"
                  style={{ cursor: "not-allowed" }}
                  disabled={true}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
                {errors.email && touched.email ? (
                  <p className="mastu-to-eroor">{errors.email} </p>
                ) : null}
              </div>

              <div className="button-save-div">
                <button className="save-button25-25" type="submit">
                  Save
                </button>
              </div>
            </div>
          </div>
          {/* <div className="border-special-div"></div> */}
          {/* <div className="Professional-Info-vhala">
          <span className="Professional-Info">Professional Info</span>
        </div> */}
          {/* <div className="Professional-Info-main-div">
          <div className="Professional-Info-child-div">
            <div className="rate-per-hour-dv">
              <label htmlFor="Rate-label" className="Rate-label">
                Rate (Per hour)
              </label>
              <input
                type="text"
                className="Professional-Info-input"
                placeholder="$"
                value={values.RatePerhour}
                onChange={handleChange}
                onBlur={handleBlur}
                name="RatePerhour"
              />
              {errors.RatePerhour && touched.RatePerhour ? (
                <p className="mastu-to-eroor">{errors.RatePerhour} </p>
              ) : null}
            </div>

            <div className="rate-per-hour-dv">
              <label htmlFor="Rate-label" className="Rate-label">
                Skills
              </label>
              <div className="doler-ka-liya mt-2">
                <div className="Lorem-btn">
                  <div className="Lorem-btn">
                    {skills.map((skill, index) => (
                      <div key={index} className="skill-item">
                        <span className="skill-text">{skill}</span>
                        <span
                          className="remove-skill-icon"
                          dangerouslySetInnerHTML={{
                            __html: removeSkillIconSvg,
                          }}
                          onClick={() => handleCancelSkill(index)}
                        />
                      </div>
                    ))}
                    <div className="add-skill-input">
                      <input
                        className="add-skill-input-field"
                        type="text"
                        placeholder="Add new skill"
                        name="Skills"
                        value={newSkill}
                        onChange={handleSkillChange}
                        onBlur={handleBlur}
                      />
                      <button
                        className="add-skill-btn"
                        onClick={handleAddSkill}
                        dangerouslySetInnerHTML={{ __html: addIconSvg }}
                      />
                    </div>
                  </div>
                </div>
              </div>
              {errors.Skills && touched.Skills ? (
                <p className="mastu-to-eroor">{errors.Skills} </p>
              ) : null}
            </div>
            <div className="rate-per-hour-dv">
              <label htmlFor="Rate-label" className="Rate-label">
                Your Speciality
              </label>
              <select
                name="YourSpeciality"
                id=""
                placeholder="Select"
                className="Professional-Info-input3 custom-select"
                value={values.YourSpeciality}
                onChange={handleChange}
                onBlur={handleBlur}
              >
                <option value="" disabled selected hidden>
                  Select
                </option>
                {specialist.map((item, index) => {
                  return (
                    <option key={index} value={item._id}>
                      {item.speciallityName}
                    </option>
                  );
                })}
              </select>
              {errors.YourSpeciality && touched.YourSpeciality ? (
                <p className="mastu-to-eroor">{errors.YourSpeciality} </p>
              ) : null}
            </div>

            <div className="rate-per-hour-dv" onClick={handleImageClick}>
              <label htmlFor="Rate-label" className="Rate-label">
                Upload Certificates
              </label>
              {image ? (
                <img
                  className="Tap-to-imgg"
                  src={URL.createObjectURL(image)}
                  alt=""
                  onChange={(e) => setImage(e.target.files[0])}
                />
              ) : (
                <div className="hight-width-input">
                  <div className="document-div mt-3">
                    <img className="Default-to-imgg" src={TapTap} alt="" />
                    <span>Drag & Drop to upload document</span>
                  </div>
                  <div className="add-img-ho">
                    <img className="add-upolod-imgs" src={add} alt="" />
                  </div>
                </div>
              )}

              <input
                ref={fileInputRef}
                onChange={handleImageChange}
                className="File-img-gov"
                type="file"
                name="gov_id"
                style={{ display: "none" }}
              />
            </div>
          </div>
          <div className="text-area-main-div">
            <div className="text-area-child-div">
              <label htmlFor="text-area" className="Rate-label">
                About You
              </label>
              <textarea
                className="text-area50-text"
                placeholder="Write here"
                style={{ fontFamily: "inherit" }}
                name="Writehere"
                value={values.Writehere}
                onChange={handleChange}
                onBlur={handleBlur}
              ></textarea>
              {errors.Writehere && touched.Writehere ? (
                <p className="mastu-to-eroor">{errors.Writehere} </p>
              ) : null}
            </div>
            <div className="text-area-child-div">
              <label htmlFor="text-area" className="Rate-label">
                Resolution Experience
              </label>
              <textarea
                placeholder="Write here"
                className="text-area50-text50"
                style={{ fontFamily: "inherit" }}
                name="ResolutionExperience"
                value={values.ResolutionExperience}
                onChange={handleChange}
                onBlur={handleBlur}
              ></textarea>
              {errors.ResolutionExperience && touched.ResolutionExperience ? (
                <p className="mastu-to-eroor ">
                  {errors.ResolutionExperience}{" "}
                </p>
              ) : null}
            </div>
          </div>
        </div> */}
        </div>
        {/* <div className="add-logo-span-div6">
        <img src={addlogo} alt="" className="add-logo-pp" />
        <span className="add-logo-span">Add more Documents/Certificate</span>
      </div>
      <div className="save-save2-button-div">
        {" "}
        <button className="save-save2-button">Save</button>
      </div> */}
      </form>
      {!loading && <div></div>}
      {loading && (
        <div>
          <Backdrop
            sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open={true}
            // onClick={handleClose}
          >
            <CircularProgress color="inherit" />
          </Backdrop>
        </div>
      )}
    </>
  );
};

export default EditProfile2;
