import React, { useState, useRef } from "react";
import "../Css/Post2.css";
import New from "./New";
// import { FiArrowUpRight } from "react-icons/fi";
// import { useEffect } from "react";
import axios from "../schemas/APi1";
import { Post2Schemas } from "../schemas";
import { useFormik } from "formik";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
// import ServiceComplaints from "./ServiceComplaints";
// import OnlineTransaction from "./OnlineTransaction";
import { useContext } from "react";
import { Globalcontext } from "../Context/GlobalContext";
import { useEffect } from "react";
// import axios from "axios";

const initialValues = {
  rate: "",
  Writehere: "",
};
const Post2 = ({ specialist }) => {
  console.log(specialist);
  const [bdata, setbData] = useState(0);
  const [newdata, setnewData] = useState(0);
  const { context, setContext } = useContext(Globalcontext);
  const data = JSON.parse(localStorage.getItem("Nezziedata"));
  const [bhaidata, setBhaidata] = useState([]);
  const [selectedSubcat1, setSelectedSubcat1] = useState(0);
  const [selectedSubcat2, setSelectedSubcat2] = useState(null);
  const [itemsave, setItemsave] = useState("");
  const [OkTo, setOkTo] = useState([]);
  console.log(OkTo, ".O.");
  console.log(itemsave);
  const [another, setAnother] = useState("");
  console.log(another);

  useEffect(() => {
    brotherData();
  }, []);
  const dataspecuails = localStorage.getItem("specialitydata");
  const specialname = localStorage.getItem("specialityname");
  // console.log(specialname);
  // console.log(dataspecuails);
  const brotherData = async () => {
    try {
      const response = await axios.post(
        "meta_data/speciallity_data",
        { speciallityId: dataspecuails },
        {
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            Authorization: data.token,
          },
        }
      );
      setBhaidata(response.data.data);
      setOkTo(response.data.data[0].subcat_2datas[0]._id);
      console.log(response);
    } catch (error) {
      console.log("Error", error);
    }
  };

  // add post api integrated at here
  const addpost = async () => {
    try {
      let otherpostValue = 0; // Default value
      if (selectedSubcat2 !== null) {
        otherpostValue = 1; // Set to 1 when subcat2 is selected
      } else if (selectedSubcat1 === 0) {
        otherpostValue = 0; // Set to 0 when subcat1 is 0
      }

      const res = await axios.post(
        "https://admin.nezzie.app/api/user/post/addPost",
        {
          rate: values.rate,
          SpeciallityId: itemsave,
          subCategoryId1: another,
          subCategoryId2: OkTo,
          otherpost: otherpostValue,
          otherForText: "dummy text",
        },
        {
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            Authorization: data?.token,
          },
        }
      );
      console.log(res);
      if (res.data.message === "Post Added Successfully") {
        toast.success(res.data.message, {
          position: "top-right",
          theme: "colored",
        });
        values.rate = "";
      }
    } catch (error) {
      console.log("Error", error);
      toast.error(error.res.data.message, {
        position: "top-right",
        theme: "colored",
      });
    }
  };

  // const btns = [
  //   {
  //     id: "1",
  //     Name: "Product Issues",
  //   },
  //   {
  //     id: "2",
  //     Name: "Billing and Payment",
  //   },
  //   {
  //     id: "3",
  //     Name: "Service Complaints",
  //   },
  //   {
  //     id: "4",
  //     Name: "Online Transactions",
  //   },
  // ];
  // const btns1 = [
  //   {
  //     id: "1",
  //     Name: " Defective Product",
  //   },
  //   {
  //     id: "2",
  //     Name: "Return Refunds",
  //   },
  //   {
  //     id: "3",
  //     Name: "Product Recall",
  //   },
  //   {
  //     id: "4",
  //     Name: "Quality Compliants",
  //   },
  // ];
  const {
    values,
    handleBlur,
    handleChange,
    touched,
    // handleSubmit,
    // setFieldValue,
    errors,
    // setTouched,
  } = useFormik({
    initialValues: initialValues,
    validationSchema: Post2Schemas,
    // Address: address,
    // onSubmit,
  });
  const handlemodal = (i) => {
    setbData(i);
    setSelectedSubcat1(i);
    setContext(false);
  };
  const handlemodal2 = (items, i) => {
    setItemsave(items.speciallityId);
    setAnother(items.subCat1Id);
    console.log(items, "hsdhgfhv");
    setnewData(i);
    setSelectedSubcat2(i);
    setContext(true);
  };

  useEffect(() => {
    if (selectedSubcat1 !== null && selectedSubcat2 !== null) {
      // Both subcat1 and subcat2 are selected
      const selectedSubcat1Name = bhaidata[selectedSubcat1].subCat1;
      const selectedSubcat2Name =
        bhaidata[selectedSubcat1].subcat_2datas[selectedSubcat2].subCat2;
      console.log("Selected Subcat1:", selectedSubcat1Name);
      console.log("Selected Subcat2:", selectedSubcat2Name);
    }
  }, [selectedSubcat1, selectedSubcat2, bhaidata]);

  return (
    <>
      <div className="second-post-main-section">
        <>
          <div className="second-post-child-div">
            <span className="customer-span-span98">{specialname}</span>
          </div>
        </>

        <div className="both-categories-90-div">
          {bhaidata.map((item, i) => {
            return (
              <>
                <button
                  className={
                    selectedSubcat1 === i
                      ? "Product-Issues-button"
                      : "Product-Issues-button-unactive"
                  }
                  key={i}
                  onClick={() => handlemodal(i)}
                >
                  {item.subCat1}
                </button>
              </>
            );
          })}
        </div>
        <>
          <div className="char-button-div">
            {bhaidata.map((item, i) => {
              return (
                <>
                  {item.subcat_2datas.map((items, index) => {
                    return (
                      <>
                        {selectedSubcat1 === i && (
                          <button
                            className={
                              selectedSubcat2 === index
                                ? "Product-Issues-button22"
                                : "Product-Issues-button-unactive22"
                            }
                            key={i}
                            onClick={() => handlemodal2(items, index)}
                          >
                            {items.subCat2}
                          </button>
                        )}
                      </>
                    );
                  })}
                </>
              );
            })}
          </div>
        </>
        {/* ) : bdata === 1 ? (
          <New />
        ) : bdata === 2 ? (
          <ServiceComplaints />
        ) : bdata === 3 ? (
          <OnlineTransaction />
        ) : null} */}
        <div>
          <button className="Craft-Anynomus-Help-Request">
            Craft Anynomus Help Request
          </button>
        </div>
        {context ? null : (
          <div className="How-much-spend-div">
            <span className="subcategories-span-00-00">
              Craft Anynomus Help Request
            </span>
            <div className="input-container-909">
              <input
                type="text"
                className="categories-input-909"
                placeholder="Write here..."
                name="Writehere"
                value={values.Writehere}
                onChange={handleChange}
                onBlur={handleBlur}
              />
            </div>
            {errors.Writehere && touched.Writehere ? (
              <p className="mastu-to-eroor mt-2">{errors.Writehere} </p>
            ) : null}
          </div>
        )}

        <div className="How-much-spend-div">
          <span className="subcategories-span-00-00">
            How much spend you in this topic?
          </span>

          <div className="input-container-909">
            <input
              type="number"
              className="categories-input"
              // placeholder="$"
              name="rate"
              value={values.rate}
              onChange={handleChange}
              onBlur={handleBlur}
            />
            <span class="placeholder-text12">$</span>
          </div>

          {errors.rate && touched.rate ? (
            <p className="mastu-to-eroor mt-2">{errors.rate} </p>
          ) : null}
        </div>
        <div>
          <button className="new-post0button-09" onClick={addpost}>
            Post
          </button>
        </div>
      </div>
    </>
  );
};

export default Post2;
