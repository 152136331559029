// import logo from "./logo.svg";
// import "./App.css";
import { Routes, Route } from "react-router-dom";
import HowItsWork from "../src/Component/HowItsWork";
import YourTrust from "../src/Component/YourTrust";
// import AAdvocates from "../src/Component/AAdvocates";
import Home from "../src/Component/Home";
import AdviceAdvocate from "./Component/AdviceAdvocate";
import AdvocateDetail from "./Component/AdvocateDetail";
import BookNow from "./Component/BookNow";
// import RatethisAdvocate from "./Component/RatethisAdvocate";
import RatethisCall from "./Component/RatethisCall";
import ChangePassword from "./Component/ChangePassword";
import AdChangepassword from "./Auth/AdChangepassword";
import Congratulations from "./Component/Congratulations";
import PaymentFailed from "./Component/PaymentFailed";
import FreeCalculator from "./Component/FreeCalculator";
import Register from "./Auth/Register";
import Login from "./Auth/Login";
import Adlogin from "./Auth/Adlogin";
import AdForgotPassword from "./Auth/AdForgotPassword";
import ForgotPassword from "./Auth/ForgotPassword";
import MyBooking from "./Component/Mybooking";
import MyBooking2 from "./Component/MyBooking2";
import AvailableAdvocate from "./Component/AvailableAdvocate";
import EditProfile from "./Component/EditProfile";
import SideBar from "./Component/SideBar";
import NearMe from "./Component/NearMe";
import Payment from "./Component/Payment";
import Settings from "./Component/Settings";
import NewClient from "../src/Component/NewClient";
// import RateCustomer from "./Component/RateCustomer";
import MyWallet from "./Component/MyWallet";
import AdRegister from "./Auth/AdRegister";
import RegisterTwo from "./Auth/RegisterTwo";
// import SetAvailability from "./Component/SetAvailability";
import Footer from "./Component/Footer";
import Footer2 from "./Component/Footer2";
import Appoinment1 from "./Component/Appoinment1";
import Appoinment2 from "./Component/Appoinment2";
import Appoinment3 from "./Component/Appoinment3";
import EditProfile2 from "./Component/EditProfile2";
import Navbar from "./Component/Navbar";
import Navbar2 from "./Component/Navbar2";
import Navbar1 from "./Component/Navbar1";
import Navbar3 from "./Component/Navbar3";
import JobPosts from "./Component/JobPosts";
import Filter from "./Component/Filter";
import MyPost from "./Component/MyPost";
import PostJob from "./Component/PostJob";
import DropDown from "./Component/DropDown";
import AdvoUser from "./Component/AdvoUser";
import Modal from "./Component/Modal";
// import Mm from "./Component/Mm";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Bar from "./Component/Bar";
import TermsCondition from "./Component/TermsCondition";
import Tip from "./Component/Tip";
import Availability from "./Component/Availablity";
// import Post2 from "./Component/Post2";
import { useEffect } from "react";
import Housing from "./Component/Housing";
// import New from "./Component/New";
// import Housing2 from "./Component/Housing2";
import Search from "./Component/Search";
import Tip2 from "./Component/Tip2";
import Post2 from "./Component/Post2";
import ServiceComplaints from "./Component/ServiceComplaints";
import OnlineTransaction from "./Component/OnlineTransaction";
import Landlord from "./Component/Landlord";
import Violations from "./Component/Violations";
import TraveleTourism from "./Component/TravaleTourism";
import Environmental from "./Component/Environmental";
import Technology from "./Component/Technology";
import Education from "./Component/Education";
import Financial from "./Component/Financial";
import Physical from "./Component/Physical";
import Employment from "./Component/Employment";
import Medical from "./Component/Medical";
import LostLuggage from "./Component/LostLuggage";
import TourOperator from "./Component/TourOperator";
import Landuse from "./Component/Landuse";
import EnvironmentalCompliance from "./Component/EnvironmentalCompliance";
import NaturalResources from "./Component/NaturalResources";
import DataBreach from "./Component/DataBreach";
import Service from "./Component/Service";
import TechnologyPatent from "./Component/TechnologyPatent";
import Discrimination from "./Component/Discrimination";
import Acedemic from "./Component/Acedemic";
import School from "./Component/School";
import Banking from "./Component/Banking";
import Debt from "./Component/Debt";
import Fraud from "./Component/Fraud";
import Cardealership from "./Component/Cardealership";
import Nonnative from "./Component/Nonnative";
import Claims from "./Component/Claims";
import Contract from "./Component/Contract";
import Insurance from "./Component/Insurance";
import PatentRights from "./Component/PatentRights";
import Health from "./Component/Health";
import Support from "./Component/Support";
import Notification from "./Component/Notification";
import New from "./Component/New";
import Open from "./Component/Open";
import { Globalcontextprovider } from "./Context/GlobalContext";
import Viewoffer from "./Component/Viewoffer";
import Categories from "./Component/Categories";
function App() {
  useEffect(() => {
    navigator.geolocation.getCurrentPosition((position) => {
      localStorage.setItem("lat", position.coords.latitude);
      localStorage.setItem("lon", position.coords.longitude);
    });
  }, []);

  return (
    <Globalcontextprovider>
      <div className="App">
        <Routes>
          {/* EditProfile */}
          <Route path="/" element={<Home />} />
          <Route path="Login" element={<Login />} />
          <Route path="Adlogin" element={<Adlogin />} />
          <Route path="Register" element={<Register />} />
          <Route path="ForgotPassword" element={<ForgotPassword />} />
          <Route path="AdForgotPassword" element={<AdForgotPassword />} />
          <Route path="MyBooking" element={<MyBooking />} />
          <Route path="MyBooking2" element={<MyBooking2 />} />
          <Route path="Navbar" element={<Navbar />} />
          <Route path="Navbar1" element={<Navbar1 />} />
          <Route path="Navbar3" element={<Navbar3 />} />
          <Route path="Navbar2" element={<Navbar2 />} />
          <Route path="Modal" element={<Modal />} />
          {/* <Route path="Post2" element={<Post2 />} /> */}
          {/* <Route path="Housing2" element={<Housing2 />} /> */}
          <Route path="Housing" element={<Housing />} />
          <Route path="/MyProfile2" element={<Bar />}>
            <Route path="/MyProfile2/EditProfile2" element={<EditProfile2 />} />
            <Route path="/MyProfile2/MyWallet" element={<MyWallet />} />
            <Route path="/MyProfile2/Availability" element={<Availability />} />
            <Route
              path="/MyProfile2/AdChangepassword"
              element={<AdChangepassword />}
            />
            <Route path="/MyProfile2/Settings" element={<Settings />} />
            <Route
              path="/MyProfile2/TermsCondition"
              element={<TermsCondition />}
            />
          </Route>

          <Route path="MyProfile" element={<SideBar />}>
            <Route path="/MyProfile/EditProfile" element={<EditProfile />} />
            <Route path="/MyProfile/MyBookings" element={<MyBooking />} />
            <Route path="/MyProfile/PaymentOptions" element={<Payment />} />
            <Route path="/MyProfile/MyPost" element={<MyPost />} />
            <Route path="/MyProfile/Settings" element={<Settings />} />
            <Route
              path="/MyProfile/TermsCondition"
              element={<TermsCondition />}
            />
            <Route path="/MyProfile/Support" element={<Support />} />
            <Route
              path="/MyProfile/ChangePassword"
              element={<ChangePassword />}
            />
          </Route>
          <Route path="Footer" element={<Footer />} />
          <Route path="NearMe" element={<NearMe />} />
          <Route path="HowItsWork" element={<HowItsWork />} />
          <Route path="YourTrust" element={<YourTrust />} />
          {/* <Route path="AAdvocates" element={<AAdvocates />} /> */}
          <Route path="AvailableAdvocate" element={<AvailableAdvocate />} />
          <Route path="AdviceAdvocate" element={<AdviceAdvocate />} />
          <Route path="AdvocateDetail" element={<AdvocateDetail />} />
          <Route path="BookNow" element={<BookNow />} />
          {/* <Route path="RatethisAdvocate" element={<RatethisAdvocate />} /> */}
          <Route path="RatethisCall" element={<RatethisCall />} />
          <Route path="ChangePassword" element={<ChangePassword />} />
          <Route path="Congratulations" element={<Congratulations />} />
          <Route path="PaymentFailed" element={<PaymentFailed />} />
          <Route path="FreeCalculator" element={<FreeCalculator />} />
          <Route path="NewClient" element={<NewClient />} />
          {/* <Route path="RateCustomer" element={<RateCustomer />} /> */}
          <Route path="AdRegister" element={<AdRegister />} />
          <Route path="RegisterTwo" element={<RegisterTwo />} />
          <Route path="Footer2" element={<Footer2 />} />
          <Route path="Appoinment1" element={<Appoinment1 />} />
          <Route path="Appoinment2" element={<Appoinment2 />} />
          <Route path="Appoinment3" element={<Appoinment3 />} />
          {/* <Route path="EditProfile2" element={<EditProfile2 />} /> */}
          <Route path="JobPosts" element={<JobPosts />} />
          <Route path="Filter" element={<Filter />} />
          <Route path="PostJob" element={<PostJob />} />
          <Route path="DropDown" element={<DropDown />} />
          <Route path="AdvoUser" element={<AdvoUser />} />
          {/* <Route path="Availablity" element={<Availablity />} /> */}
          {/* <Route path="Mm" element={<Mm />} /> */}
          <Route path="Tip" element={<Tip />} />
          <Route path="Search" element={<Search />} />
          <Route path="Tip2" element={<Tip2 />} />
          <Route path="Post2" element={<Post2 />} />
          <Route path="ServiceComplaints" element={<ServiceComplaints />} />
          <Route path="OnlineTransaction" element={<OnlineTransaction />} />
          <Route path="Landlord" element={<Landlord />} />
          <Route path="Violations" element={<Violations />} />
          <Route path="TraveleTourism" element={<TraveleTourism />} />
          <Route path="Environmental" element={<Environmental />} />
          <Route path="Technology" element={<Technology />} />
          <Route path="Education" element={<Education />} />
          <Route path="Financial" element={<Financial />} />
          <Route path="Physical" element={<Physical />} />
          <Route path="Employment" element={<Employment />} />
          <Route path="Medical" element={<Medical />} />
          <Route path="LostLuggage" element={<LostLuggage />} />
          <Route path="TourOperator" element={<TourOperator />} />
          <Route path="Landuse" element={<Landuse />} />
          <Route
            path="EnvironmentalCompliance"
            element={<EnvironmentalCompliance />}
          />
          <Route path="NaturalResources" element={<NaturalResources />} />
          <Route path="DataBreach" element={<DataBreach />} />
          <Route path="Service" element={<Service />} />
          <Route path="TechnologyPatent" element={<TechnologyPatent />} />
          <Route path="Discrimination" element={<Discrimination />} />
          <Route path="Acedemic" element={<Acedemic />} />
          <Route path="School" element={<School />} />
          <Route path="Banking" element={<Banking />} />
          <Route path="Debt" element={<Debt />} />
          <Route path="Fraud" element={<Fraud />} />
          <Route path="Cardealership" element={<Cardealership />} />
          <Route path="Nonnative" element={<Nonnative />} />
          <Route path="Claims" element={<Claims />} />
          <Route path="Contract" element={<Contract />} />
          <Route path="Insurance" element={<Insurance />} />
          <Route path="PatentRights" element={<PatentRights />} />
          <Route path="Health" element={<Health />} />
          <Route path="Support" element={<Support />} />
          <Route path="Notification" element={<Notification />} />
          <Route path="New" element={<New />} />
          <Route path="Open" element={<Open />} />
          <Route path="Viewoffer" element={<Viewoffer />} />
          <Route path="Categories" element={<Categories />} />
        </Routes>
      </div>
      <ToastContainer />
    </Globalcontextprovider>
  );
}

export default App;
