import React, { useEffect, useState } from "react";
import "../Css/PostJob.css";
import { useFormik } from "formik";
import { PostaJobSchemas } from "../schemas";
import Navbar2 from "./Navbar2";
import Footer2 from "./Footer2";
import axios from "../schemas/APi1";
// import axios from "../schemas/APi";

import PlacesAutocomplete, {
  geocodeByAddress,
  // getLatLng,
} from "react-places-autocomplete";

import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";

const initialValues = {
  Description: "",
  State: "",
  City: "",
  ExperienceIn: "",
  HourlyRate: "",
  HourlyRateto: "",
  TimeDuration: "",
  TotalHours: "",
  Writehere: "",
  Address: "",
  Country: "",
};
const PostJob = () => {
  const [address, setAddress] = useState("");
  const [city, setCity] = useState("");
  const [state, setState] = useState("");
  const [country, setCountry] = useState("");
  const [specialist, setSpecialist] = useState([]);
  const data = JSON.parse(localStorage.getItem("Nezziedata"));
  localStorage.getItem("lat");
  localStorage.getItem("long");
  const navigate = useNavigate();

  const onSubmit = async () => {
    try {
      const resp = await axios.post(
        "https://admin.nezzie.app/api/user/post/addPost",
        {
          // Replace "your-post-job-api-endpoint" with the actual API endpoint
          requirementTitle: values.Writehere,
          totalHours: values.TotalHours,
          timeDuration: values.TimeDuration,
          hourlyRate: {
            maxRate: values.HourlyRate,
            minRate: values.HourlyRateto,
          },
          experianceIn: values.ExperienceIn,
          city: values.City,
          state: values.State,
          description: values.Description,
          address: values.Address,
          country: values.Country,
          //           longitude:localStorage.getItem("lat"),
          // latitude:localStorage.getItem("long"),
          longitude: "72.5714",
          latitude: "23.0225",
        },
        {
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            Authorization: data.token,
          },
        }
      );
      console.log(resp);
      // toast.success(resp.data.message, {
      //   position: "top-right",
      //   theme: "colored",
      // });

      if (resp.data.message === "Post Added Successfully") {
        toast.success(resp.data.message, {
          position: "top-right",
          theme: "colored",
        });
      }
      navigate("/MyProfile/MyPost");
    } catch (error) {
      console.log("error", error);
      toast.error(error.resp.data.message, {
        position: "top-right",
        theme: "colored",
      });
    }
  };

  // specialist api
  const specialityData = async () => {
    try {
      const res = await axios.post(
        "meta_data/speciallityList",

        {
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
          },
        }
      );
      console.log(res);
      setSpecialist(res.data.data);
    } catch (error) {
      console.log("Error", error);
    }
  };
  useEffect(() => {
    specialityData();
  }, []);

  const handleAddressChange = (newAddress) => {
    setAddress(newAddress);
  };
  // const handleAddressBlur = () => {
  //   setTouched({ ...touched, Address: true });
  // };
  const handleAddressBlur = () => {
    handleBlur("Address");
  };

  const handleSelect = async (selectedAddress) => {
    setFieldValue("Address", selectedAddress);
    setFieldValue("Country", selectedAddress);
    setFieldValue("State", selectedAddress);
    setFieldValue("City", selectedAddress);

    try {
      const results = await geocodeByAddress(selectedAddress);
      // const { lat, lng } = await getLatLng(results[0]);

      setAddress(selectedAddress);
      const addressComponents = results[0].address_components;

      const cityComponent = addressComponents.find((component) =>
        component.types.includes("locality")
      );
      const stateComponent = addressComponents.find((component) =>
        component.types.includes("administrative_area_level_1")
      );
      const countryComponent = addressComponents.find((component) =>
        component.types.includes("country")
      );

      setCity(cityComponent?.long_name || "");
      setState(stateComponent?.long_name || "");
      setCountry(countryComponent?.long_name || "");

      handleChange({
        target: {
          name: "City",
          value: cityComponent?.long_name || "",
        },
      });
      handleChange({
        target: {
          name: "State",
          value: stateComponent?.long_name || "",
        },
      });
      handleChange({
        target: {
          name: "Country",
          value: countryComponent?.long_name || "",
        },
      });
    } catch (error) {
      console.error(error);
    }
  };

  const {
    values,
    handleBlur,
    handleChange,
    touched,
    handleSubmit,
    setFieldValue,
    errors,
    // setTouched,
  } = useFormik({
    initialValues: initialValues,
    validationSchema: PostaJobSchemas,
    // Address: address,
    onSubmit,
  });
  console.log(errors);

  return (
    <>
      <Navbar2 />
      <section className="PostJob-main-section">
        <form onSubmit={handleSubmit} className="PostJob-main-div88">
          <div className="post-job-single-div">
            <span className="Post-a-Job-span">Post a Job</span>
          </div>
          <div className="all-input-feild-div">
            <div className="three-feild-main250-div">
              <div>
                <div className="text-feild-label-div">
                  <label htmlFor="" className="text-feild-label">
                    Requirement Title
                  </label>
                  <input
                    type="text"
                    className="text-feild-label-input"
                    placeholder="Write here"
                    name="Writehere"
                    value={values.Writehere}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                </div>
                {errors.Writehere && touched.Writehere ? (
                  <p className="mastu-to-eroor mt-2">{errors.Writehere} </p>
                ) : null}
              </div>
              <div className="total-time-div">
                <div>
                  <div className="text-feild-label-div">
                    <label htmlFor="" className="text-feild-label">
                      Total Hours
                    </label>
                    <input
                      type="number"
                      className="text-feild-label-input12"
                      placeholder="30"
                      name="TotalHours"
                      value={values.TotalHours}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                  </div>
                  {errors.TotalHours && touched.TotalHours ? (
                    <p className="mastu-to-eroor mt-2">{errors.TotalHours} </p>
                  ) : null}
                </div>
                <div>
                  <div className="text-feild-label-div">
                    <label htmlFor="" className="text-feild-label">
                      Time Duration
                    </label>
                    <input
                      type="text"
                      className="text-feild-label-input12"
                      placeholder="1 Week"
                      name="TimeDuration"
                      value={values.TimeDuration}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                  </div>
                  {errors.TimeDuration && touched.TimeDuration ? (
                    <p className="mastu-to-eroor mt-2">
                      {errors.TimeDuration}{" "}
                    </p>
                  ) : null}
                </div>
              </div>
            </div>
            <div className="three-feild-main250-div">
              <div className="post-job-omg-div">
                <div>
                  <div className="text-feild-label-div">
                    <label htmlFor="" className="text-feild-label">
                      Hourly Rate
                    </label>
                    <input
                      type="number"
                      className="text-feild-label-input-90"
                      placeholder="$ 40"
                      name="HourlyRate"
                      value={values.HourlyRate}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                  </div>
                  {errors.HourlyRate && touched.HourlyRate ? (
                    <p className="mastu-to-eroor mt-2">{errors.HourlyRate} </p>
                  ) : null}
                </div>
                <div className="To-span-to-one">
                  <span>to</span>
                </div>
                <div>
                  <div className="text-feild-label-div">
                    <label htmlFor="" className="text-feild-label">
                      Hourly Rate
                    </label>
                    <input
                      type="number"
                      className="text-feild-label-input-90"
                      placeholder="$ 50"
                      name="HourlyRateto"
                      value={values.HourlyRateto}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                  </div>
                  {errors.HourlyRateto && touched.HourlyRateto ? (
                    <p className="mastu-to-eroor mt-2">
                      {errors.HourlyRateto}{" "}
                    </p>
                  ) : null}
                </div>
              </div>
              <div>
                <div className="text-feild-label-div">
                  <label htmlFor="" className="text-feild-label">
                    Experience In
                  </label>

                  <select
                    name="ExperienceIn"
                    id=""
                    className="text-feild-label-input1212 custom-select1"
                    placeholder="Select"
                    value={values.ExperienceIn}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  >
                    {" "}
                    <option value="" disabled selected hidden>
                      Select
                    </option>
                    {specialist.map((item, index) => {
                      return (
                        <option key={index} value={item.id}>
                          {item.speciallityName}
                        </option>
                      );
                    })}
                  </select>
                </div>
                {errors.ExperienceIn && touched.ExperienceIn ? (
                  <p className="mastu-to-eroor mt-2">{errors.ExperienceIn} </p>
                ) : null}
              </div>
            </div>
            <div className="three-feild-main250-div">
              <div>
                <div className="text-feild-label-div">
                  <label htmlFor="" className="text-feild-label">
                    Address
                  </label>
                  <PlacesAutocomplete
                    value={address}
                    onChange={handleAddressChange}
                    onSelect={handleSelect}
                    onBlur={handleAddressBlur}
                  >
                    {({
                      getInputProps,
                      suggestions,
                      getSuggestionItemProps,
                      loading,
                    }) => (
                      <div>
                        <input
                          className="text-feild-label-input1212 custom-select1"
                          {...getInputProps({ placeholder: "Enter address" })}
                        />
                        <div>
                          {loading ? <div>Loading...</div> : null}
                          {suggestions.map((suggestion) => {
                            const style = {
                              backgroundColor: suggestion.active
                                ? "#41b6e6"
                                : "#fff",
                            };
                            return (
                              <div
                                {...getSuggestionItemProps(suggestion, {
                                  style,
                                })}
                              >
                                {suggestion.description}
                              </div>
                            );
                          })}
                        </div>
                      </div>
                    )}
                  </PlacesAutocomplete>
                </div>
                {errors.Address && touched.Address ? (
                  <p className="mastu-to-eroor mt-2">{errors.Address} </p>
                ) : null}
              </div>
              <div>
                <div className="text-feild-label-div">
                  <label htmlFor="" className="text-feild-label">
                    Country
                  </label>
                  <input
                    type="text"
                    name="Country"
                    id=""
                    className="text-feild-label-input1212 custom-select1"
                    placeholder="Select"
                    value={values.Country}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                </div>
                {errors.Country && touched.Country ? (
                  <p className="mastu-to-eroor mt-2">{errors.Country} </p>
                ) : null}
              </div>
            </div>
            <div className="three-feild-main250-div">
              <div>
                <div className="text-feild-label-div">
                  <label htmlFor="" className="text-feild-label">
                    State
                  </label>

                  <input
                    type="text"
                    name="State"
                    id=""
                    className="text-feild-label-input1212 custom-select1"
                    placeholder="Select"
                    value={values.State}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                </div>
                {errors.State && touched.State ? (
                  <p className="mastu-to-eroor mt-2">{errors.State} </p>
                ) : null}
              </div>
              <div>
                <div className="text-feild-label-div">
                  <label htmlFor="" className="text-feild-label">
                    City
                  </label>

                  <input
                    type="text"
                    name="City"
                    id=""
                    className="text-feild-label-input1212 custom-select1"
                    placeholder="Select"
                    value={values.City}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                </div>
                {errors.City && touched.City ? (
                  <p className="mastu-to-eroor mt-2">{errors.City} </p>
                ) : null}
              </div>
            </div>
            <div>
              <div className="text-feild-label-div">
                <label htmlFor="" className="text-feild-label">
                  Description
                </label>
                <textarea
                  name="Description"
                  id=""
                  cols="30"
                  rows="10"
                  placeholder="Write here"
                  className="text-area-field20"
                  value={values.Description}
                  onChange={handleChange}
                  onBlur={handleBlur}
                ></textarea>
              </div>
              {errors.Description && touched.Description ? (
                <p className="mastu-to-eroor mt-2">{errors.Description} </p>
              ) : null}
            </div>
            <div>
              <button type="submit" className="Post-Job-button">
                Post Job
              </button>
            </div>
          </div>
        </form>
      </section>
      <Footer2 />
    </>
  );
};

export default PostJob;
