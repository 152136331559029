import React, { useState, useEffect } from "react";
import "../Css/Mybooking.css";
// import jennyimg from "../Images/Jennyimg.png";
// import starimg from "../Images/starimg.png";
// import Dianne from "../Images/Dianne.png";
// import Marvin from "../Images/Marvin.png";
// import Jecob from "../Images/Jecob.png";
import axios from "../schemas/Api";
import ssstar from "../Images/ssstar.png";
import ksstar from "../Images/ksstar.png";
import Mybooking2 from "./MyBooking2";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

// import { CListGroup } from "@coreui/react";

const MyBooking = () => {
  const [upcoming, setUpcoming] = useState([]);
  const [active, setActive] = useState(0);
  const data = JSON.parse(localStorage.getItem("Nezziedata"));
  // console.log(data.token);

  // console.log(upcoming);
  useEffect(() => {
    upcomingData();
  }, []);
  const upcomingData = async () => {
    try {
      const response = await axios.post(
        "bookings/my_Bookingslist",
        {
          status: "Upcoming",
        },
        {
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            Authorization: data.token,
          },
        }
      );
      const upcomingWithCancellable = response.data.data.map((item) => ({
        ...item,
        cancellable: true, // Set this value based on your logic
      }));
      setUpcoming(upcomingWithCancellable);
      // setUpcoming(response.data.data);
      console.log(response);
    } catch (error) {
      console.log("Error", error);
    }
  };
  // Cnacel Booking Api
  const handleCancelBooking = async (_id) => {
    try {
      const resp = await axios.post(
        "bookings/cancelledAppointmen",
        {
          _id: _id,
        },
        {
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            Authorization: data.token,
          },
        }
      );
      // if (resp.status === 200 && resp.data && resp.data.success) {
      //   toast.success("Appointment Cancelled Successfully");
      // }
      if (resp.data.message === "Appointment Cancelled Successfully") {
        toast.success(resp.data.message, {
          position: "top-right",
          theme: "colored",
        });
      }
      upcomingData();
      console.log(resp);
    } catch (error) {
      console.log("Error", error);
      toast.error(error.resp.data.message, {
        position: "top-right",
        theme: "colored",
      });
    }
  };
  // const bookingdata = [
  //   {
  //     Img1: jennyimg,
  //     Name1: "Jenny Wilson",
  //     Price1: "$ 200",
  //   },
  //   {
  //     Img1: Jecob,
  //     Name1: "jecob Jones",
  //     Price1: "$ 200",
  //   },

  //   {
  //     Img1: Dianne,
  //     Name1: "Dianne",
  //     Price1: "$ 200",
  //   },
  //   {
  //     Img1: Marvin,
  //     Name1: "Marvin ",
  //     Price1: "$ 200",
  //   },
  // ];

  const btns = [
    {
      id: 1,
      name: "Upcoming",
    },
    {
      id: 2,
      name: "Completed",
    },
  ];
  return (
    <>
      <section className="mybooking-main-section">
        <div className="mybooking-main-div">
          <div className="mybooking-single11-div">
            <span className="My-Bookings-span">My Bookings</span>
          </div>
          <div className="Upcoming-btn-Completed">
            {/* <button className="Upcoming-button">Upcoming</button>
            <button className="completed-button">Completed</button> */}
            {btns.map((item, i) => {
              return (
                <>
                  <button
                    className={
                      active === i ? "Upcoming-button" : "completed-button"
                    }
                    onClick={() => setActive(i)}
                  >
                    {item.name}
                  </button>
                </>
              );
            })}
          </div>
          {active === 0 ? (
            <>
              <div className="main-div-of-card23">
                {upcoming.map((item1, index) => {
                  /* console.log(item1); */

                  let Ratingcount = item1.review_total_count;
                  let MaxRate = 5;
                  let yellowstar = Math.min(Ratingcount, MaxRate);
                  let RatingcountImage = Array.from(
                    { length: yellowstar },
                    (_, i) => <img key={i} src={ssstar} alt="" />
                  );
                  let GrayStarCount = MaxRate - yellowstar;
                  let GrayStarImage = Array.from(
                    { length: GrayStarCount },
                    (_, i) => <img key={i + yellowstar} src={ksstar} alt="" />
                  );
                  let starRatingimages = [
                    ...RatingcountImage,
                    ...GrayStarImage,
                  ];
                  return (
                    <div className="single-card-div44" key={index}>
                      <div className="inner-div-card-content">
                        <div className="ma-coduy-hu">
                          <div>
                            <img
                              src={item1.image}
                              alt=""
                              className="jenny-img-img"
                            />
                          </div>
                          <div className="name-span-text-div">
                            <div>
                              <span className="name-span13">
                                {item1.advocateName}
                              </span>
                            </div>
                            <div className="starimage-div">
                              {starRatingimages.map((item1, name) => {
                                return (
                                  <img
                                    key={name}
                                    src={item1.props.src}
                                    alt=""
                                    className="star-img-90"
                                  />
                                );
                              })}
                              <span className="image-review12-span">
                                (0 Reviews)
                              </span>
                            </div>
                            <div className="">
                              <span className="dollar-t-span">$</span>
                              <span className="dolar-span-span1">
                                {item1.rate}
                              </span>
                              <span className="per-hour44-span">
                                {" "}
                                / per hour
                              </span>
                            </div>
                          </div>
                          <div className="chat-logo-btn">
                            <svg
                              className="msg-svg-1"
                              width="24"
                              height="24"
                              viewBox="0 0 24 24"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <g clip-path="url(#clip0_2_925)">
                                <path
                                  d="M7.29101 20.824L2.00001 22L3.17601 16.709C2.40154 15.2604 1.99754 13.6426 2.00001 12C2.00001 6.477 6.47701 2 12 2C17.523 2 22 6.477 22 12C22 17.523 17.523 22 12 22C10.3574 22.0025 8.73963 21.5985 7.29101 20.824V20.824ZM7.58101 18.711L8.23401 19.061C9.39256 19.6801 10.6864 20.0027 12 20C13.5823 20 15.129 19.5308 16.4446 18.6518C17.7602 17.7727 18.7855 16.5233 19.391 15.0615C19.9965 13.5997 20.155 11.9911 19.8463 10.4393C19.5376 8.88743 18.7757 7.46197 17.6569 6.34315C16.538 5.22433 15.1126 4.4624 13.5607 4.15372C12.0089 3.84504 10.4004 4.00346 8.93854 4.60896C7.47674 5.21447 6.22731 6.23984 5.34825 7.55544C4.4692 8.87103 4.00001 10.4177 4.00001 12C4.00001 13.334 4.32501 14.618 4.94001 15.766L5.28901 16.419L4.63401 19.366L7.58101 18.711V18.711Z"
                                  fill="#006EBD"
                                />
                              </g>
                              <defs>
                                <clipPath id="clip0_2_925">
                                  <rect width="24" height="24" fill="white" />
                                </clipPath>
                              </defs>
                            </svg>
                            <button className="upcoming-button-19">
                              Pending
                            </button>
                          </div>
                        </div>

                        <div className="date-time-main-div">
                          <div className="time-dchild-div">
                            <span className="date12-span">Date</span>
                            <span className="jun5-span">
                              {item1.bookedDate}
                            </span>
                          </div>
                          <div className="time-dchild-div">
                            <span className="date12-span">Time</span>
                            <span className="jun5-span">
                              {item1.bookedTime}
                            </span>
                          </div>
                        </div>
                        <div className="button-time-div">
                          {item1.cancellable ? (
                            <button
                              className="meeting-button"
                              onClick={() => handleCancelBooking(item1._id)}
                            >
                              Cancel Booking
                            </button>
                          ) : (
                            <button className="meeting-button">
                              Meeting Start in 5:30Mins
                            </button>
                          )}
                          {/* <button className="meeting-button">
                            Meeting Start in 5:30Mins
                          </button> */}
                        </div>
                      </div>
                    </div>
                  );
                })}
              </div>
            </>
          ) : (
            <Mybooking2 />
          )}
        </div>
      </section>
    </>
  );
};

export default MyBooking;
