// import React, { useState, useRef } from "react";
// import "../Css/Post2.css";
// // import New from "./New";
// import LostLuggage from "./LostLuggage";
// import { FiArrowUpRight } from "react-icons/fi";
// // import { useEffect } from "react";
// // import axios from "../schemas/APi1";
// import { TravelSchemas } from "../schemas";
// import { useFormik } from "formik";
// // import { toast } from "react-toastify";
// // import "react-toastify/dist/ReactToastify.css";
// // import Housing2 from "./Housing2";
// import OnlineTransaction from "./OnlineTransaction";
// import TourOperator from "./TourOperator";
// import Travelinsurance from "./TravelInsurance";
// const initialValues = {
//   rate: "",
// };
// const TraveleToursim = () => {
//   const containerRef = useRef(null);
//   const [isDragging, setIsDragging] = useState(false);
//   const [startX, setStartX] = useState(null);

//   const handleMouseDown = (e) => {
//     setIsDragging(true);
//     setStartX(e.clientX - containerRef.current.scrollLeft);
//   };

//   const handleMouseUp = () => {
//     setIsDragging(false);
//   };

//   const handleMouseMove = (e) => {
//     if (!isDragging) return;

//     const x = e.clientX - startX;
//     containerRef.current.scrollLeft = x;
//   };

//   const [bdata, setbData] = useState(0);

//   const btns = [
//     {
//       id: "1",
//       Name: "Travel Booking Disputes",
//     },
//     {
//       id: "2",
//       Name: "Lost Luggage Claims",
//     },
//     {
//       id: "3",
//       Name: "Tour Operator Misrepresentation",
//     },
//     {
//       id: "4",
//       Name: "Travel Insurance Issues",
//     },
//   ];

//   const {
//     values,
//     handleBlur,
//     handleChange,
//     touched,
//     // handleSubmit,
//     // setFieldValue,
//     errors,
//     // setTouched,
//   } = useFormik({
//     initialValues: initialValues,
//     validationSchema: TravelSchemas,
//     // Address: address,
//     // onSubmit,
//   });

//   return (
//     <>
//       <div className="second-post-main-section">
//         <div className="second-post-child-div">
//           <span className="customer-span-span98">Travel and Tourism</span>
//         </div>

//         <div
//           ref={containerRef}
//           className="both-categories-90-div"
//           onMouseDown={handleMouseDown}
//           onMouseUp={handleMouseUp}
//           onMouseMove={handleMouseMove}
//         >
//           {btns.map((item, i) => {
//             return (
//               <>
//                 <button
//                   className={
//                     bdata === i
//                       ? "Product-Issues-button"
//                       : "Product-Issues-button-unactive"
//                   }
//                   key={i}
//                   onClick={() => setbData(i)}
//                 >
//                   {item.Name}
//                 </button>
//               </>
//             );
//           })}
//         </div>
//         {bdata === 0 ? (
//           <>
//             <div className="sub-categories-child-divs">
//               <div className="react-icon-div56">
//                 <span className="subcategories-span-00">
//                   Craft Anynomus Help Request
//                 </span>
//                 <FiArrowUpRight size={24} />
//               </div>
//               <div className="react-icon-div56">
//                 <span className="subcategories-span-00">Travel</span>
//                 <FiArrowUpRight size={24} />
//               </div>
//               {/* ))} */}

//               {/* <div className="boirder-div-che78"></div> */}
//             </div>
//             <div className="How-much-spend-div">
//               <span className="subcategories-span-00-00">
//                 How much spend you in this topic?
//               </span>
//               <div className="input-container-909">
//                 <input
//                   type="number"
//                   className="categories-input"
//                   // placeholder="$"
//                   name="rate"
//                   value={values.rate}
//                   onChange={handleChange}
//                   onBlur={handleBlur}
//                 />
//                 <span class="placeholder-text12">$</span>
//               </div>
//               {errors.rate && touched.rate ? (
//                 <p className="mastu-to-eroor mt-2">{errors.rate} </p>
//               ) : null}
//             </div>
//             <div>
//               <button className="new-post0button-09">Post</button>
//             </div>
//           </>
//         ) : bdata === 1 ? (
//           <LostLuggage />
//         ) : bdata === 2 ? (
//           <TourOperator />
//         ) : bdata === 3 ? (
//           <Travelinsurance />
//         ) : null}
//       </div>
//       <div className="main-post-linehg"></div>
//     </>
//   );
// };

// export default TraveleToursim;
import React, { useState, useEffect } from "react";
import "../Css/Post2.css";
// import New from "./New";
// import { FiArrowUpRight } from "react-icons/fi";
// import { useEffect } from "react";
import axios from "../schemas/APi1";
import { Post2Schemas } from "../schemas";
import { useFormik } from "formik";
// import { toast } from "react-toastify";
// import "react-toastify/dist/ReactToastify.css";
// import ServiceComplaints from "./ServiceComplaints";
// import OnlineTransaction from "./OnlineTransaction";
// import OnlineTransaction from "./OnlineTransaction";
import TourOperator from "./TourOperator";
import Travelinsurance from "./TravelInsurance";
import LostLuggage from "./LostLuggage";
import { useContext } from "react";
import { Globalcontext } from "../Context/GlobalContext";

const initialValues = {
  rate: "",
  Writehere: "",
};
const Post2 = () => {
  const [bdata, setbData] = useState(0);
  const { context, setContext } = useContext(Globalcontext);
  const [newdata, setnewData] = useState(0);
  const [listdata, setListdata] = useState([]);
  const data = JSON.parse(localStorage.getItem("Nezziedata"));

  useEffect(() => {
    specilaitydata();
  }, []);
  const specilaitydata = async () => {
    try {
      const response = await axios.post(
        "meta_data/speciallityList",
        {},
        {
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            Authorization: data.token,
          },
        }
      );
      setListdata(response.data.data);
      console.log(response);
    } catch (error) {
      console.log("Error", error);
    }
  };
  const btns = [
    {
      id: "1",
      Name: "Travel Booking Disputes",
    },
    {
      id: "2",
      Name: "Lost Luggage Claims",
    },
    {
      id: "3",
      Name: "Tour Operator Misrepresentation",
    },
    {
      id: "4",
      Name: "Travel Insurance Issues",
    },
  ];
  const btns1 = [
    {
      id: "1",
      Name: "Incorrect Reservations or Bookings",
    },
    {
      id: "2",
      Name: "Cancellation and Refund Disputes",
    },
    {
      id: "3",
      Name: "Overbooking Issues",
    },
    {
      id: "4",
      Name: "Coverage Disputes for Trip Cancellation",
    },
  ];
  const {
    values,
    handleBlur,
    handleChange,
    touched,
    // handleSubmit,
    // setFieldValue,
    errors,
    // setTouched,
  } = useFormik({
    initialValues: initialValues,
    validationSchema: Post2Schemas,
    // Address: address,
    // onSubmit,
  });
  const handlemodal = (i) => {
    setbData(i);
    setContext(false);
  };
  const handlemodal2 = (i) => {
    setnewData(i);
    setContext(true);
  };
  return (
    <>
      <div className="second-post-main-section">
        <div className="second-post-child-div">
          <span className="customer-span-span98">Travel and Tourism</span>
        </div>

        <div className="both-categories-90-div">
          {btns.map((item, i) => {
            return (
              <>
                <button
                  className={
                    bdata === i
                      ? "Product-Issues-button"
                      : "Product-Issues-button-unactive"
                  }
                  key={i}
                  onClick={() => handlemodal(i)}
                >
                  {item.Name}
                </button>
              </>
            );
          })}
        </div>
        {bdata === 0 ? (
          <>
            <div className="char-button-div">
              {btns1.map((item, i) => {
                return (
                  <>
                    <button
                      // className="Defective-Product-button"
                      className={
                        newdata === i
                          ? "Product-Issues-button22"
                          : "Product-Issues-button-unactive22"
                      }
                      key={i}
                      onClick={() => handlemodal2(i)}
                    >
                      {item.Name}
                    </button>
                  </>
                );
              })}
            </div>
          </>
        ) : bdata === 1 ? (
          <LostLuggage />
        ) : bdata === 2 ? (
          <TourOperator />
        ) : bdata === 3 ? (
          <Travelinsurance />
        ) : null}
        <div>
          <button className="Craft-Anynomus-Help-Request">
            Craft Anynomus Help Request
          </button>
        </div>
        {context ? null : (
          <div className="How-much-spend-div">
            <span className="subcategories-span-00-00">
              Craft Anynomus Help Request
            </span>
            <div className="input-container-909">
              <input
                type="text"
                className="categories-input-909"
                placeholder="Write here..."
                name="Writehere"
                value={values.Writehere}
                onChange={handleChange}
                onBlur={handleBlur}
              />
            </div>
            {errors.Writehere && touched.Writehere ? (
              <p className="mastu-to-eroor mt-2">{errors.Writehere} </p>
            ) : null}
          </div>
        )}
        <div className="How-much-spend-div">
          <span className="subcategories-span-00-00">
            How much spend you in this topic?
          </span>

          <div className="input-container-909">
            <input
              type="number"
              className="categories-input"
              // placeholder="$"
              name="rate"
              value={values.rate}
              onChange={handleChange}
              onBlur={handleBlur}
            />
            <span class="placeholder-text12">$</span>
          </div>

          {errors.rate && touched.rate ? (
            <p className="mastu-to-eroor mt-2">{errors.rate} </p>
          ) : null}
        </div>
        <div>
          <button className="new-post0button-09">Post</button>
        </div>
      </div>
      <div className="main-post-linehg"></div>
    </>
  );
};

export default Post2;
