import React, { useState, useEffect } from "react";
import "../Css/Appoinment3.css";
// import round3 from "../Images/R2.png";
import penaimg from "../Images/Ad2.png";
import starimg from "../Images/starimg.png";
import msg from "../Images/msg.png";
import sstarimg from "../Images/Onestar.png";
import foximg from "../Images/Ad21.png";
import mkinny from "../Images/Ad22.png";
import axios from "../schemas/APi1";
import { GrStar } from "react-icons/gr";
import Modal from "react-bootstrap/Modal";
import Rateuser from "../Images/Rateuser.png";

const Appoinment3 = () => {
  const [selected, setSelected] = useState(0);

  const [completed1, setCompleted1] = useState([]);
  useEffect(() => {
    completedData();
  }, []);
  const StarGenerator = (numofstar = 5) => {
    // console.log(selected, "seleted");
    return Array(numofstar)
      .fill()
      .map((item, i) => (
        <Star
          key={i}
          selected={selected > i}
          onSelected={() => setSelected(i + 1)}
        />
      ));
  };
  const Star = ({ selected, onSelected }) => {
    return (
      <GrStar
        className="fastar-mnb"
        color={selected ? "goldenrod" : "gray"}
        borderColor={selected ? "red" : "goldenrod"}
        onClick={onSelected}
      />
    );
  };
  const data = JSON.parse(localStorage.getItem("Nezziedata"));

  const completedData = async () => {
    try {
      const res = await axios.post(
        "appointment/getappointment",
        {
          status: "Completed",
        },
        {
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            Authorization: data.token,
          },
        }
      );
      setCompleted1(res.data.data);
      console.log(res);
    } catch (error) {
      console.log("Error", error);
    }
  };
  const Appoinment3data = [
    {
      img6: penaimg,
      Name6: "Eleanor Pena",
    },
    {
      img6: foximg,
      Name6: "Robert Fox",
    },
    {
      img6: mkinny,
      Name6: "Eleanor Pena",
    },
    {
      img6: foximg,
      Name6: "Eleanor Pena",
    },
    {
      img6: foximg,
      Name6: "Eleanor Pena",
    },
    {
      img6: foximg,
      Name6: "Eleanor Pena",
    },
    {
      img6: foximg,
      Name6: "Eleanor Pena",
    },
    {
      img6: foximg,
      Name6: "Eleanor Pena",
    },
    {
      img6: foximg,
      Name6: "Eleanor Pena",
    },
  ];
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  return (
    <section className="Appoinment3-main23-section">
      <div className="Appoinment3-main23-div50">
        {/* <div className="My-Appointments-span67-div">
          <span className="Dash-board-span05">Dash board</span>
          <span className="My-Appointments-span67">My Appointments</span>
        </div>
        <div className="new-upcoming-completed-div">
          <button className="new-button55">
            New <img src={round3} alt="" className="round3-img55" />
          </button>
          <button className="Upcoming-button55">Upcoming</button>
          <button className="Completed-button55">Completed</button>
        </div> */}
        <div className="map-papa-div96 ">
          {Appoinment3data.map((item6, index) => {
            return (
              <div className="single-card-hw-div" key={index}>
                <div className="inner-single-card-hw-div">
                  <div className="img-star-div33">
                    <div>
                      <img src={item6.img6} alt="" className="item6-img6-66" />
                    </div>
                    <div className="star-img-div-div44">
                      <span className="item6-span6">{item6.Name6}</span>
                      <div className="span233-span234-reveiw-div">
                        <img src={starimg} alt="" className="star-img-oop" />
                        {/* <span className="span233-span234-reveiw">
                          ( <span className="span233-span234">233</span>{" "}
                          Reviews)
                        </span> */}
                      </div>
                    </div>
                    <div className="messagelogo780-div">
                      <img src={msg} alt="" className="messagelogo780-logo" />
                    </div>
                  </div>

                  <div className="display-div666">
                    <div className="display-flex-div-div12">
                      <span className="display-flex-span-span">Date</span>
                      <span className="jun-span-span1-span">5 Jan 2023</span>
                    </div>
                    <div className="display-flex-div-div12">
                      <span className="display-flex-span-span">Time</span>
                      <span className="jun-span-span1-span">
                        2:00 pm to 3:00 pm
                      </span>
                    </div>
                  </div>
                  <div className="rate-or-button-div">
                    <div className="rate-this-span-div098" onClick={handleShow}>
                      {/* <span className="rate-this-span">Rated</span> */}
                      <span className="rate-this-span-mene-add-kiya">
                        Rate this Client
                      </span>

                      {/* <img src={sstarimg} alt="" className="sstarimg-img" />
                      <span className="span-4-0">4.0</span> */}
                    </div>
                    <Modal show={show} onHide={handleClose}>
                      <Modal.Header closeButton>
                        <span className="Customer-Rate-this">
                          Rate this Customer
                        </span>
                      </Modal.Header>
                      <div className="model-contain">
                        <div className="Rate-user-name">
                          <img className="Rateuser-img" src={Rateuser} alt="" />
                          <span>Jacob Jones</span>
                        </div>
                        <div className="mb-3">{StarGenerator(5)}</div>

                        <div className="mb-4">
                          <button className="Submit-btn">Submit</button>
                        </div>
                      </div>
                    </Modal>
                    <div className="completed-button88-div">
                      <button className="completed-button88">Completed</button>
                    </div>
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </section>
  );
};

export default Appoinment3;
