// import React, { useEffect, useState, useRef } from "react";
// import "../Css/Post2.css";
// import Housing2 from "./Housing2";
// import { FiArrowUpRight } from "react-icons/fi";
// import axios from "../schemas/APi1";
// import { useFormik } from "formik";
// import { HousingSchemas } from "../schemas";
// import { toast } from "react-toastify";
// import "react-toastify/dist/ReactToastify.css";
// import Landlord from "./Landlord";
// import Violations from "./Violations";
// const initialValues = {
//   rate: "",
// };
// const Housing = () => {
//   const containerRef = useRef(null);
//   const [isDragging, setIsDragging] = useState(false);
//   const [startX, setStartX] = useState(null);

//   const handleMouseDown = (e) => {
//     setIsDragging(true);
//     setStartX(e.clientX - containerRef.current.scrollLeft);
//   };

//   const handleMouseUp = () => {
//     setIsDragging(false);
//   };

//   const handleMouseMove = (e) => {
//     if (!isDragging) return;

//     const x = e.clientX - startX;
//     containerRef.current.scrollLeft = x;
//   };

//   const [bhaidata, setBhaidata] = useState([]);
//   const [bhaidata2, setBhaidata2] = useState([]);
//   const [savedata, setSavedata] = useState(null);
//   // console.log(savedata);

//   const [showValidationMessage, setShowValidationMessage] = useState(false);

//   const [selectedSubcategoryIndex, setSelectedSubcategoryIndex] =
//     useState(null);
//   // speciality list usestate
//   // console.log(bhaidata, "bhaidata");
//   const [metadata, setmetaData] = useState([]);
//   // console.log(metadata._id, "metadata");

//   const data = JSON.parse(localStorage.getItem("Nezziedata"));

//   const handleSubcategoryClick = (subcategory, index) => {
//     console.log("Selected Subcategory");
//     setSavedata(subcategory);
//     if (subcategory.subCat2 === "Other") {
//       // Handle the "Other" option separately here
//       setSelectedSubcategoryIndex("Other");
//       console.log("Selected Subcategory: Other");
//     } else {
//       setSelectedSubcategoryIndex(index);
//       const selectedSubcategory = bhaidata2[0]?.subcat_2datas[index];
//       console.log("Selected Subcategory:", selectedSubcategory);
//     }
//   };
//   const handleOncolor = () => {
//     setSelectedSubcategoryIndex("Other");
//     setSavedata("Other");
//   };
//   //specilist api integrated at here

//   useEffect(() => {
//     metadataspecial();
//   }, []);

//   const metadataspecial = async () => {
//     try {
//       const resp = await axios.post(
//         "meta_data/speciallityList",
//         {},
//         {
//           headers: {
//             "Content-Type": "application/json",
//             Accept: "application/json",
//             Authorization: data.token,
//           },
//         }
//       );
//       setmetaData(resp.data.data[1]);
//       console.log(resp);
//     } catch (error) {
//       console.log("Error", error);
//     }
//   };
//   //specialist data  api integrated at here
//   // useEffect(() => {
//   //   brotherData();
//   // }, []);
//   useEffect(() => {
//     brotherData(metadata._id);
//   }, [metadata._id]);

//   const brotherData = async (speciallityId) => {
//     try {
//       const response = await axios.post(
//         "meta_data/speciallity_data",
//         { speciallityId: speciallityId },
//         {
//           headers: {
//             "Content-Type": "application/json",
//             Accept: "application/json",
//             Authorization: data.token,
//           },
//         }
//       );
//       console.log(response);
//       setBhaidata(response.data.data[1]);
//       setBhaidata2(response.data.data[0]);
//     } catch (error) {
//       console.log("Error", error);
//     }
//   };
//   // console.log(bhaidata);
//   // ...add post api integreated at here

//   const addpost = async () => {
//     try {
//       if (!values.rate) {
//         setShowValidationMessage(true);
//         return;
//       }
//       let otherpostValue = 0; // Default value

//       // Check if the user selected "Other"
//       if (selectedSubcategoryIndex === "Other") {
//         otherpostValue = 1;
//       }
//       const res = await axios.post(
//         "https://admin.nezzie.app/api/user/post/addPost",
//         {
//           rate: values.rate,
//           SpeciallityId: savedata.speciallityId,
//           subCategoryId1: savedata.subCat1Id,
//           subCategoryId2: "",
//           otherpost: otherpostValue,
//         },
//         {
//           headers: {
//             "Content-Type": "application/json",
//             Accept: "application/json",
//             Authorization: data?.token,
//           },
//         }
//       );
//       console.log(res);
//       if (res.data.message === "Post Added Successfully") {
//         toast.success(res.data.message, {
//           position: "top-right",
//           theme: "colored",
//         });
//         setSelectedSubcategoryIndex(null);
//         // specailchizData();
//         values.rate = "";
//       }
//     } catch (error) {
//       console.log("Error", error);
//       toast.error(error.res.data.message, {
//         position: "top-right",
//         theme: "colored",
//       });
//     }
//   };

//   const [bdata, setbData] = useState(0);
//   const btns = [
//     {
//       id: "1",
//       Name: "Rental Agreements",
//     },
//     {
//       id: "2",
//       Name: "Property Purchase Disputes",
//     },
//     {
//       id: "3",
//       Name: "Landlord-Tenant Issues",
//     },
//     {
//       id: "4",
//       Name: "Housing Code Violations",
//     },
//   ];
//   const {
//     values,
//     handleBlur,
//     handleChange,
//     touched,
//     // handleSubmit,
//     // setFieldValue,
//     errors,
//     // setTouched,
//   } = useFormik({
//     initialValues: initialValues,
//     validationSchema: HousingSchemas,
//     // Address: address,
//     // onSubmit,
//   });
//   useEffect(() => {
//     setSelectedSubcategoryIndex("Other"); // Set "Other" as the initially selected subcategory
//   }, []);

//   return (
//     <div className="second-post-main-section">
//       <div className="second-post-child-div">
//         <span className="customer-span-span98">Housing</span>
//       </div>
//       <div
//         ref={containerRef}
//         className="both-categories-90-div"
//         onMouseDown={handleMouseDown}
//         onMouseUp={handleMouseUp}
//         onMouseMove={handleMouseMove}
//       >
//         {btns.map((item, i) => {
//           return (
//             <>
//               <button
//                 className={
//                   bdata == i
//                     ? "Product-Issues-button"
//                     : "Product-Issues-button-unactive"
//                 }
//                 key={i}
//                 onClick={() => setbData(i)}
//               >
//                 {item.Name}
//               </button>
//             </>
//           );
//         })}
//       </div>

//       {bdata === 0 ? (
//         <>
//           <div className="sub-categories-child-divs">
//             <div
//               className={
//                 selectedSubcategoryIndex === "Other"
//                   ? "react-icon-div56 selected-martin"
//                   : "react-icon-div56"
//               }
//               onClick={() => handleOncolor()}
//             >
//               <span
//                 className="subcategories-span-00"
//                 style={{
//                   color: selectedSubcategoryIndex === "Other" && "#006ebd",
//                   // backgroundColor:
//                   //   selectedSubcategoryIndex === "Other" && "#006ebd",
//                 }}
//               >
//                 Craft Anynomus Help Request
//               </span>
//               <FiArrowUpRight
//                 size={24}
//                 style={{
//                   color: selectedSubcategoryIndex === "Other" && "#006ebd",
//                 }}
//               />
//             </div>
//             {bhaidata2?.subcat_2datas?.map((subcategory, index) => (
//               <div
//                 key={index}
//                 className={
//                   selectedSubcategoryIndex === index
//                     ? "react-icon-div56 selected-martin"
//                     : "react-icon-div56"
//                 }
//                 onClick={() => handleSubcategoryClick(subcategory, index)}
//               >
//                 <span
//                   className="subcategories-span-00"
//                   style={{
//                     color: selectedSubcategoryIndex === index && "#006ebd",
//                   }}
//                 >
//                   {subcategory.subCat2}
//                 </span>
//                 <FiArrowUpRight
//                   size={24}
//                   style={{
//                     color: selectedSubcategoryIndex === index && "#006ebd",
//                   }}
//                 />
//               </div>
//             ))}
//           </div>
//           <div className="How-much-spend-div">
//             <span className="subcategories-span-00-00">
//               How much do you want to spend on this topic?
//             </span>
//             <div className="input-container-909">
//               <input
//                 type="text"
//                 className="categories-input"
//                 // placeholder="$"
//                 name="rate"
//                 value={values.rate}
//                 onChange={handleChange}
//                 onBlur={handleBlur}
//               />
//               <span class="placeholder-text12">$</span>
//             </div>
//             {showValidationMessage && !values.rate && (
//               <p className="mastu-to-eroor mt-2">
//                 Required field can't be empty.
//               </p>
//             )}
//             {errors.rate && touched.rate ? (
//               <p className="mastu-to-eroor mt-2">{errors.rate} </p>
//             ) : null}
//           </div>
//           <div>
//             <button className="new-post0button-09" onClick={addpost}>
//               Post
//             </button>
//           </div>
//         </>
//       ) : bdata === 1 ? (
//         <Housing2 bhaidata={bhaidata} />
//       ) : bdata === 2 ? (
//         <Landlord bhaidata={bhaidata} />
//       ) : bdata === 3 ? (
//         <Violations bhaidata={bhaidata} />
//       ) : null}
//     </div>
//   );
// };

// export default Housing;
import React, { useState, useEffect } from "react";
import "../Css/Post2.css";
import New from "./New";
// import { FiArrowUpRight } from "react-icons/fi";
// import { useEffect } from "react";
import axios from "../schemas/APi1";
import { Post2Schemas } from "../schemas";
import { useFormik } from "formik";
// import { toast } from "react-toastify";
// import "react-toastify/dist/ReactToastify.css";
// import ServiceComplaints from "./ServiceComplaints";
// import OnlineTransaction from "./OnlineTransaction";
import Housing2 from "./Housing2";
import Landlord from "./Landlord";
import Violations from "./Violations";
import { useContext } from "react";
import { Globalcontext } from "../Context/GlobalContext";

const initialValues = {
  rate: "",
  Writehere: "",
};
const Post2 = () => {
  const [bdata, setbData] = useState(0);
  const { context, setContext } = useContext(Globalcontext);
  const [newdata, setnewData] = useState(0);
  const data = JSON.parse(localStorage.getItem("Nezziedata"));

  const [listdata, setListdata] = useState([]);
  useEffect(() => {
    specilaitydata();
  }, []);
  const specilaitydata = async () => {
    try {
      const response = await axios.post(
        "meta_data/speciallityList",
        {},
        {
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            Authorization: data.token,
          },
        }
      );
      setListdata(response.data.data);
      console.log(response);
    } catch (error) {
      console.log("Error", error);
    }
  };

  const btns = [
    {
      id: "1",
      Name: "Rental Agreements",
    },
    {
      id: "2",
      Name: "Property Purchase Disputes",
    },
    {
      id: "3",
      Name: "Landlord-Tenant Issues",
    },
    {
      id: "4",
      Name: "Housing Code Violations",
    },
  ];
  const btns1 = [
    {
      id: "1",
      Name: "Lease Terms and Conditions",
    },
    {
      id: "2",
      Name: "Rent Increases and Renewals",
    },
    {
      id: "3",
      Name: "Security Deposit Issues",
    },
    {
      id: "4",
      Name: "Eviction Notices and Rights",
    },
  ];
  const {
    values,
    handleBlur,
    handleChange,
    touched,
    // handleSubmit,
    // setFieldValue,
    errors,
    // setTouched,
  } = useFormik({
    initialValues: initialValues,
    validationSchema: Post2Schemas,
    // Address: address,
    // onSubmit,
  });
  const handlemodal = (i) => {
    setbData(i);
    setContext(false);
  };
  const handlemodal2 = (i) => {
    setnewData(i);
    setContext(true);
  };
  return (
    <>
      <div className="second-post-main-section">
        <div className="second-post-child-div">
          <span className="customer-span-span98">Housing</span>
        </div>

        <div className="both-categories-90-div-9k">
          {btns.map((item, i) => {
            return (
              <>
                <button
                  className={
                    bdata === i
                      ? "Product-Issues-button"
                      : "Product-Issues-button-unactive"
                  }
                  key={i}
                  onClick={() => handlemodal(i)}
                >
                  {item.Name}
                </button>
              </>
            );
          })}
        </div>
        {bdata === 0 ? (
          <>
            {/* <div className="char-button-div">
              <button
                className="Defective-Product-button"
                onClick={() => setContext(true)}
              >
                Lease Terms and Conditions
              </button>
              <button
                className="Defective-Product-button"
                onClick={() => setContext(true)}
              >
                Rent Increases and Renewals
              </button>
              <button
                className="Defective-Product-button"
                onClick={() => setContext(true)}
              >
                Security Deposit Issues
              </button>
              <button
                className="Defective-Product-button"
                onClick={() => setContext(true)}
              >
                Eviction Notices and Rights
              </button>
            </div> */}
            <div className="char-button-div">
              {btns1.map((item, i) => {
                return (
                  <>
                    <button
                      // className="Defective-Product-button"
                      className={
                        newdata === i
                          ? "Product-Issues-button22"
                          : "Product-Issues-button-unactive22"
                      }
                      key={i}
                      onClick={() => handlemodal2(i)}
                    >
                      {item.Name}
                    </button>
                  </>
                );
              })}
            </div>
          </>
        ) : bdata === 1 ? (
          <Housing2 />
        ) : bdata === 2 ? (
          <Landlord />
        ) : bdata === 3 ? (
          <Violations />
        ) : null}
        <div>
          <button className="Craft-Anynomus-Help-Request">
            Craft Anynomus Help Request
          </button>
        </div>
        {context ? null : (
          <div className="How-much-spend-div">
            <span className="subcategories-span-00-00">
              Craft Anynomus Help Request
            </span>
            <div className="input-container-909">
              <input
                type="text"
                className="categories-input-909"
                placeholder="Write here..."
                name="Writehere"
                value={values.Writehere}
                onChange={handleChange}
                onBlur={handleBlur}
              />
            </div>
            {errors.Writehere && touched.Writehere ? (
              <p className="mastu-to-eroor mt-2">{errors.Writehere} </p>
            ) : null}
          </div>
        )}
        <div className="How-much-spend-div">
          <span className="subcategories-span-00-00">
            How much spend you in this topic?
          </span>

          <div className="input-container-909">
            <input
              type="number"
              className="categories-input"
              // placeholder="$"
              name="rate"
              value={values.rate}
              onChange={handleChange}
              onBlur={handleBlur}
            />
            <span class="placeholder-text12">$</span>
          </div>

          {errors.rate && touched.rate ? (
            <p className="mastu-to-eroor mt-2">{errors.rate} </p>
          ) : null}
        </div>
        <div>
          <button className="new-post0button-09">Post</button>
        </div>
      </div>
      <div className="main-post-linehg"></div>
    </>
  );
};

export default Post2;
