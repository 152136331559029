import React from "react";
import "../Css/Open.css";

const Open = () => {
  return (
    <div className="helo-open">
      <div className="devon-opne">
        <div>
          <span className="send-propsa-span">Send Proposal</span>
        </div>
        <div className="ret-propsal">
          <span>Message</span>
          <div>
            <textarea name="" id="" className="ertyuytyy"></textarea>
          </div>
        </div>
        <div className="ret-propsal">
          <span>Price</span>

          <div>
            <input type="number" className="fghjgfhjgy" />
          </div>
        </div>
        <div>
          <button className="button-propsal90">Send Propsal</button>
        </div>
      </div>
    </div>
  );
};

export default Open;
