import React, { useState } from "react";
import "../Css/Search.css";
import { BiSearch } from "react-icons/bi";
import axios from "../schemas/Api";
import { useFormik } from "formik";
import ssstar from "../Images/ssstar.png";
import ksstar from "../Images/ksstar.png";
import Footer2 from "./Footer2";
import CircularProgress from "@mui/material/CircularProgress";

const initialValues = {
  search: "",
};
const Search = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [recentdata, setRecentdata] = useState([]);
  const [advocatedata, setadvocateData] = useState([]);
  const data = JSON.parse(localStorage.getItem("Nezziedata"));
  localStorage.getItem("lat");
  localStorage.getItem("lon");

  //   main search api integrated at here
  const handleSearch = async () => {
    try {
      setIsLoading(true);
      const response = await axios.post(
        "search/mainSearch",
        {
          search: values.search,
          longitude: localStorage.getItem("lat"),

          latitude: localStorage.getItem("lon"),
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: data.token,
          },
        }
      );
      setadvocateData(response.data.data);
      console.log(response);
    } catch (error) {
      console.log("error", error);
    } finally {
      setIsLoading(false); // Set loading state back to false
    }
  };
  //   Recent Sear ch api integreted at here
  const handleRecentsearch = async (searchTerm) => {
    try {
      const res = await axios.post(
        "search/searchTag",
        {
          search: searchTerm,
          longitude: localStorage.getItem("lat"),

          latitude: localStorage.getItem("lon"),
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: data.token,
          },
        }
      );
      setRecentdata(res.data.data);
      console.log(res);
    } catch (error) {
      console.log("Error", error);
    }
  };
  const {
    values,
    handleBlur,
    handleChange,
    // touched,
    handleSubmit,
    // setFieldValue,
    // errors,
  } = useFormik({
    initialValues: initialValues,
    // validationSchema:,

    // onSubmit: handleSearch,
  });
  return (
    <>
      <section className="main-section-of-search">
        <div className="search-main-bh-div">
          <form className="search-input-field-nono">
            <input
              type="search"
              className="serach-inpiut"
              name="search"
              placeholder="Search"
              value={values.search}
              onChange={handleChange}
              onBlur={handleBlur}
            />

            <BiSearch
              onClick={() => {
                if (values.search.trim() !== "") {
                  handleSearch();
                  handleRecentsearch(values.search);
                }
              }}
              className="serach-icon-react"
              size={25}
            />
          </form>
        </div>
        {recentdata.length > 0 && (
          <div>
            <h2>Recent Searches</h2>
            {recentdata.map((item) => (
              <div key={item.id}>{item.searchTerm}</div>
            ))}
          </div>
        )}
        <div className="card-section-main-div-search">
          {isLoading ? (
            <div className="loader-container">
              <CircularProgress />
            </div>
          ) : (
            advocatedata.map((item) => {
              let Ratingcount = item.review_total_count;
              let MaxRate = 5;
              let yellowstar = Math.min(Ratingcount, MaxRate);
              let RatingcountImage = Array.from(
                { length: yellowstar },
                (_, i) => <img key={i} src={ssstar} alt="" />
              );
              let GrayStarCount = MaxRate - yellowstar;
              let GrayStarImage = Array.from(
                { length: GrayStarCount },
                (_, i) => <img key={i + yellowstar} src={ksstar} alt="" />
              );
              let starRatingimages = [...RatingcountImage, ...GrayStarImage];
              return (
                <div className="card-ineer-single-div-search">
                  <div className="svg-image-div-search">
                    <svg
                      className="msg-svg-1"
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <g clip-path="url(#clip0_2_925)">
                        <path
                          d="M7.29101 20.824L2.00001 22L3.17601 16.709C2.40154 15.2604 1.99754 13.6426 2.00001 12C2.00001 6.477 6.47701 2 12 2C17.523 2 22 6.477 22 12C22 17.523 17.523 22 12 22C10.3574 22.0025 8.73963 21.5985 7.29101 20.824V20.824ZM7.58101 18.711L8.23401 19.061C9.39256 19.6801 10.6864 20.0027 12 20C13.5823 20 15.129 19.5308 16.4446 18.6518C17.7602 17.7727 18.7855 16.5233 19.391 15.0615C19.9965 13.5997 20.155 11.9911 19.8463 10.4393C19.5376 8.88743 18.7757 7.46197 17.6569 6.34315C16.538 5.22433 15.1126 4.4624 13.5607 4.15372C12.0089 3.84504 10.4004 4.00346 8.93854 4.60896C7.47674 5.21447 6.22731 6.23984 5.34825 7.55544C4.4692 8.87103 4.00001 10.4177 4.00001 12C4.00001 13.334 4.32501 14.618 4.94001 15.766L5.28901 16.419L4.63401 19.366L7.58101 18.711V18.711Z"
                          fill="#006EBD"
                        />
                      </g>
                      <defs>
                        <clipPath id="clip0_2_925">
                          <rect width="24" height="24" fill="white" />
                        </clipPath>
                      </defs>
                    </svg>
                  </div>
                  <div>
                    <img
                      src={item.image}
                      alt=""
                      className="item-image-card-serch"
                    />
                  </div>
                  <span className="item-span-card-serch">{item.fullName}</span>
                  <div className="image-review-dv-serch">
                    {starRatingimages.map((item) => {
                      return (
                        <img
                          src={item.props.src}
                          alt=""
                          className="star-img-90-serch"
                        />
                      );
                    })}
                    <span className="image-review-span-search">
                      (0 Reviews)
                    </span>
                  </div>

                  <div className="item-dono-div-saerch">
                    <span className="dollar-symbol-ooops-serch">$</span>
                    <span className="item-price-span-search">{item.rate}</span>
                    <span className="per-hour-span77-serch">/ per hour</span>
                  </div>

                  <span className="item-paragraph-span-search">
                    {/* {item.paragraph} */}
                    {item.resolutionExperiance}
                  </span>
                </div>
              );
            })
          )}
        </div>
      </section>
      <Footer2 />
    </>
  );
};

export default Search;
