import React, { useState, useEffect } from "react";
import "../Css/AvailableAdvocate.css";
import advocatgroup from "../Images/advocatgroup.png";
// import advo11 from "../Images/advo11.png";
// import advo22 from "../Images/advo22.png";
// import advo33 from "../Images/advo33.png";
// import advo44 from "../Images/advo44.png";
// import advo55 from "../Images/advo55.png";
// import advo66 from "../Images/advo66.png";
// import advo77 from "../Images/advo77.png";
// import advo88 from "../Images/advo88.png";
// import advo99 from "../Images/advo99.png";
// import advo10 from "../Images/advo10.png";
// import advo111 from "../Images/advo111.png";
// import advo12 from "../Images/advo12.png";
// import starimg from "../Images/starimg.png";
import CircularProgress from "@mui/material/CircularProgress";

import Footer2 from "./Footer2";
import Navbar2 from "./Navbar2";
import axios from "../schemas/Api";
import ssstar from "../Images/ssstar.png";
import ksstar from "../Images/ksstar.png";

const AvailableAdvocate = () => {
  const [isloading, setIsLoading] = useState(true);
  const [advocates, setAdvocates] = useState([]);
  const data = JSON.parse(localStorage.getItem("Nezziedata"));
  // console.log(data.token);
  // console.log(advocates);
  // useEffect(() => {
  //   if (navigator.geolocation) {
  //     navigator.geolocation.getCurrentPosition(
  //       (position) => {
  //         const latitude = position.coords.latitude;
  //         const longitude = position.coords.longitude;

  //         adData(latitude, longitude);
  //       },
  //       (error) => {
  //         console.error("Error getting user's location:", error);
  //       }
  //     );
  //   } else {
  //     console.error("Geolocation is not supported by this browser.");
  //   }
  // }, []);
  useEffect(() => {
    adData();
  }, []);
  localStorage.getItem("lat");
  localStorage.getItem("lon");
  // here i remove the dependency array
  const adData = async () => {
    try {
      const response = await axios.post(
        "advocate/near_by_advocate",
        {
          // longitude: "72.5131344",

          // latitude: "22.9848335",
          longitude: localStorage.getItem("lat"),

          latitude: localStorage.getItem("lon"),
        },
        {
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            Authorization: data.token,
          },
        }
      );
      setAdvocates(response.data.data);
      setIsLoading(false);

      console.log(response);
    } catch (error) {
      console.log("Error:", error);
    }
  };

  // const addata = [
  //   {
  //     img: advo11,
  //     price: "$ 200",
  //     Name: "Arlene McCoy",
  //     paragraph:
  //       "There are many variations of passages of Lorem Ipsum available, but the majority...",
  //   },
  //   {
  //     img: advo22,
  //     price: "$ 349",
  //     Name: "Jenny Wilson",
  //     paragraph:
  //       "There are many variations of passages of Lorem Ipsum available, but the majority...",
  //   },
  //   {
  //     img: advo33,
  //     price: " $ 299",
  //     Name: "Eleanor Pena",
  //     paragraph:
  //       "There are many variations of passages of Lorem Ipsum available, but the majority...",
  //   },
  //   {
  //     img: advo44,
  //     price: " $ 149",
  //     Name: "Darlene Robertson",
  //     paragraph:
  //       "There are many variations of passages of Lorem Ipsum available, but the majority...",
  //   },
  //   {
  //     img: advo55,
  //     price: " $ 200",
  //     Name: "Arlene McCoy",
  //     paragraph:
  //       "There are many variations of passages of Lorem Ipsum available, but the majority...",
  //   },
  //   {
  //     img: advo66,
  //     price: "$ 349",
  //     Name: "Jenny Wilson",
  //     paragraph:
  //       "There are many variations of passages of Lorem Ipsum available, but the majority...",
  //   },
  //   {
  //     img: advo77,
  //     price: "$ 299",
  //     Name: "Eleanor Pena",
  //     paragraph:
  //       "There are many variations of passages of Lorem Ipsum available, but the majority...",
  //   },
  //   {
  //     img: advo88,
  //     price: " $ 149",
  //     Name: "Darlene Robertson",
  //     paragraph:
  //       "There are many variations of passages of Lorem Ipsum available, but the majority...",
  //   },
  //   {
  //     img: advo99,
  //     price: " $ 200",
  //     Name: "Arlene McCoy",
  //     paragraph:
  //       "There are many variations of passages of Lorem Ipsum available, but the majority...",
  //   },
  //   {
  //     img: advo10,
  //     price: " $ 349",
  //     Name: "Jenny Wilson",
  //     paragraph:
  //       "There are many variations of passages of Lorem Ipsum available, but the majority...",
  //   },
  //   {
  //     img: advo111,
  //     price: "$ 299",
  //     Name: "Eleanor Pena",
  //     paragraph:
  //       "There are many variations of passages of Lorem Ipsum available, but the majority...",
  //   },
  //   {
  //     img: advo12,
  //     price: "$ 299",
  //     Name: "Darlene Robertson",
  //     paragraph:
  //       "There are many variations of passages of Lorem Ipsum available, but the majority...",
  //   },
  // ];
  return (
    <>
      <Navbar2 />
      <section className="AvailableAdvocate-main-section">
        <div className="AvailableAdvocate-main-div">
          <div className="Available-Advocates-span-div">
            <span className="Available-Advocates-span">
              Available Advocates
            </span>
          </div>
          <div className="img-span-donno">
            <img src={advocatgroup} alt="" className="advocat-img" />
            <span className="on-span">
              We earn your trust and are deligent your case.
            </span>
            <span className="lorem-span-span-123">
              Lorem Ipsum is simply dummy text of the printing and typesetting
              industry. Lorem Ipsum has been the industry's standard.
            </span>
            <button className="btn-on">Hire an Advocate</button>
          </div>
          {isloading ? (
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: "100vh",
                width: "100%",
              }}
            >
              <CircularProgress />
            </div>
          ) : (
            <div className="card-section-main-div">
              {advocates.map((item) => {
                let Ratingcount = item.review_total_count;
                let MaxRate = 5;
                let yellowstar = Math.min(Ratingcount, MaxRate);
                let RatingcountImage = Array.from(
                  { length: yellowstar },
                  (_, i) => <img key={i} src={ssstar} alt="" />
                );
                let GrayStarCount = MaxRate - yellowstar;
                let GrayStarImage = Array.from(
                  { length: GrayStarCount },
                  (_, i) => <img key={i + yellowstar} src={ksstar} alt="" />
                );
                let starRatingimages = [...RatingcountImage, ...GrayStarImage];
                return (
                  <div className="card-ineer-single-div">
                    <div className="svg-image-div">
                      <svg
                        className="msg-svg-1"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <g clip-path="url(#clip0_2_925)">
                          <path
                            d="M7.29101 20.824L2.00001 22L3.17601 16.709C2.40154 15.2604 1.99754 13.6426 2.00001 12C2.00001 6.477 6.47701 2 12 2C17.523 2 22 6.477 22 12C22 17.523 17.523 22 12 22C10.3574 22.0025 8.73963 21.5985 7.29101 20.824V20.824ZM7.58101 18.711L8.23401 19.061C9.39256 19.6801 10.6864 20.0027 12 20C13.5823 20 15.129 19.5308 16.4446 18.6518C17.7602 17.7727 18.7855 16.5233 19.391 15.0615C19.9965 13.5997 20.155 11.9911 19.8463 10.4393C19.5376 8.88743 18.7757 7.46197 17.6569 6.34315C16.538 5.22433 15.1126 4.4624 13.5607 4.15372C12.0089 3.84504 10.4004 4.00346 8.93854 4.60896C7.47674 5.21447 6.22731 6.23984 5.34825 7.55544C4.4692 8.87103 4.00001 10.4177 4.00001 12C4.00001 13.334 4.32501 14.618 4.94001 15.766L5.28901 16.419L4.63401 19.366L7.58101 18.711V18.711Z"
                            fill="#006EBD"
                          />
                        </g>
                        <defs>
                          <clipPath id="clip0_2_925">
                            <rect width="24" height="24" fill="white" />
                          </clipPath>
                        </defs>
                      </svg>
                    </div>
                    <div>
                      <img
                        src={item.image}
                        alt=""
                        className="item-image-card"
                      />
                    </div>
                    <span className="item-span-card">{item.fullName}</span>
                    <div className="image-review-dv">
                      {starRatingimages.map((item) => {
                        return (
                          <img
                            src={item.props.src}
                            alt=""
                            className="star-img-90"
                          />
                        );
                      })}
                      <span className="image-review-span">(0 Reviews)</span>
                    </div>

                    <div className="item-dono-div">
                      <span className="dollar-symbol-ooops">$</span>
                      <span className="item-price-span">{item.rate}</span>
                      <span className="per-hour-span">/ per hour</span>
                    </div>

                    <span className="item-paragraph-span">
                      {/* {item.paragraph} */}
                      {item.aboutYou}
                    </span>
                  </div>
                );
              })}
            </div>
          )}
        </div>
      </section>
      <Footer2 />
      {/* <div className="both-main-div52">
                  <div className="item10-hour-div">
                    <span className="hour-span-span19">
                      Less than {item10.Hour} hrs/week
                    </span>
                    <span className="week-span-90">Hours needed</span>
                  </div>
                  <div className="item10-hour-div">
                    {" "}
                    <span className="hour-span-span19">Duration</span>
                    <span className="week-span-90">{item10.Week}</span>
                  </div>
                </div>
                <div className="both-main-div5252">
                  <div className="item10-hour-div">
                    <span className="hour-span-span19">Experience In</span>
                    <span className="week-span-90">{item10.Contract}</span>
                  </div>
                  <div className="item10-hour-div">
                    {" "}
                    <span className="hour-span-span19">Location</span>
                    <span className="week-span-90">{item10.dallas}</span>
                  </div>
                </div>
                <div>
                  <span className="item10-span-textr">
                    We need some one with larger scale legal...
                    <span className="more-span00">more</span>
                  </span>
                </div> */}
    </>
  );
};

export default AvailableAdvocate;
