import React from "react";
import "../Css/AboutUs.css";
import mitting from "../Images/mitting.png";

const AboutUs = () => {
  return (
    <>
      <section className="AboutUs-section">
        <div className="AboutUs-div">
          <div>
            <img className="mitting-img" src={mitting} alt="" />
          </div>
          <div className="about-us-contain">
            <span className="link-about">About Us</span>
            <span className="sleep-span">
              {/* We never sleep till our client get justice. */}
              Unleash Empowerment Through Expert Advocacy
            </span>
            <span className="industry-span">
              Nezzie™ is your partner when it comes to facing uncertainty in
              business scenarios. Whether you're seeking guidance, advocating
              for your rights, or connecting with business representatives, our
              platform empowers your voice to shape collaborative solutions.
            </span>
            <button className="abvoca-span">Find an Advocate</button>
          </div>
        </div>
      </section>
    </>
  );
};

export default AboutUs;
