import React, { useState } from "react";
import "../Css/Filter.css";

const Filter = () => {
  return (
    <>
      <div className="Model-wrap"></div>

      <div>
        <section className="ChangePassword-main-section">
          <div className="ChangePassword-main-div">
            <div className="Legal-input-span">
              <span>Legal</span>
              <input
                type="checkbox"
                className="chaki-chaki"
                id="vehicle1"
                name="vehicle1"
                value="Bike"
              />
            </div>
            <div className="Legal-ke-line-ho--gai"></div>
            <div className="Legal-input-span">
              <span>Auto</span>
              <input
                type="checkbox"
                id="vehicle1"
                name="vehicle1"
                value="Bike"
              />
            </div>
            <div className="Legal-ke-line-ho--gai"></div>
            <div className="Legal-input-span">
              <span>Customer</span>
              <input
                type="checkbox"
                id="vehicle1"
                name="vehicle1"
                value="Bike"
              />
            </div>
            <div className="Legal-ke-line-ho--gai"></div>
            <div className="Legal-input-span">
              <span>Housing</span>
              <input
                type="checkbox"
                id="vehicle1"
                name="vehicle1"
                value="Bike"
              />
            </div>
            <div className="Legal-ke-line-ho--gai"></div>
            <div className="Legal-input-span">
              <span>Employment</span>
              <input
                type="checkbox"
                id="vehicle1"
                name="vehicle1"
                value="Bike"
              />
            </div>
            <div className="Legal-ke-line-ho--gai"></div>
            <div className="Legal-input-span">
              <span>Medical</span>
              <input
                type="checkbox"
                id="vehicle1"
                name="vehicle1"
                value="Bike"
              />
            </div>
            <div className="Legal-ke-line-ho--gai"></div>
            <div className="Legal-input-span">
              <span>Travel and Tourism</span>
              <input
                type="checkbox"
                id="vehicle1"
                name="vehicle1"
                value="Bike"
              />
            </div>
            <div className="Legal-ke-line-ho--gai"></div>
            <div className="Legal-input-span">
              <span>Environmental</span>
              <input
                type="checkbox"
                id="vehicle1"
                name="vehicle1"
                value="Bike"
              />
            </div>
            <div className="Legal-ke-line-ho--gai"></div>
            <div className="Legal-input-span">
              <span>Technology</span>
              <input
                type="checkbox"
                id="vehicle1"
                name="vehicle1"
                value="Bike"
              />
            </div>
            <div className="Legal-ke-line-ho--gai"></div>
            <div className="Legal-input-span">
              <span>Education</span>
              <input
                type="checkbox"
                id="vehicle1"
                name="vehicle1"
                value="Bike"
              />
            </div>
            <div className="Legal-ke-line-ho--gai"></div>
            <div className="Legal-input-span">
              <span>Financial</span>
              <input
                type="checkbox"
                id="vehicle1"
                name="vehicle1"
                value="Bike"
              />
            </div>
            <div className="Legal-ke-line-ho--gai"></div>
            <div className="Legal-input-span mb-3">
              <span>Physical Representation</span>
              <input
                type="checkbox"
                id="vehicle1"
                name="vehicle1"
                value="Bike"
              />
            </div>
            <div className="Legal-ke-line-ho--gai"></div>
            <div className="cancel-apply-btn-ho mb-3 mt-3">
              <button className="Cancel-btn-ho-gya">Cancel</button>
              <button className="Apply-btn-ho-gya">Apply</button>
            </div>
          </div>
        </section>
      </div>
    </>
  );
};

export default Filter;
