// export default Financial;
import React, { useState, useEffect } from "react";
import "../Css/Post2.css";
import New from "./New";
// import { FiArrowUpRight } from "react-icons/fi";
// import { useEffect } from "react";
import axios from "../schemas/APi1";
import { Post2Schemas } from "../schemas";
import { useFormik } from "formik";
// import { toast } from "react-toastify";
// import "react-toastify/dist/ReactToastify.css";
// import ServiceComplaints from "./ServiceComplaints";
// import OnlineTransaction from "./OnlineTransaction";
import Insurance from "./Insurance";
import PatentRights from "./PatentRights";
import Health from "./Health";
import { useContext } from "react";
import { Globalcontext } from "../Context/GlobalContext";
const initialValues = {
  rate: "",
  Writehere: "",
};
const Post2 = () => {
  const [bdata, setbData] = useState(0);
  const { context, setContext } = useContext(Globalcontext);
  const [newdata, setnewData] = useState(0);
  const data = JSON.parse(localStorage.getItem("Nezziedata"));

  const [listdata, setListdata] = useState([]);
  useEffect(() => {
    specilaitydata();
  }, []);
  const specilaitydata = async () => {
    try {
      const response = await axios.post(
        "meta_data/speciallityList",
        {},
        {
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            Authorization: data.token,
          },
        }
      );
      setListdata(response.data.data);
      console.log(response);
    } catch (error) {
      console.log("Error", error);
    }
  };
  const btns = [
    {
      id: "1",
      Name: "Medical Malpractice",
    },
    {
      id: "2",
      Name: "Insurance Claims",
    },
    {
      id: "3",
      Name: "Patient Rights Violations",
    },
    {
      id: "4",
      Name: "Health Record Privacy",
    },
  ];
  const btns1 = [
    {
      id: "1",
      Name: "Surgical Errors",
    },
    {
      id: "2",
      Name: "Misdiagnosis or Delayed Diagnosis",
    },
    {
      id: "3",
      Name: "Medication Errors",
    },
    {
      id: "4",
      Name: "Anesthesia Mistakes",
    },
  ];
  const {
    values,
    handleBlur,
    handleChange,
    touched,
    // handleSubmit,
    // setFieldValue,
    errors,
    // setTouched,
  } = useFormik({
    initialValues: initialValues,
    validationSchema: Post2Schemas,
    // Address: address,
    // onSubmit,
  });
  const handlemodal = (i) => {
    setbData(i);
    setContext(false);
  };
  const handlemodal2 = (i) => {
    setnewData(i);
    setContext(true);
  };
  return (
    <>
      <div className="second-post-main-section">
        <div className="second-post-child-div">
          <span className="customer-span-span98">Medical</span>
        </div>

        <div className="both-categories-90-div">
          {btns.map((item, i) => {
            return (
              <>
                <button
                  className={
                    bdata === i
                      ? "Product-Issues-button"
                      : "Product-Issues-button-unactive"
                  }
                  key={i}
                  onClick={() => handlemodal(i)}
                >
                  {item.Name}
                </button>
              </>
            );
          })}
        </div>
        {bdata === 0 ? (
          <>
            {/* <div className="char-button-div">
              <button
                className="Defective-Product-button"
                onClick={() => setContext(true)}
              >
                Surgical Errors
              </button>
              <button
                className="Defective-Product-button"
                onClick={() => setContext(true)}
              >
                Misdiagnosis or Delayed Diagnosis
              </button>
              <button
                className="Defective-Product-button"
                onClick={() => setContext(true)}
              >
                Medication Errors
              </button>
              <button
                className="Defective-Product-button"
                onClick={() => setContext(true)}
              >
                Anesthesia Mistakes
              </button>
            </div> */}
            <div className="char-button-div">
              {btns1.map((item, i) => {
                return (
                  <>
                    <button
                      // className="Defective-Product-button"
                      className={
                        newdata === i
                          ? "Product-Issues-button22"
                          : "Product-Issues-button-unactive22"
                      }
                      key={i}
                      onClick={() => handlemodal2(i)}
                    >
                      {item.Name}
                    </button>
                  </>
                );
              })}
            </div>
          </>
        ) : bdata === 1 ? (
          <Insurance />
        ) : bdata === 2 ? (
          <PatentRights />
        ) : bdata === 3 ? (
          <Health />
        ) : null}
        <div>
          <button className="Craft-Anynomus-Help-Request">
            Craft Anynomus Help Request
          </button>
        </div>
        {context ? null : (
          <div className="How-much-spend-div">
            <span className="subcategories-span-00-00">
              Craft Anynomus Help Request
            </span>
            <div className="input-container-909">
              <input
                type="text"
                className="categories-input-909"
                placeholder="Write here..."
                name="Writehere"
                value={values.Writehere}
                onChange={handleChange}
                onBlur={handleBlur}
              />
            </div>
            {errors.Writehere && touched.Writehere ? (
              <p className="mastu-to-eroor mt-2">{errors.Writehere} </p>
            ) : null}
          </div>
        )}
        <div className="How-much-spend-div">
          <span className="subcategories-span-00-00">
            How much spend you in this topic?
          </span>

          <div className="input-container-909">
            <input
              type="number"
              className="categories-input"
              // placeholder="$"
              name="rate"
              value={values.rate}
              onChange={handleChange}
              onBlur={handleBlur}
            />
            <span class="placeholder-text12">$</span>
          </div>

          {errors.rate && touched.rate ? (
            <p className="mastu-to-eroor mt-2">{errors.rate} </p>
          ) : null}
        </div>
        <div>
          <button className="new-post0button-09">Post</button>
        </div>
      </div>
      <div className="main-post-linehg"></div>
    </>
  );
};

export default Post2;
