import "../Css/AAdvocates.css";
import React, { useRef } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import { Navigation } from "swiper";
import Advocates1 from "../Images/Advocates1.png";
import Advocates2 from "../Images/Advocates2.png";
import Advocates3 from "../Images/Advocates3.png";
import Advocates4 from "../Images/Advocates4.png";
import laft from "../Images/laftbtn.png";
import right from "../Images/rightbtn.png";
const AAdvocates = () => {
  const advodata = [
    {
      img: Advocates1,
      price: "$ 200",
      Name: "Arlene McCoy",
    },
    {
      img: Advocates2,
      price: "$ 349",
      Name: "Jenny Wilson",
    },
    {
      img: Advocates3,
      price: "$ 299",
      Name: "Eleanor Pena",
    },
    {
      img: Advocates4,
      price: " $ 149",
      Name: "Darlene Robertson",
    },
    {
      img: Advocates4,
      price: " $ 149",
      Name: "Darlene Robertson",
    },
    {
      img: Advocates4,
      price: " $ 149",
      Name: "Darlene Robertson",
    },
  ];
  const btnref = useRef(null);
  return (
    <>
      <section className="AAdvocates-section">
        <div className="AAdvocates-div">
          <div className="a-a-av-div">
            <span className="Advocates-a-span">Available Advocates</span>
          </div>
          <div className="swipar-div">
            <span onClick={() => btnref.current.swiper.slidePrev()}>
              <img className="icon-laft-img" src={laft} alt="" />
            </span>
            <Swiper
              navigation={true}
              ref={btnref}
              modules={[Navigation]}
              className="mySwiper"
              slidesPerView={4}
              breakpoints={{
                1024: {
                  slidesPerView: 4,
                  spaceBetween: 30,
                },
                884: {
                  slidesPerView: 3,
                  spaceBetween: 30,
                },
                768: {
                  slidesPerView: 2,
                  spaceBetween: 30,
                },
                428: {
                  slidesPerView: 1,
                  spaceBetween: 30,
                },
                390: {
                  slidesPerView: 1,
                  spaceBetween: 30,
                },

                360: {
                  slidesPerView: 1,
                  spaceBetween: 40,
                },
                320: {
                  slidesPerView: 2,
                  spaceBetween: 40,
                },
              }}
            >
              <div>
                {advodata.map((itm, index) => {
                  return (
                    <div key={index}>
                      <SwiperSlide>
                        <div className="main-div-user">
                          <div className="svg-msg-div">
                            <svg
                              className="msg-svg-1"
                              width="24"
                              height="24"
                              viewBox="0 0 24 24"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <g clip-path="url(#clip0_2_925)">
                                <path
                                  d="M7.29101 20.824L2.00001 22L3.17601 16.709C2.40154 15.2604 1.99754 13.6426 2.00001 12C2.00001 6.477 6.47701 2 12 2C17.523 2 22 6.477 22 12C22 17.523 17.523 22 12 22C10.3574 22.0025 8.73963 21.5985 7.29101 20.824V20.824ZM7.58101 18.711L8.23401 19.061C9.39256 19.6801 10.6864 20.0027 12 20C13.5823 20 15.129 19.5308 16.4446 18.6518C17.7602 17.7727 18.7855 16.5233 19.391 15.0615C19.9965 13.5997 20.155 11.9911 19.8463 10.4393C19.5376 8.88743 18.7757 7.46197 17.6569 6.34315C16.538 5.22433 15.1126 4.4624 13.5607 4.15372C12.0089 3.84504 10.4004 4.00346 8.93854 4.60896C7.47674 5.21447 6.22731 6.23984 5.34825 7.55544C4.4692 8.87103 4.00001 10.4177 4.00001 12C4.00001 13.334 4.32501 14.618 4.94001 15.766L5.28901 16.419L4.63401 19.366L7.58101 18.711V18.711Z"
                                  fill="#006EBD"
                                />
                              </g>
                              <defs>
                                <clipPath id="clip0_2_925">
                                  <rect width="24" height="24" fill="white" />
                                </clipPath>
                              </defs>
                            </svg>
                          </div>
                          <div className="to-do-img">
                            <img
                              className="Advocates1-ke-img"
                              src={itm.img}
                              alt=""
                            />
                          </div>
                          <span className="Arlene-span">{itm.Name}</span>
                          <div className="per-hors-span">
                            <svg
                              width="70"
                              height="14"
                              viewBox="0 0 70 14"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <g clip-path="url(#clip0_2_1105)">
                                <path
                                  d="M7.00001 9.91671L3.57117 12.0109L4.50334 8.10254L1.45251 5.48921L5.45709 5.16837L7.00001 1.45837L8.54292 5.16837L12.5481 5.48921L9.49667 8.10254L10.4288 12.0109L7.00001 9.91671Z"
                                  fill="#E8A700"
                                />
                              </g>
                              <g clip-path="url(#clip1_2_1105)">
                                <path
                                  d="M21 9.91671L17.5712 12.0109L18.5033 8.10254L15.4525 5.48921L19.4571 5.16837L21 1.45837L22.5429 5.16837L26.5481 5.48921L23.4967 8.10254L24.4288 12.0109L21 9.91671Z"
                                  fill="#E8A700"
                                />
                              </g>
                              <g clip-path="url(#clip2_2_1105)">
                                <path
                                  d="M35 9.91671L31.5712 12.0109L32.5033 8.10254L29.4525 5.48921L33.4571 5.16837L35 1.45837L36.5429 5.16837L40.5481 5.48921L37.4967 8.10254L38.4288 12.0109L35 9.91671Z"
                                  fill="#E8A700"
                                />
                              </g>
                              <g clip-path="url(#clip3_2_1105)">
                                <path
                                  d="M49 9.91671L45.5712 12.0109L46.5033 8.10254L43.4525 5.48921L47.4571 5.16837L49 1.45837L50.5429 5.16837L54.5481 5.48921L51.4967 8.10254L52.4288 12.0109L49 9.91671Z"
                                  fill="#E8A700"
                                />
                              </g>
                              <g clip-path="url(#clip4_2_1105)">
                                <path
                                  d="M63 9.91671L59.5712 12.0109L60.5033 8.10254L57.4525 5.48921L61.4571 5.16837L63 1.45837L64.5429 5.16837L68.5481 5.48921L65.4967 8.10254L66.4288 12.0109L63 9.91671Z"
                                  fill="#BDB8AB"
                                />
                              </g>
                              <defs>
                                <clipPath id="clip0_2_1105">
                                  <rect width="14" height="14" fill="white" />
                                </clipPath>
                                <clipPath id="clip1_2_1105">
                                  <rect
                                    width="14"
                                    height="14"
                                    fill="white"
                                    transform="translate(14)"
                                  />
                                </clipPath>
                                <clipPath id="clip2_2_1105">
                                  <rect
                                    width="14"
                                    height="14"
                                    fill="white"
                                    transform="translate(28)"
                                  />
                                </clipPath>
                                <clipPath id="clip3_2_1105">
                                  <rect
                                    width="14"
                                    height="14"
                                    fill="white"
                                    transform="translate(42)"
                                  />
                                </clipPath>
                                <clipPath id="clip4_2_1105">
                                  <rect
                                    width="14"
                                    height="14"
                                    fill="white"
                                    transform="translate(56)"
                                  />
                                </clipPath>
                              </defs>
                            </svg>
                            <span className="reveiw-span-op">
                              (
                              <span
                                className="
                              res-dark-span"
                              >
                                233
                              </span>{" "}
                              Reviews)
                            </span>
                          </div>
                          <div className="per-hors-span">
                            <span className="price-span234">{itm.price}</span>
                            <span className="per-hour-span77"> / per hour</span>
                          </div>
                          <span className="variations-span">
                            There are many variations of passages of Lorem Ipsum
                            available, but the majority...
                          </span>
                        </div>
                      </SwiperSlide>
                    </div>
                  );
                })}
              </div>
            </Swiper>
            <span onClick={() => btnref.current.swiper.slideNext()}>
              <img className="icon-laft-img" src={right} alt="" />
            </span>
          </div>
        </div>
      </section>
    </>
  );
};

export default AAdvocates;
