import React, { useState } from "react";
import "../Css/DropDown.css";
// import customer from "../Images/customer.png";
import arwrt from "../Images/arwrt.png";
// import housing from "../Images/housing.png";
import close from "../Images/close.png";
import Post2 from "../Component/Post2";
import Housing from "../Component/Housing";
import axios from "../schemas/APi1";
import { useEffect } from "react";
import CircularProgress from "@mui/material/CircularProgress";
import { Box } from "@mui/material";
import TraveleToursim from "./TravaleTourism";
import Environmental from "./Environmental";
import Technology from "./Technology";
import Education from "./Education";
import Financial from "./Financial";
import Physical from "./Physical";
import Employment from "./Employment";
import Medical from "./Medical";
const DropDown = () => {
  const [isLoading, setIsLoading] = useState(true);
  // const [show, setshow] = useState("");
  const [showTo, setshowTo] = useState("");
  const [showthree, setshowThree] = useState("");
  const [showfour, setshowFour] = useState("");
  const [showfive, setshowFive] = useState("");
  const [showsix, setshowSix] = useState("");
  const [showseven, setshowSeven] = useState("");
  const [showEight, setshowEight] = useState("");
  const [shownine, setshowNine] = useState("");
  const [showTen, setshowTen] = useState("");

  const [specialist, setSpecialist] = useState([]);
  // console.log(specialist);

  // const Imagelink = () => {
  //   setshow(true);
  // };
  const Mood = () => {
    setshowTo(true);
  };
  const Mood1 = () => {
    setshowThree(true);
  };
  const Mood2 = () => {
    setshowFour(true);
  };
  const Mood3 = () => {
    setshowFive(true);
  };
  const Mood4 = () => {
    setshowSix(true);
  };
  const Mood5 = () => {
    setshowSeven(true);
  };
  const Mood6 = () => {
    setshowEight(true);
  };
  const Mood7 = () => {
    setshowNine(true);
  };
  const Mood8 = () => {
    setshowTen(true);
  };
  // specialitylist api integraeted at here
  useEffect(() => {
    specialitydata();
  }, []);
  const specialitydata = async () => {
    try {
      const resp = await axios.post(
        "meta_data/speciallityList",
        {},
        {
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
          },
        }
      );
      setSpecialist(resp.data.data);
      setIsLoading(false);
      console.log(resp);
    } catch (error) {
      console.log("Error", error);
      setIsLoading(false);
    }
  };
  // const handleCustomerModalOpen = () => {
  //   setshow(true);
  // };

  // const handleHousingModalOpen = () => {
  //   setshowTo(true);
  // };
  return (
    <>
      {/* {show && (
        <div className="model">
          <div className="closeicon">
            <img src={close} alt="" onClick={() => setshow(false)} />
            <Post2 specialist={specialist} />
          </div>
        </div>
      )} */}
      {showTo && (
        <div className="model">
          <div className="closeicon">
            <img src={close} alt="" onClick={() => setshowTo(false)} />
            <Housing />
          </div>
        </div>
      )}
      {showthree && (
        <div className="model">
          <div className="closeicon">
            <img src={close} alt="" onClick={() => setshowThree(false)} />
            <TraveleToursim />
          </div>
        </div>
      )}
      {showfour && (
        <div className="model">
          <div className="closeicon">
            <img src={close} alt="" onClick={() => setshowFour(false)} />
            <Environmental />
          </div>
        </div>
      )}
      {showfive && (
        <div className="model">
          <div className="closeicon">
            <img src={close} alt="" onClick={() => setshowFive(false)} />
            <Technology />
          </div>
        </div>
      )}
      {showsix && (
        <div className="model">
          <div className="closeicon">
            <img src={close} alt="" onClick={() => setshowSix(false)} />
            <Education />
          </div>
        </div>
      )}
      {showseven && (
        <div className="model">
          <div className="closeicon">
            <img src={close} alt="" onClick={() => setshowSeven(false)} />
            <Financial />
          </div>
        </div>
      )}
      {showEight && (
        <div className="model">
          <div className="closeicon">
            <img src={close} alt="" onClick={() => setshowEight(false)} />
            <Physical />
          </div>
        </div>
      )}
      {shownine && (
        <div className="model">
          <div className="closeicon">
            <img src={close} alt="" onClick={() => setshowNine(false)} />
            <Employment />
          </div>
        </div>
      )}
      {showTen && (
        <div className="model">
          <div className="closeicon">
            <img src={close} alt="" onClick={() => setshowTen(false)} />
            <Medical />
          </div>
        </div>
      )}
      <div className="drop-down-main-section">
        <div className="drop-down-main-child">
          {isLoading ? ( // Check isLoading state for rendering the loader
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: "100px",
              }}
            >
              <CircularProgress />
            </Box>
          ) : (
            specialist.map((speciality) => (
              <div
                key={speciality._id}
                onClick={() => {
                  // Imagelink();
                  localStorage.setItem("specialitydata", speciality._id);
                  localStorage.setItem(
                    "specialityname",
                    speciality.speciallityName
                  );
                }}
                className="customer-images-text-div"
              >
                <div className="both-kahin-div">
                  <img
                    src={speciality.speciallityImage}
                    alt=""
                    className="cutomer-img-909"
                  />
                  <span className="customer-span-99">
                    {speciality.speciallityName}
                  </span>
                </div>
                <div>
                  <img src={arwrt} alt="" className="arwrt-images" />
                </div>
              </div>
            ))
          )}
          {/* <div className="border-main-border0"></div> */}
        </div>
      </div>
    </>
  );
};

export default DropDown;
