import React, { useRef, useEffect } from "react";
import { useState } from "react";
import Logo from "../Images/Nezzie Logo.png";
import ser from "../Images/search.png";
import Hamburger from "hamburger-react";
import { NavLink, useNavigate } from "react-router-dom";
import "../Css/Navbar.css";
import "../Css/Navbar1.css";
import notification from "../Images/notification.png";
import Modal from "./Modal";
import Easton from "../Images/easton.png";
import arrowrght from "../Images/arrowrght.png";
import logimg from "../Images/logimg.png";
import { useUser } from "../UserContext.js";

const Navbar = () => {
  const { userData } = useUser();

  const navigate = useNavigate();
  // const [save, setSave] = useState();
  // console.log(save);

  // useEffect(() => {
  //   setSave(JSON.parse(localStorage.getItem("Nezziedata")));
  // }, []);

  const [open, setOpen] = useState(false);

  const [isScrolled, setIsScrolled] = useState(false);

  const [isOpen, setIsOpen] = useState(false);

  const handleScroll = () => {
    if (window.scrollY > 20) {
      setIsScrolled(true);
    } else {
      setIsScrolled(false);
    }
  };
  let menuref = useRef();
  useEffect(() => {
    let handler = (event) => {
      if (menuref.current) {
        if (!menuref.current.contains(event.target)) {
          setIsOpen(false);
        }
      }
    };
    document.addEventListener("scroll", handler);

    return () => {
      document.removeEventListener("mousedown", handler);
      document.removeEventListener("scroll", handler);
    };
  });
  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);
  const handlehide = () => {
    setIsOpen(!isOpen);
  };

  return (
    <>
      <section className="Navbar-section">
        <div className={!isScrolled ? "navbar-div" : "navbar-div-not"}>
          <div className="Navbar-itm">
            <NavLink to="/">
              <img className="logo-img" src={Logo} alt="" />
            </NavLink>

            <div className={isOpen ? "navbar-itam100" : "navbar-itam"}>
              <ul className="Nav-bar-itm">
                <li>
                  {" "}
                  <NavLink to="/" className="home-li-nav1">
                    Home{" "}
                  </NavLink>
                </li>
                <li>
                  {" "}
                  <NavLink to="/Jobposts" className="home-li-nav1">
                    Job Posts <div className="round-divmm">2</div>
                  </NavLink>
                </li>
              </ul>
              <div className="img-btn-select-div">
                <img className="serach-img" src={ser} alt="" />
                <select className="drop-one-en" name="cars" id="cars">
                  <option value="volvo">EN</option>
                  <option value="saab">Saab</option>
                  <option value="mercedes">Mercedes</option>
                  <option value="audi">Audi</option>
                </select>
                <div className="height-width-img23-ops0">
                  <div className="height-width-img23">2</div>
                  <img
                    src={notification}
                    alt=""
                    className="notification-img11"
                  />
                </div>
                {/* <button
                  onClick={() => navigate("/AdvoUser")}
                  className="Login-btn"
                >
                  Login
                </button> */}
                <div className="log-img-div-div" onClick={() => setOpen(!open)}>
                  {" "}
                  <img src={userData?.image} alt="" className="log-img-log" />
                  <span className="Guy-Hawkins-span">{userData?.fullName}</span>
                  <img src={arrowrght} alt="" className="class-arrow-bottom" />
                </div>
                {open ? <Modal userRole="advocate" /> : null}
              </div>
            </div>
            <div className="ham-burgur-nav">
              <Hamburger
                onToggle={handlehide}
                toggled={isOpen}
                color="#ffffff"
              />
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Navbar;
