import React, { useState, useRef } from "react";
import "../Css/EditProfile.css";
import camimg2 from "../Images/camimg2.png";
import camimg1 from "../Images/camimg1.png";
import { useFormik } from "formik";
import { EditProfileSchemas } from "../schemas";
import PhoneInput from "react-phone-number-input";
import "react-phone-number-input/style.css";
import { parsePhoneNumber } from "react-phone-number-input";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import axios from "../schemas/Api";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useEffect } from "react";
import { useUser } from "../UserContext.js";
const EditProfile = () => {
  const { updateUserData } = useUser();
  const initialValues = {
    FullName: "",
    MobileNumber: "",
    email: "",
  };
  const [Editdata, setEditdata] = useState([]);
  const [loading, setloading] = useState(false);

  // console.log(Editdata, "Editdata");
  const data = JSON.parse(localStorage.getItem("Nezziedata"));

  // const [Loading, setLoading] = useState(false);
  // const { resetForm } = useFormikContext();

  const camimg1Ref = useRef(null);
  const camimg2Ref = useRef(null);

  const [selectedImages, setSelectedImages] = useState({
    img: null,
  });
  const [image, setimage] = useState();
  // console.log(selectedImages);

  // api edit profile
  const onSubmit = async () => {
    setloading(true);
    try {
      const resp = await axios.post(
        "userUpdate",
        {
          fullName: values.FullName,
          mobileNumber: values.MobileNumber,
          email: values.email,
          image: image,
          // countryCode: values.country,
        },
        {
          headers: {
            "Content-Type": "multipart/form-data ",
            Authorization: data.token,
          },
        }
      );
      if (resp) {
        setloading(false);
      }
      toast.success(resp.data.message, {
        position: "top-right",
        theme: "colored",
      });
      setEditdata(resp.data.Data);
      if (resp.data.message === "User Updated Successfully") {
      }
      // localStorage.setItem("Nezziedata", JSON.stringify(resp.data.Data));
      updateUserData(resp.data.Data);
      // image:"",
      console.log(resp);
    } catch (error) {
      console.log("Error", error);
      setloading(false);
      toast.error(error.resp.data.message, {
        position: "top-right",
        theme: "colored",
      });
    }
  };
  useEffect(() => {
    setFieldValue("FullName", data.fullName);
    setFieldValue("MobileNumber", data.mobileNumber);
    setPhone(data.countryCode + data.mobileNumber);
    setFieldValue("email", data.email);
  }, []);

  const handleImageChange = (e, imageType) => {
    const file = e.target.files[0];
    setimage(file);
    setSelectedImages({ img: URL.createObjectURL(file) });
  };
  // console.log(image);

  const {
    values,
    handleBlur,
    handleChange,
    touched,
    handleSubmit,
    setFieldValue,
    errors,
  } = useFormik({
    initialValues: initialValues,
    validationSchema: EditProfileSchemas,

    onSubmit,
  });

  // console.log(errors);
  const [phone, setPhone] = useState("");
  // console.log(phone);
  const handlePhoneChange = (value) => {
    setPhone(value);
    setFieldValue("MobileNumber", value);
    try {
      const parsedPhone =
        value.length >= 6
          ? parsePhoneNumber(value, { defaultCountry: "US" })
          : null;

      if (parsedPhone) {
        setFieldValue("country", `+${parsedPhone.countryCallingCode}`);
        setFieldValue("MobileNumber", parsedPhone.nationalNumber);
      } else {
        setFieldValue("country", "");
        setFieldValue("MobileNumber", "");
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <>
      <section className="edit-profile-main-section">
        <form onSubmit={handleSubmit} className="edit-profile-main-div">
          <div className="edit-profile-single-div">
            <span className="Edit-Profile-span23">Edit Profile</span>
          </div>

          <div className="edit-profile-main27-div27">
            <div className="edit-profile-child27-div">
              <div className="camimg22-div22">
                <img
                  className="camimg2-img"
                  src={selectedImages.img || camimg2}
                  alt=""
                />
                <input
                  type="file"
                  accept="image/*"
                  ref={camimg2Ref}
                  style={{ display: "none" }}
                  onChange={(e) => handleImageChange(e, "camimg2")}
                />
              </div>
              <div className="camimg11-div11">
                <img
                  src={camimg1}
                  alt=""
                  className="camimg1-img"
                  onClick={() => camimg1Ref.current.click()}
                />
                <input
                  type="file"
                  accept="image/*"
                  ref={camimg1Ref}
                  style={{ display: "none" }}
                  onChange={(e) => handleImageChange(e, "camimg1")}
                />
              </div>
            </div>
            <div className="input-text-div-dad-div">
              <div className="input-text-div-pap-div">
                <div className="label-or-feild-div24">
                  <label htmlFor="fullname" className="html12-label-span">
                    Full Name
                  </label>
                  <input
                    type="text"
                    className="kathryn-input22"
                    // placeholder="Kathryn Murphy"
                    name="FullName"
                    value={values.FullName}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                  {errors.FullName && touched.FullName ? (
                    <p className="mastu-to-eroor mt-2">{errors.FullName} </p>
                  ) : null}
                </div>
                <div className="label-or-feild-div24">
                  <label htmlFor="fullname" className="html12-label-span">
                    Mobile Number
                  </label>

                  <PhoneInput
                    className="kathryn-input22"
                    name="MobileNumber"
                    style={{ cursor: "not-allowed" }}
                    disabled={true}
                    // placeholder="+1 989809 98980"
                    onChange={handlePhoneChange}
                    value={phone}
                    onBlur={handleBlur}
                  />
                  {errors.MobileNumber && touched.MobileNumber ? (
                    <p className="mastu-to-eroor mt-2">
                      {errors.MobileNumber}{" "}
                    </p>
                  ) : null}
                </div>
              </div>
              <div className="label-or-feild-div24-90">
                <label htmlFor="fullname" className="html12-label-span-75">
                  Email
                </label>

                <input
                  type="email"
                  className="kathryn-second-input"
                  placeholder="kathryn_murphy07@gmail.com"
                  name="email"
                  style={{ cursor: "not-allowed" }}
                  disabled={true}
                  value={values.email}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
                {errors.email && touched.email ? (
                  <p className="mastu-to-eroor mt-2">{errors.email} </p>
                ) : null}
              </div>
              <div className="button-save-div">
                <button className="save-button25">Save</button>
              </div>
            </div>
          </div>
        </form>
      </section>
      {!loading && <div></div>}
      {loading && (
        <div>
          <Backdrop
            sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open={true}
            // onClick={handleClose}
          >
            <CircularProgress color="inherit" />
          </Backdrop>
        </div>
      )}
    </>
  );
};

export default EditProfile;
