import React, { useState } from "react";
import "../Css/Tip2.css";
import ad21 from "../Images/Ad21.png";
import close from "../Images/close.png";

const Tip2 = () => {
  const [show, setshow] = useState("");
  const Imagelink = () => {
    setshow(true);
  };
  return (
    <>
      {show && (
        <div className="model">
          <div className="closeicon">
            <img src={close} alt="" onClick={() => setshow(false)} />
            <Tip2 />
          </div>
        </div>
      )}
      {/* <button onClick={Imagelink}>Load tip</button> */}
      <section className="tip2-main2-section2">
        <div className="tip2-main2-section2-div2">
          <div>
            <span className="advo-tip-span">Tip this Advocate</span>
          </div>
          <div className="babura-div">
            <div>
              <img src={ad21} alt="" className="ad21-png-img" />
            </div>
            <span className="advo-tip-span-mosu">Jemmy Willson</span>
          </div>
          <div className="height-width-tip-div-father">
            <div className="hhh-ops">
              <div className="height-width-tip-div">
                <span className="percentage-span97"> $ 15</span>
                <span className="Acceptable-span26">Acceptable</span>
              </div>
              <div className="height-width-tip-div11">
                <span className="percentage-span97">$ 20</span>
                <span className="Acceptable-span26">Good</span>
              </div>
              <div className="height-width-tip-div22">
                <span className="percentage-span97">$ 25</span>
                <span className="Acceptable-span26">Great</span>
              </div>
            </div>
            <div className="hhh-ops">
              <div className="height-width-tip-div">
                <span className="percentage-span97">$ 30</span>
                <span className="Acceptable-span26">Excellent</span>
              </div>
              <div className="height-width-tip-div11">
                {/* <span className="percentage-span97">20%</span> */}
                <span className="Acceptable-span26">No Tip</span>
              </div>
              <div className="height-width-tip-div22">
                {/* <span className="percentage-span97">20%</span> */}
                <span className="Acceptable-span26">Custom</span>
              </div>
            </div>
          </div>
          <div>
            <button className="tip-submit-button">Submit</button>
          </div>
        </div>
      </section>
    </>
  );
};

export default Tip2;
