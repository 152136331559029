import { useState } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import "../Css/RatethisCall.css";
import Rateuser from "../Images/Rateuser.png";

const RatethisCall = () => {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  return (
    <>
      <Button variant="primary" onClick={handleShow}>
        RatethisCall
      </Button>

      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <span className="call-span-one">Rate this Call</span>
        </Modal.Header>
        <div className="Rate-user-name">
          <img className="Rateuser-img" src={Rateuser} alt="" />
          <span className="Jones-span-user">Jacob Jones</span>
        </div>
        <div className="mb-3">
          <span className="happy-do-span">Do you happy with this call?</span>
          <div className="no-yes-div">
            <button className="no-span-btn">No</button>
            <button className="yes-btn-span">Yes</button>
          </div>
        </div>
        <div className="Review-span-div">
          <span className="textatea-span">Reason</span>
          <textarea
            className="Write-a-text"
            placeholder="Write here"
            id="w3review"
            name="w3review"
            rows="5"
            cols="35"
          ></textarea>
        </div>
        <div className="">
          <button className="Submit-btn">Submit</button>
        </div>
      </Modal>
    </>
  );
};

export default RatethisCall;
