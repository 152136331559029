import { useState } from "react";
import "../Css/BookNow.css";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import "react-datepicker/dist/react-datepicker.css";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker-cssmodules.css";

const BookNow = () => {
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(null);
  const [selectedSlots, setSelectedSlots] = useState([]);

  const onChange = (dates) => {
    const [start, end] = dates;
    setStartDate(start);
    setEndDate(end);
  };
  const availableSlots = [
    "8:00 to 8:30 AM",
    "8:30 to 9:00 AM",
    "9:00 to 9:30 AM",
    "9:30 to 10:00 AM",
    "10:00 to 10:30 AM",
    "10:30 to 11:00 AM",
    "11:00 to 11:30 AM",
    "11:30 to 12:00 PM",
    "12:00 to 12:30 PM",
    "12:30 to 10:00 PM",
  ];
  const handleSlotSelection = (slot) => {
    if (selectedSlots.includes(slot)) {
      setSelectedSlots(selectedSlots.filter((s) => s !== slot));
    } else {
      if (selectedSlots.length === 0 || selectedSlots.length === 1) {
        setSelectedSlots([...selectedSlots, slot]);
      } else {
        console.log("You can only select up to 2 consecutive slots.");
      }
    }
  };
  const [Open, setOpen] = useState(false);

  const handleClos = () => setOpen(false);
  const handleOpen = () => setOpen(true);
  return (
    <>
      <section>
        <div className="model-book">
          {/* <Button variant="primary" onClick={handleOpen}>
            Launch demo modal
          </Button> */}

          <Modal show={Open} onHide={handleClos}>
            <Modal.Header closeButton>
              <Modal.Title>Book Now</Modal.Title>
            </Modal.Header>

            <div className="Select-div">
              <DatePicker
                selected={startDate}
                onChange={onChange}
                startDate={startDate}
                endDate={endDate}
                selectsRange
                inline
              />
              <span className="Select-time-span">Select Time</span>
              {/* <div className="mt-2 all-time-btn">
                <button className="time-apoy-mant">8:00 to 8:30 AM</button>
                <button className="time-apoy-mant">8:30 to 9:00 AM</button>
                <button className="time-apoy-mant">9:00 to 9:30 AM</button>
                <button className="time-apoy-mant">9:30 to 10:00 AM</button>
                <button className="time-apoy-mant">10:00 to 10:30 AM</button>
                <button className="time-apoy-mant">10:30 to 11:00 AM</button>
                <button className="time-apoy-mant">11:00 to 11:30 AM</button>
                <button className="time-apoy-mant">11:30 to 12:00 PM</button>
                <button className="time-apoy-mant">12:00 to 12:30 PM</button>
                <button className="time-apoy-mant">12:30 to 1:00 PM</button>
              </div> */}
              <div className="mt-2 all-time-btn">
                {availableSlots.map((slot, index) => (
                  <button
                    className={`time-apoy-mant ${
                      selectedSlots.includes(slot) ? "selected-slot" : ""
                    }`}
                    key={index}
                    onClick={() => handleSlotSelection(slot)}
                  >
                    {slot}
                  </button>
                ))}
              </div>
              <button className="Book-Now-text mt-4">Book Now</button>
              <div className="mb-4"></div>
            </div>
          </Modal>
        </div>
      </section>
    </>
  );
};

export default BookNow;
