import React, { useState, useEffect } from "react";
import "../Css/AdviceAdvocate.css";
import hireAdvocate from "../Images/hireAdvocate.png";
import sado from "../Images/sado.png";
import chat from "../Images/Chatimg.png";
// import favestar from "../Images/favestar.png";
// import hire1 from "../Images/Hire1.png";
// import hire2 from "../Images/Hire2.png";
// import hire3 from "../Images/Hire3.png";
// import hire4 from "../Images/Hire4.png";
// import hire5 from "../Images/Hire5.png";
// import hire6 from "../Images/Hire6.png";
// import hire7 from "../Images/Hire7.png";
// import hire8 from "../Images/Hire8.png";
// import hire9 from "../Images/Hire9.png";
// import hire10 from "../Images/Hire10.png";
// import hire11 from "../Images/Hire11.png";
// import hire12 from "../Images/Hire12.png";
import axios from "../schemas/Api";
import ssstar from "../Images/ssstar.png";
import ksstar from "../Images/ksstar.png";
import Footer2 from "./Footer2";
import Navbar2 from "./Navbar2";
import { useNavigate } from "react-router-dom";
import CircularProgress from "@mui/material/CircularProgress";

const AdviceAdvocate = () => {
  const [isloading, setIsLoading] = useState(true);
  const [speciality, setSpeciality] = useState([]);
  const data = JSON.parse(localStorage.getItem("Nezziedata"));
  // console.log(data, "data");
  // console.log(speciality);
  const dataspecuails = localStorage.getItem("specialitydata");

  useEffect(() => {
    specialityData();
  }, []);
  // here i remove the dependency array
  const specialityData = async () => {
    try {
      const response = await axios.post(
        "advocate/allSpeciallityAdvocates",
        {
          speciallityId: dataspecuails,
        },

        {
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            Authorization: data.token,
          },
        }
      );
      setSpeciality(response.data.data);
      setIsLoading(false);
      console.log(response);
    } catch (error) {
      console.log("Error", error);
    }
  };
  // const Hiredata = [
  //   {
  //     usreNam: "Jenny Wilson",
  //     perhour: "$ 200",
  //     Reviews: "(233 Reviews)",
  //     Imges: hire1,
  //   },
  //   {
  //     usreNam: "Jacob Jones",
  //     perhour: "$ 200",
  //     Reviews: "(233 Reviews)",
  //     Imges: hire2,
  //   },
  //   {
  //     usreNam: "Jerome Bell",
  //     perhour: "$ 200",
  //     Reviews: "(233 Reviews)",
  //     Imges: hire3,
  //   },
  //   {
  //     usreNam: "Darlene Robertson",
  //     perhour: "$ 200",
  //     Reviews: "(233 Reviews)",
  //     Imges: hire4,
  //   },
  //   {
  //     usreNam: "Robert Fox",
  //     perhour: "$ 200",
  //     Reviews: "(233 Reviews)",
  //     Imges: hire5,
  //   },
  //   {
  //     usreNam: "Ronald Richards",
  //     perhour: "$ 200",
  //     Reviews: "(233 Reviews)",
  //     Imges: hire6,
  //   },
  //   {
  //     usreNam: "Guy Hawkins",
  //     perhour: "$ 200",
  //     Reviews: "(233 Reviews)",
  //     Imges: hire7,
  //   },
  //   {
  //     usreNam: "Eleanor Pena",
  //     perhour: "$ 200",
  //     Reviews: "(233 Reviews)",
  //     Imges: hire8,
  //   },
  //   {
  //     usreNam: "Jenny Wilson",
  //     perhour: "$ 200",
  //     Reviews: "(233 Reviews)",
  //     Imges: hire9,
  //   },
  //   {
  //     usreNam: "Cameron Williamson",
  //     perhour: "$ 200",
  //     Reviews: "(233 Reviews)",
  //     Imges: hire10,
  //   },
  //   {
  //     usreNam: "Cody Fisher",
  //     perhour: "$ 200",
  //     Reviews: "(233 Reviews)",
  //     Imges: hire11,
  //   },
  //   {
  //     usreNam: "Kathryn Murphy",
  //     perhour: "$ 200",
  //     Reviews: "(233 Reviews)",
  //     Imges: hire12,
  //   },
  // ];

  const navigate = useNavigate();
  const hhee = (item) => {
    // console.log(item);
    navigate("/AdvocateDetail", {
      state: {
        item,
      },
    });
  };

  return (
    <>
      <Navbar2 />
      <section className="AdviceAdvocate-main-sec">
        <div className="AdviceAdvocate-main-divv">
          <div>
            <span className="Legal-Advice-span">Legal Advice Advocate</span>
          </div>
          <div className="img-apn-btn-div">
            <img className="hireAdvocate-img" src={hireAdvocate} alt="" />
            <img src={sado} className="sado-img" alt="" />
            <span className="deligent-span">
              We earn your trust and are deligent your case.
            </span>
            <span className="industry-a-span">
              Lorem Ipsum is simply dummy text of the printing and typesetting
              industry. Lorem Ipsum has been the industry's standard.
            </span>
            <button className="Hire-btn"> Hire an Advocate</button>
          </div>
          {isloading ? (
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: "100vh",
              }}
            >
              <CircularProgress />
            </div>
          ) : (
            <div className="main-card-div">
              {speciality.map((itm, index) => {
                let Ratingcount = itm.review_total_count;
                let MaxRate = 5;
                let yellowstar = Math.min(Ratingcount, MaxRate);
                let RatingcountImage = Array.from(
                  { length: yellowstar },
                  (_, i) => <img key={i} src={ssstar} alt="" />
                );
                let GrayStarCount = MaxRate - yellowstar;
                let GrayStarImage = Array.from(
                  { length: GrayStarCount },
                  (_, i) => <img key={i + yellowstar} src={ksstar} alt="" />
                );
                let starRatingimages = [...RatingcountImage, ...GrayStarImage];

                return (
                  <div key={index} className="img-span-rat-div">
                    <div className="all-soan-star-div-main">
                      <div className="chat-kaliya">
                        <img
                          src={itm.image}
                          className="hire1-avalebal-img"
                          alt=""
                        />
                        <div className="star-span">
                          <span className="usreNam-span">{itm.fullName}</span>
                          <div>
                            {starRatingimages.map((itm) => {
                              return (
                                <img
                                  src={itm.props.src}
                                  alt=""
                                  className="fave-star-img"
                                />
                              );
                            })}
                            <span className="image-review-span">
                              (0 Reviews)
                            </span>
                          </div>
                          <div className="bjdvbbdjkbvje">
                            <span className="dollar-apn7890">$</span>
                            <span className="par-hors-price-ha">
                              {itm.rate}
                            </span>
                            <span className="per-hour-span66">/ per hour</span>
                            {/* <span className="image-review-span">
                              
                            </span> */}
                          </div>
                        </div>
                      </div>
                      <div>
                        <img className="chat-img" src={chat} alt="" />
                      </div>
                    </div>
                    <div>
                      <span className="per-hour-span66-ops">
                        {/* There are many variations of passages of Lorem Ipsum
                      available, but the majority... */}
                        {itm.resolutionExperiance}
                      </span>
                    </div>
                    <div className="dono-btn-kaliya">
                      <button
                        className="View-Details-btn"
                        onClick={() => hhee(itm)}
                      >
                        {" "}
                        View Details
                      </button>
                      <button className="Book-Video-Call-btn">
                        Book Audio Call
                      </button>
                    </div>
                  </div>
                );
              })}
            </div>
          )}
        </div>
      </section>
      <Footer2 />
    </>
  );
};

export default AdviceAdvocate;
