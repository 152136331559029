import React from "react";
import "../Css/Payment.css";
import addlogo from "../Logo/addlogo.png";
import visaimg from "../Images/visaimg.png";
import cardimg from "../Images/cardimg.png";

const Payment = () => {
  return (
    <section className="payment-main-section90">
      <div className="payment-main-dib90">
        <div className="paymnet-add-div">
          <div>
            <span className="Payment-Options-span">Payment Options</span>
          </div>
          <div className="add-logocard-div99">
            <img src={addlogo} alt="" className="add-logo-pls" />
            <span className="Add-New-Card-span">Add New Card</span>
          </div>
        </div>
        <div className="paymnet-three-main-div">
          <div className="payment-input67">
            <img src={visaimg} alt="" className="visaimg-img" />
            <input
              className="jugad-input12"
              type="text"
              placeholder="XXXX XXXX XXXX 3243"
            />
            <svg
              width="20"
              height="20"
              viewBox="0 0 20 20"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <g clip-path="url(#clip0_22_3414)">
                <path
                  d="M9.99935 18.3333C5.39685 18.3333 1.66602 14.6025 1.66602 10C1.66602 5.3975 5.39685 1.66667 9.99935 1.66667C14.6018 1.66667 18.3327 5.3975 18.3327 10C18.3327 14.6025 14.6018 18.3333 9.99935 18.3333ZM9.16852 13.3333L15.0602 7.44083L13.8818 6.2625L9.16852 10.9767L6.81102 8.61917L5.63268 9.7975L9.16852 13.3333Z"
                  fill="#36A533"
                />
              </g>
              <defs>
                <clipPath id="clip0_22_3414">
                  <rect width="20" height="20" fill="white" />
                </clipPath>
              </defs>
            </svg>
            <svg
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <g clip-path="url(#clip0_22_3424)">
                <path
                  d="M12 3C11.175 3 10.5 3.675 10.5 4.5C10.5 5.325 11.175 6 12 6C12.825 6 13.5 5.325 13.5 4.5C13.5 3.675 12.825 3 12 3ZM12 18C11.175 18 10.5 18.675 10.5 19.5C10.5 20.325 11.175 21 12 21C12.825 21 13.5 20.325 13.5 19.5C13.5 18.675 12.825 18 12 18ZM12 10.5C11.175 10.5 10.5 11.175 10.5 12C10.5 12.825 11.175 13.5 12 13.5C12.825 13.5 13.5 12.825 13.5 12C13.5 11.175 12.825 10.5 12 10.5Z"
                  fill="#3A3781"
                />
              </g>
              <defs>
                <clipPath id="clip0_22_3424">
                  <rect width="24" height="24" fill="white" />
                </clipPath>
              </defs>
            </svg>
          </div>
          <div className="payment-input67">
            <img src={cardimg} alt="" className="visaimg-img" />
            <input
              className="jugad-input12"
              type="text"
              placeholder="XXXX XXXX XXXX 3243"
            />
            <svg
              width="20"
              height="20"
              viewBox="0 0 20 20"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <g clip-path="url(#clip0_22_3414)">
                <path
                  d="M9.99935 18.3333C5.39685 18.3333 1.66602 14.6025 1.66602 10C1.66602 5.3975 5.39685 1.66667 9.99935 1.66667C14.6018 1.66667 18.3327 5.3975 18.3327 10C18.3327 14.6025 14.6018 18.3333 9.99935 18.3333ZM9.16852 13.3333L15.0602 7.44083L13.8818 6.2625L9.16852 10.9767L6.81102 8.61917L5.63268 9.7975L9.16852 13.3333Z"
                  fill="#36A533"
                />
              </g>
              <defs>
                <clipPath id="clip0_22_3414">
                  <rect width="20" height="20" fill="white" />
                </clipPath>
              </defs>
            </svg>
            <svg
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <g clip-path="url(#clip0_22_3424)">
                <path
                  d="M12 3C11.175 3 10.5 3.675 10.5 4.5C10.5 5.325 11.175 6 12 6C12.825 6 13.5 5.325 13.5 4.5C13.5 3.675 12.825 3 12 3ZM12 18C11.175 18 10.5 18.675 10.5 19.5C10.5 20.325 11.175 21 12 21C12.825 21 13.5 20.325 13.5 19.5C13.5 18.675 12.825 18 12 18ZM12 10.5C11.175 10.5 10.5 11.175 10.5 12C10.5 12.825 11.175 13.5 12 13.5C12.825 13.5 13.5 12.825 13.5 12C13.5 11.175 12.825 10.5 12 10.5Z"
                  fill="#3A3781"
                />
              </g>
              <defs>
                <clipPath id="clip0_22_3424">
                  <rect width="24" height="24" fill="white" />
                </clipPath>
              </defs>
            </svg>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Payment;
