import React, { useState, useEffect } from "react";
import "../Css/Appoinment2.css";
// import round3 from "../Images/R2.png";
// import janeimg from "../Images/Ad21.png";
// import watson from "../Images/Ad24.png";
// import arlene from "../Images/Ad25.png";
// import Mckinny from "../Images/Ad26.png";
// import annete from "../Images/Ad27.png";
// import savannah from "../Images/Ad28.png";
// import enny from "../Images/Ad22.png";
// import fisher from "../Images/Ad23.png";
// import devon from "../Images/Ad2.png";
import axios from "../schemas/APi1";
import msg from "../Images/msg.png";
import starimg from "../Images/starimg.png";

// import { useState } from "react";

const Appoinment2 = () => {
  const [newdata, setnewdata] = useState([]);
  const data = JSON.parse(localStorage.getItem("Nezziedata"));

  useEffect(() => {
    dataUpcoming();
  }, []);

  const dataUpcoming = async () => {
    try {
      const res = await axios.post(
        "appointment/getappointment",
        { status: "Upcoming" },

        {
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            Authorization: data.token,
          },
        }
      );
      setnewdata(res.data.data);
      console.log(res);
    } catch (error) {
      console.log("Error", error);
    }
  };
  // const appoinmentdata2 = [
  //   {
  //     img5: janeimg,
  //     Name5: "Jane Cooper",
  //   },
  //   {
  //     img5: watson,
  //     Name5: "Kristin Watson",
  //   },
  //   {
  //     img5: arlene,
  //     Name5: "Arlene McCoy",
  //   },
  //   {
  //     img5: Mckinny,
  //     Name5: "Darlene Robert",
  //   },
  //   {
  //     img5: annete,
  //     Name5: "Annette Black",
  //   },
  //   {
  //     img5: savannah,
  //     Name5: "Savannah Nguyen",
  //   },
  //   {
  //     img5: enny,
  //     Name5: "Jenny Wilson",
  //   },
  //   {
  //     img5: fisher,
  //     Name5: "Cody Fisher",
  //   },
  //   {
  //     img5: devon,
  //     Name5: "Devon Lane",
  //   },
  // ];
  return (
    <section className="appoinment2-main34-section">
      <div className="appoinment2-main34-divo">
        {/* <div className="appoinment-span98-div">
          <span className="appoinment-span98">Dashboard</span>
          <span className="appoinment-span98-span">My Appointments</span>
        </div>
        <div className="three-button-parent">
          <button className="new-button-new1">
            New <img src={round3} alt="" className="round3-img3" />
          </button>
          <button className="new-button-new2">Upcoming</button>
          <button className="new-button-new3">Completed</button>
        </div> */}
        <div className="map-method-main1-dib ">
          {newdata.map((item5, index) => {
            return (
              <div className="single-card-div-paps" key={index}>
                <div className="inner-map-div-card">
                  <div className="img1-star1-name1-div1">
                    <div>
                      <img src={item5.image} alt="" className="star-img545" />
                    </div>
                    <div className="item5-span-star-div55">
                      <span className="item5-name5-span49">
                        {item5.fullName}
                      </span>
                      <div className="reveiw-rating-div19">
                        <img src={starimg} alt="" className="star-img4c" />
                        <span className="span-12-555">
                          (<span className="span123-span-sapn1">0 </span>{" "}
                          Reviews)
                        </span>
                      </div>
                    </div>
                    <div className="message-logo-div-div">
                      <img src={msg} alt="" className="message-logo-img990" />
                      <button className="inner-btn-upcoming">Upcoming</button>
                    </div>
                  </div>
                  <div className="display-div666">
                    <div className="display-flex-div-div12">
                      <span className="display-flex-span-span">Date</span>
                      <span className="jun-span-span1-span">
                        {item5.bookedDate}
                      </span>
                    </div>
                    <div className="display-flex-div-div12">
                      <span className="display-flex-span-span">Time</span>
                      <span className="jun-span-span1-span">
                        {item5.bookedTime}
                      </span>
                    </div>
                  </div>
                  <div className="reject12-accept-button-div12">
                    <button className="cancel-booking-btn">Start Call</button>
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </section>
  );
};

export default Appoinment2;
