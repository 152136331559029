import React from "react";
import "../Css/HowItsWork.css";
import logo12 from "../Images/logo12.png";
import logo22 from "../Images/logo22.png";
import logounion from "../Images/logounion.png";

const HowItsWork = () => {
  const carddata = [
    {
      cardimg: logo12,
      cardheading: "Issue Submission",
      cardpara:
        "Share your problem anonymously, ensuring your privacy.Set your price for the consultation.",
    },
    {
      cardimg: logo22,
      cardheading: "Freelancer Engagement",
      cardpara:
        "Mutual agreement on the consultation fee.Freelancer facilitates a three-way call with involved parties.",
    },
    {
      cardimg: logounion,
      cardheading: "Resolution and Collaboration",
      cardpara:
        "Set up a call with the freelancer advocating on your behalf.Prepare to engage with the business representative to bridge divides.",
    },
  ];
  return (
    <>
      <div className="how-it-work-parent-div">
        <div className="how-it-work-child-div">
          <div className="">
            <span className="how-it-work-span">How it Works</span>
          </div>
          <div className="nava-span-add-div">
            {" "}
            <span className="nava-span-add">
              Streamlined Process for Constructive Outcomes
            </span>
            <span className="Collaboration-span">
              From Challenge to Collaboration – Nezzie™'s Approach
            </span>
          </div>
          <div className="how-it-work-card-main-div">
            {carddata.map((curelem, index) => {
              return (
                <div className="card-single-div" key={index}>
                  <img
                    src={curelem.cardimg}
                    alt=""
                    className="images-three-on-one"
                  />
                  <span className="heading-span-span">
                    {curelem.cardheading}
                  </span>
                  <span className="paragraph-span-span">
                    {curelem.cardpara}
                  </span>
                </div>
              );
            })}
          </div>
          <div className="become-service-btn-div">
            <button className="become-service-btn20">
              Become a Service Provider
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default HowItsWork;
