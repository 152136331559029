import React, { useRef, useEffect } from "react";
import { useState } from "react";
import Logo from "../Images/Nezzie Logo.png";
import ser from "../Images/search.png";
import Hamburger from "hamburger-react";
import { NavLink, useNavigate } from "react-router-dom";
import Modal from "./Modal";
import "../Css/Navbar.css";
import "../Css/Navbar3.css";
import logimg from "../Images/logimg.png";
import arrowrght from "../Images/arrowrght.png";

// import Dropdown from "./Dropdown";
const Navbar = () => {
  const navigate = useNavigate();
  // const [open, setOpen] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const handlehide = () => {
    setIsModalOpen((prevState) => !prevState);
  };

  const [isScrolled, setIsScrolled] = useState(false);

  const [isOpen, setIsOpen] = useState(false);

  const handleScroll = () => {
    if (window.scrollY > 20) {
      setIsScrolled(true);
    } else {
      setIsScrolled(false);
    }
  };
  let menuref = useRef();
  useEffect(() => {
    let handler = (event) => {
      if (menuref.current) {
        if (!menuref.current.contains(event.target)) {
          setIsOpen(false);
        }
      }
    };
    document.addEventListener("scroll", handler);

    return () => {
      document.removeEventListener("mousedown", handler);
      document.removeEventListener("scroll", handler);
    };
  });
  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);
  const handlehidechange = () => {
    setIsOpen(!isOpen);
  };

  return (
    <>
      <section className="Navbar-section">
        <div className={!isScrolled ? "navbar-div" : "navbar-div-not"}>
          <div className="Navbar-itm">
            <NavLink to="/">
              <img className="logo-img" src={Logo} alt="" />
            </NavLink>

            <div className={isOpen ? "navbar-itam11" : "navbar-itam"}>
              <div className="gap-div99">{/* <Dropdown /> */}</div>

              <div className="Nav-bar-itm">
                <NavLink to="/" className="home-li">
                  Home{" "}
                </NavLink>

                <NavLink to="/NearMe" className="home-li">
                  Near Me
                </NavLink>

                <NavLink to="/AvailableAdvocate" className="home-li">
                  Available Advocates
                </NavLink>
              </div>
              <div className="img-btn-select-div">
                <button
                  onClick={() => navigate("/PostJob")}
                  className="post-job-btn00"
                >
                  Post a Job
                </button>
                <img className="serach-img" src={ser} alt="" />
                <select className="drop-one-en" name="cars" id="cars">
                  <option value="volvo">EN</option>
                  <option value="saab">Saab</option>
                  <option value="mercedes">Mercedes</option>
                  <option value="audi">Audi</option>
                </select>
                {/* <button
                  onClick={() => navigate("/AdvoUser")}
                  className="Login-btn"
                >
                  Login
                </button> */}
                <div className="log-img-div-div">
                  {" "}
                  {/* <div className="log-img-div-div" onClick={handlehidechange}> */}{" "}
                  <img src={logimg} alt="" className="log-img-log" />
                  <span className="Guy-Hawkins-span">Guy Hawkins</span>
                  <img src={arrowrght} alt="" className="class-arrow-bottom" />
                </div>
                {/* <Modal isOpen={isModalOpen} /> */}
              </div>
            </div>
            <div className="ham-burgur-nav">
              <Hamburger
                onToggle={handlehide}
                toggled={isOpen}
                color="#ffffff"
              />
            </div>
          </div>
        </div>
      </section>
      {/* .img-btn-select-div {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 2rem;
  margin-left:4rem
}884px{
  .img-btn-select-div {
    flex-direction: column;
    gap: 1rem;
    margin-top: 1rem;
    margin-left: 0rem;
  }

} */}
    </>
  );
};

export default Navbar;
