import React, { useEffect, useState } from "react";
import "../Css/RegisterTwo.css";
import add from "../Logo/Add.png";
import { useNavigate } from "react-router-dom";
import TapTap from "../Logo/Uplod.png";
import { useFormik } from "formik";
import { RegisterTwoSchemas } from "../schemas";
import axios from "../schemas/APi1";
import "react-toastify/dist/ReactToastify.css";
import { toast } from "react-toastify";
// import axios from "axios";
// import CircularProgress from "@mui/material/CircularProgress";
// import Backdrop from "@mui/material/Backdrop";

const initialValues = {
  RatePerhour: "",
  Writehere: "",
  YourSpeciality: "",
  ResolutionExperience: "",
  Skills: "",
};
const RegisterTwo = ({ num, selectedImage }) => {
  console.log(selectedImage);
  // const data = JSON.parse(localStorage.getItem("Nezziedata"));

  const navigate = useNavigate();
  // const location = useLocation();
  // const num = location.state?.num;
  // console.log(num);
  // console.log(selectedImage);
  // const [showCertificateUploader, setShowCertificateUploader] = useState(false);

  const [skills, setSkills] = useState([]);
  const [Loading, setLoading] = useState(false);
  const [Ok, setOk] = useState([]);
  const [OkTo, setOkTo] = useState([]);
  // console.log(OkTo._id, ".Ok-2.");
  // console.log(Ok._id, ".id-1.");
  const [newSkill, setNewSkill] = useState("");
  // Api integrated at here
  const dataspecuails = localStorage.getItem("specialitydata");
  // const latlong = localStorage.getItem();
  // console.log(dataspecuails);

  localStorage.getItem("lon");
  localStorage.getItem("lat");

  const onSubmit = async (event) => {
    event.preventDefault();

    setLoading(true);
    console.log(values);
    const formdta = new FormData();

    formdta.append("select_languages", num.select_languages);
    formdta.append("fullName", num.fullname);
    formdta.append("mobileNumber", num.mobileNumber);
    formdta.append("email", num.email);
    formdta.append("password", num.password);
    formdta.append("confirmPassword", num.confirmPassword);
    formdta.append("device_id", "hjhjfewhjfweewgbwbgkkwe");
    formdta.append("device_type", "web");
    formdta.append("rate", values.RatePerhour);
    formdta.append(
      "skills",
      JSON.stringify([
        {
          skill: "test",
        },
        {
          skill: "test",
        },
      ])
    );
    formdta.append("aboutYou", values.Writehere);
    formdta.append("yourSpeciality", values.YourSpeciality);
    formdta.append("resolutionExperiance", values.ResolutionExperience);
    formdta.append("uploadCertificate", num.image);
    formdta.append("image", num.image);
    formdta.append("latitude", localStorage.getItem("lat"));
    formdta.append("longitude", localStorage.getItem("lon"));
    formdta.append("address", "vasna");
    formdta.append("countryCode", num.countryCode);
    formdta.append("category_1Id ", Ok._id);
    formdta.append("category_2Id", OkTo._id);
    // console.log(formdta, "ssd");
    // prompt(formdta);

    try {
      // const skillsArray = skills.map((skill) => ({ skill }));
      const response = await axios.post(
        "https://admin.nezzie.app/api/advocate/advocateRegister",
        formdta,

        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
      if (response) {
        setLoading(false);
      }
      toast.success(response.data.message, {
        position: "top-right",
        theme: "colored",
      });
      if (response.data.message === "Advocate Register Successfully") {
        navigate("/AdLogin");
      }
      console.log("api response", response);
      // console.log(response.data);
      if (Object.keys(errors).length > 0) {
        console.log("Form validation errors:", errors);
      }
    } catch (error) {
      console.log("error", error);
      setLoading(false);
      console.log("error response", error.response); //error response
      toast.error(error.response.data.message, {
        position: "top-right",
        theme: "colored",
      });
    }
  };
  const handleSkillChange = (event) => {
    setNewSkill(event.target.value);
  };

  const handleAddSkill = () => {
    if (newSkill.trim() !== "") {
      setSkills([...skills, newSkill.trim()]);
      setNewSkill("");
      // addSpeciality(newSkill.trim());
    }
  };

  const handleCancelSkill = (index) => {
    const updatedSkills = [...skills];
    updatedSkills.splice(index, 1);
    setSkills(updatedSkills);
  };
  const removeSkillIconSvg = `
  <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="#1E2D38">
    <g clip-path="url(#clip0_27_6463)">
      <path d="M10.0001 8.82129L14.1251 4.69629L15.3034 5.87462L11.1784 9.99962L15.3034 14.1246L14.1251 15.303L10.0001 11.178L5.87511 15.303L4.69678 14.1246L8.82178 9.99962L4.69678 5.87462L5.87511 4.69629L10.0001 8.82129Z" fill="#1E2D38" />
    </g>
    <defs>
      <clipPath id="clip0_27_6463">
        <rect width="20" height="20" fill="white" />
      </clipPath>
    </defs>
  </svg>
`;
  const addIconSvg = `
    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="#1E2D38">
      <path d="M10 2V10H18V12H10V20H8V12H0V10H8V2H10Z"/>
    </svg>
  `;
  const { values, handleBlur, handleChange, touched, handleSubmit, errors } =
    useFormik({
      initialValues: initialValues,
      validationSchema: RegisterTwoSchemas,
      onSubmit,
    });
  // console.log(errors);
  const [specialist, setSpecialist] = useState([]);
  // console.log(specialist, "specialityOptions");
  const [image, setImage] = useState("");
  const handleImageChange = (event) => {
    // const file = event.target.files[0];
    // console.log(file);
    setImage(event.target.files[0]);
  };
  // add speciality api integrate at here
  const specialityData = async () => {
    try {
      const res = await axios.post(
        "meta_data/speciallityList",

        {
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
          },
        }
      );
      console.log(res);
      setSpecialist(res.data.data);
    } catch (error) {
      console.log("Error", error);
    }
  };
  useEffect(() => {
    specialityData();
  }, []);

  const ok = async () => {
    try {
      const resp = await axios.post(
        "meta_data/speciallity_data",
        {
          speciallityId: dataspecuails,
        },
        {
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
          },
        }
      );

      // console.log(resp.data.data, " tera mu me");
      setOk(resp.data.data[0]);
      setOkTo(resp.data.data[0].subcat_2datas[0]);
    } catch (error) {
      console.log("Error", error);
    }
  };
  useEffect(() => {
    ok();
  }, []);
  // console.log(image);
  return (
    <>
      <section className="RegisterTwo-main-sec">
        <form onSubmit={handleSubmit} className="RegisterTwo-main-div">
          <div className="w-100">
            <span className="personaly-add">Become a Service Provider</span>
          </div>
          <div className="haghit-ka-liya"></div>
          <div className="ragiesrer-one ">
            <div className="div-he-div-he">
              <div className="whit-line-tyrnspar"></div>
              <span
                onClick={() => navigate("/AdRegister")}
                className="two-dada-span"
              >
                1
              </span>
              <div className="whit-line-tyrnspar"></div>
              <div className="blue-undur-line"></div>
              <span
                // onClick={() => navigate("/RegisterTwo")}
                className="one-dada-span"
              >
                2
              </span>
              <div className="blue-undur-line"></div>
            </div>
          </div>
          <span className="mb-4 personaly-add">Professional Info</span>
          <div className="input-from-r mt-3">
            <span className="all-input-span-font">Rate (Per hour)</span>
            <div className="doler-ka-liya mt-2">
              <span className="doler-span">$</span>
              <input
                className="ya-bot-input"
                placeholder=""
                type="Number"
                value={values.RatePerhour}
                onChange={handleChange}
                onBlur={handleBlur}
                name="RatePerhour"
              />
            </div>
            {errors.RatePerhour && touched.RatePerhour ? (
              <p className="mastu-to-eroor mt-2">{errors.RatePerhour} </p>
            ) : null}
            <span className="mt-3 all-input-span-font">About You</span>
            <textarea
              className="mt-2 Write-here-mahu"
              id="w3review"
              name="Writehere"
              placeholder="Write here"
              rows="6"
              cols="40"
              value={values.Writehere}
              onChange={handleChange}
              onBlur={handleBlur}
            ></textarea>
            {errors.Writehere && touched.Writehere ? (
              <p className="mastu-to-eroor mt-2">{errors.Writehere} </p>
            ) : null}
            <span className="all-input-span-font mt-3">Skills</span>
            <div className="doler-ka-liya mt-2">
              <div className="Lorem-btn">
                <div className="Lorem-btn">
                  {skills.map((skill, index) => (
                    <div key={index} className="skill-item">
                      <span className="skill-text">{skill}</span>
                      <span
                        className="remove-skill-icon"
                        dangerouslySetInnerHTML={{ __html: removeSkillIconSvg }}
                        onClick={() => handleCancelSkill(index)}
                      />
                    </div>
                  ))}
                  <div className="add-skill-input">
                    <input
                      className="add-skill-input-field"
                      type="text"
                      placeholder="Add new skill"
                      name="Skills"
                      value={newSkill}
                      onChange={handleSkillChange}
                      onBlur={handleBlur}
                    />
                    <button
                      className="add-skill-btn"
                      onClick={handleAddSkill}
                      dangerouslySetInnerHTML={{ __html: addIconSvg }}
                    />
                  </div>
                </div>
              </div>
            </div>
            {errors.Skills && touched.Skills ? (
              <p className="mastu-to-eroor mt-2">{errors.Skills} </p>
            ) : null}
            <span className="all-input-span-font mt-3">Your Speciality</span>
            <div className="selact-ka-liya-jugad mt-2 ">
              <select
                className="selact-tag-one"
                name="YourSpeciality"
                value={values.YourSpeciality}
                onChange={handleChange}
                onBlur={handleBlur}
              >
                <option value="">Select</option>
                {specialist.map((item, index) => {
                  return (
                    <option key={index} value={item._id}>
                      {item.speciallityName}
                    </option>
                  );
                })}
              </select>
            </div>
            {errors.YourSpeciality && touched.YourSpeciality ? (
              <p className="mastu-to-eroor mt-2">{errors.YourSpeciality} </p>
            ) : null}
            <span className="mt-3 all-input-span-font">
              Resolution Experience
            </span>
            <textarea
              className="mt-2 Write-here-mahu"
              id="w3review"
              name="ResolutionExperience"
              placeholder="Write here"
              rows="6"
              cols="40"
              value={values.ResolutionExperience}
              onChange={handleChange}
              onBlur={handleBlur}
            ></textarea>
            {errors.ResolutionExperience && touched.ResolutionExperience ? (
              <p className="mastu-to-eroor mt-2">
                {errors.ResolutionExperience}{" "}
              </p>
            ) : null}
            <span className="mt-3">Upload Certificate</span>
            <div
              className="box-containor"
              // onClick={() => setShowCertificateUploader(true)}
            >
              <div className="mt-2 hathi-bhai">
                {image ? (
                  <img
                    className="Tap-to-imgg"
                    src={URL.createObjectURL(image)}
                    alt=""
                    onChange={(e) => setImage(e.target.files[0])}
                  />
                ) : (
                  <div className="taop-tap">
                    <div className="document-div mt-3">
                      <img className="Default-to-imgg" src={TapTap} alt="" />
                      <span>Drag & Drop to upload document</span>
                    </div>
                    <div className="add-img-ho">
                      <img className="add-upolod-imgs" src={add} alt="" />
                    </div>
                  </div>
                )}

                <input
                  onChange={handleImageChange}
                  className="File-img-gov"
                  type="file"
                  name="gov_id"
                />
              </div>
            </div>
          </div>
          <span className="mt-3 Certificate-span">
            Add more Documents/Certificate
          </span>
          <button
            type="submit"
            className="cponform-regiestar mt-3"
            onClick={onSubmit}
          >
            Register
          </button>
          <div className="hghu"></div>
          {/* {!Loading && <div></div>}
          {Loading && ( // Display the loader when `loading` is true
            <div>
              <Backdrop
                sx={{
                  color: "#fff",
                  zIndex: (theme) => theme.zIndex.drawer + 1,
                }}
                open={true}
                // onClick={handleClose}
              >
                <CircularProgress color="inherit" />
              </Backdrop>
            </div>
          )} */}
        </form>
      </section>
    </>
  );
};

export default RegisterTwo;
